<template>
    <div class="sku-config-root"
        :class="{'readonly':readonly}">
        <div class="unit"
            v-for="(unit,key) in valueScheme"
            :key="key">
            <div class="name">
                <InputBox v-model="unit.display"
                    :readonly="readonly"
                    :placeholder="'规格名称'"></InputBox>
                <span class="iconfont icon-trash-icon"
                    @click="removeRow(key)"
                    title="删除规格"></span>
            </div>
            <div class="options">
                <div v-for="(option,index) in unit.value"
                    :key="index"
                    class="option-item">
                    <InputBox v-model="option.name"
                        :readonly="readonly"
                        :placeholder="'规格选项'"></InputBox>
                    <span class="iconfont icon-trash-icon"
                        @click="removeOption(unit,index)"
                        title="删除选项"></span>
                </div>
                <div class="button iconfont icon-plus-icon"
                    @click="addOption(unit)"></div>
            </div>
        </div>
        <div class="button"
            @click="addRow">新增规格</div>
        <div class="storeList">
            <div v-for="(store,index) in storeList">{{store.store_name}}</div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
let props = defineProps({
    readonly: Boolean,
    modelValue: [Object, Array]
});
const emit = defineEmits([
    'update:modelValue'
]);
const valueScheme = ref([]);
const unitButtons = ref([
    {
        display: '删除',
        emit: 'removeUnit',
    }
])
const storeList = computed(() => {
    let data = JSON.stringify(valueScheme.value);
    // 消除数据响应
    return storeInit(JSON.parse(data));
})
const storeInit = (data, result = []) => {
    let conf = data.shift();
    if (!conf) {
        return result;
    }
    let skuBasic = {
        'goods_id': '',
        'sku_code': [],
        'store_name': [],
        'cover': 0,
        'price': 0,
        'freight_basic': 0,
        'freight_append': 0,
        'freight_condition': 0,
        'stock': 0,
        'status': 1,
    };
    if (result.length < 1) {
        for (const index in conf.value) {
            if (Object.hasOwnProperty.call(conf.value, index)) {
                const val = conf.value[index];
                let arr = skuBasic;
                    arr.sku_code.push(index)
                    arr.store_name.push(val.name)
                result.push(arr);
            }
        }
        // console.log(result);
        return storeInit(data, result);
    }
    let newResult = [];

    for (const index in conf.value) {
        if (Object.hasOwnProperty.call(conf.value, index)) {
            const val = conf.value[index];
            for (const key in result) {
                if (Object.hasOwnProperty.call(result, key)) {
                    let arr = JSON.parse(JSON.stringify(result[key]));
                    arr.sku_code.push(index)
                    arr.store_name.push(val.name)
                    newResult.push(arr)
                }
            }
        }
    }
    return storeInit(data, newResult);
}
onMounted(async () => {
    initVlaue();
});
// 初始化参数
const initVlaue = () => {
    valueScheme.value = props.modelValue;
}

// 监听源数据变化
watch(() => props.modelValue, () => {
    initVlaue();
})
const addRow = () => {
    valueScheme.value.push({
        "value": [], "display": "新规格"
    });
    onUpdate();
}
const addOption = (unit) => {
    unit.value.push({
        "name": "新选项"
    })
    onUpdate();
}
const removeOption = (unit, index) => {
    unit.value.splice(index, 1);
    onUpdate();
}
const removeRow = (index) => {
    valueScheme.value.splice(index, 1);
    onUpdate();
}
const onUpdate = () => {
    emit('update:modelValue', valueScheme.value);
}
</script>
<style lang="less" scoped>
.sku-config-root {
    width: 100%;
    min-width: 400px;
    padding-bottom: 10px;

    .unit {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--color-light-9);
        margin: 10px 0;

        .name {
            height: 30px;
            overflow: hidden;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 30px;
            border: 1px solid var(--color-theme-light-4);
            background-color: var(--color-light-8);

            .input-box-root {
                margin: 0;
            }

            position: relative;
        }

        .options {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 10px 0;

            .icon-plus-icon {
                margin: 0 10px 10px 0;
            }

            .option-item {
                height: 30px;
                overflow: hidden;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 30px;
                border: 1px solid var(--color-theme-light-4);
                border-radius: 4px;
                margin: 0 10px 10px 0;

                .input-box-root {
                    margin: 0;
                }

                position: relative;
            }
        }

        .name>.icon-trash-icon,
        .option-item>.icon-trash-icon {
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 100%;
            color: var(--el-color-danger);
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

    }

    &.readonly {
        .unit {
            .name {
                .input-box-root {
                    margin: 0;
                }

                position: relative;
            }

            .options {
                .icon-plus-icon {
                    display: none;
                }
            }

            .name>.icon-trash-icon,
            .option-item>.icon-trash-icon {
                display: none;
            }

        }

        &>.button {
            display: none;
        }
    }
}
</style>
