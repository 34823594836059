<template>
    <div class="profile-item">
        <b>用户状态：</b>
        <span :style="{ 'color': userStatus == '禁用' ? '#CC3333' : '#33CC33' }">{{ userStatus }}</span>
    </div>
    <div class="profile-item">
        <b>用户角色：</b>
        <span>{{ userinfo.info.role.name }}</span>
    </div>
    <div class="profile-item">
        <b>上次登录时间：</b>
        <span>{{ lastLoginDatetime }}</span>
    </div>
    <div class="profile-item">
        <b>上次登录ip：</b>
        <span>{{ userinfo.info.lastloginip }}</span>
    </div>
    <div class="bottom-buttons">
        <div class="button replace-pwd normal-fill"
            @click="jumpToFindPwd">修改密码</div>
        <div class="button logout danger-fill"
            @click="logout">安全退出</div>
    </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from "vuex"
import { tools } from '@/assets/js/common.js'
export default {
    name: "UserProfile",
    setup() {
        var store = useStore();
        let userinfo = computed(() => {
            return store.getters.userinfo;
        })
        let userStatus = computed(() => {
            var status1 = userinfo.value.info.status, status2 = userinfo.value.info.admin.status;
            if (status1 == 1 && status2 == 1) {
                return "正常";
            }
            return "禁用";
        })
        let lastLoginDatetime = computed(() => {
            return tools.dateFormat("", userinfo.value.info.lastlogin * 1000);
        })
        function logout() {
            store.dispatch("logout")
        }
        function jumpToFindPwd() {
            store.dispatch("changeIdentifyState", "FindPwd");
        }
        return {
            userinfo,
            userStatus,
            lastLoginDatetime,
            logout,
            jumpToFindPwd
        }
    }
}
</script>
<style lang="less">
.profile-item {
    font-size: 14px;
    line-height: 45px;

    b {
        width: 100px;
        text-align: justify;
        text-align-last: justify;
        display: inline-block;
    }
}
</style>
