<template>
    <div class="shop-mine-root">
        <div class="shop-info">
            <div class="shop-basic">
                <Panel :title="'店铺信息'"
                    :border="true">
                    <ShopInfoDisplay>
                    </ShopInfoDisplay>
                </Panel>
            </div>
            <!-- <div class="shop-coupons">
                <Panel :title="'店铺优惠券'"
                    :border="true">
                </Panel>
            </div> -->
            <div class="sold-count">
                <Panel :title="'本店商品'"
                    :border="true">
                    <table class="getch-table">
                        <tr>
                            <th>#</th>
                            <th>商品</th>
                            <th>单价</th>
                            <th>点击量</th>
                            <th>销量</th>
                            <th>操作</th>
                        </tr>
                        <tr v-if="data.serviceList.length==0">
                            <td colspan="10">暂无记录</td>
                        </tr>
                        <tr v-else
                            v-for="(goods,index) in data.goodsList"
                            :key="index">
                            <td>{{index+1}}</td>
                            <td>
                                <div class="goods-name">
                                    <img :src="goods.cover"
                                        style="width:30px;height: 30px; border-radius: 50%;margin-right: 10px;"
                                        alt="商品图片.png">
                                    <span @click="openGoodsDetail(goods)">{{goods.name}}</span>
                                </div>
                            </td>
                            <td>
                                <InputBox v-model="goods.price_min"
                                    :type="'money'"
                                    :readonly="true"></InputBox>
                            </td>
                            <td>{{goods.hit}}</td>
                            <td>{{goods.sold}}</td>
                            <td>
                                <div class="button"
                                    @click="goodsDetail(goods)">详情</div>
                            </td>
                        </tr>
                    </table>
                </Panel>
            </div>
        </div>
        <div class="shop-report">
            <div class="shop-shelves scroll scroll-all">
                <Panel :title="'我的商品分类'"
                    :border="true">
                    <ShopShelvesDisplay></ShopShelvesDisplay>
                </Panel>
            </div>
            <div class="order-list">
                <Panel :title="'待处理售后'"
                    :border="true">
                    <table class="getch-table">
                        <tr>
                            <th>#</th>
                            <th>订单编号</th>
                            <th>售后类型</th>
                            <th>售后状态</th>
                            <th>申请时间</th>
                            <th>操作</th>
                        </tr>
                        <tr v-if="data.serviceList.length==0">
                            <td colspan="10">暂无记录</td>
                        </tr>
                        <tr v-else
                            v-for="(service,index) in data.serviceList"
                            :key="service">
                            <td>{{index+1}}</td>
                            <td>{{service.order_id}}</td>
                            <td><Label :text="data.dictionary.order_service_type.mapper[service.type]"></Label></td>
                            <td><Label :text="data.dictionary.order_service_status.mapper[service.status]"></Label></td>
                            <td style="text-align: center;padding: 0 10px;">
                                <DateFormat v-model="service.created_at"
                                    :readonly="true"></DateFormat>
                            </td>
                            <td>
                                <div class="button"
                                    @click="orderDetail(service)">处理</div>
                            </td>
                        </tr>
                    </table>
                </Panel>
            </div>
        </div>
        <!-- <Window></Window> -->
    </div>
</template>
<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../assets/js/apiMap';
import { tools } from '../../assets/js/common';
const store = useStore();
const data = reactive({
    dictionary: {
        order_service_status: { mapper: [] },
        order_service_type: { mapper: [] },
    },
    shop: {
    },
    goodsList: [],
    serviceList: []
})
const goodsDetail = goods => {
    tools.menuTrigger('商品详情', 'goods.detail', `/goods/edit/${goods.id}`)
}
const orderDetail = service => {
    tools.menuTrigger('订单详情','order.detal',`/order/edit/${service.order_id}`)
}
onMounted(async () => {
    await top.window.handle.getDictionary('order_service_status').then(async res => {
        data.dictionary.order_service_status = res;
    });
    await top.window.handle.getDictionary('order_service_type').then(async res => {
        data.dictionary.order_service_type = res;
    });
    await store.dispatch({
        type: apiMap.MallGoodsPageList,
        params: {
            page: 1, pageSize: 10, condition: {}, order: { sold: 'desc' }
        }
    }).then(res => {
        if (res && res.list) {
            data.goodsList = res.list;
        }
    });
    await store.dispatch({
        type: apiMap.MallOrderServicePageList,
        params: {
            page: 1, pageSize: 10, condition: { 'status.in': [0, 1] }, order: { created_at: 'desc' }
        }
    }).then(res => {
        if (res && res.list) {
            data.serviceList = res.list;
        }
    });
})
</script>
<style lang="less" scoped>
.shop-mine-root {
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    &>div {
        width: 50%;

        &>div:nth-child(1) {
            height: 370px;
        }

        .shop-basic {
            width: 100%;
            // height: 400px;
        }

        .goods-name {
            text-align: left;
            padding: 4px 10px;
            line-height: 30px;
            display: flex;
            align-items: center;
        }
    }

}
</style>