<template>
    <div class="table-list-root">
        <div class="buttons-contain">
            <div class="button"
                @click="insert()">新增</div>
            <div class="button"
                @click="getData()">刷新</div>
        </div>
        <div class="handle-area">
            <InputBox v-model="conf.queryCondtion.keywordsLike"
                :border="true"
                :radius="true"
                :placeholder="'请输入关键词'"></InputBox>
        </div>
        <div class="data-area scroll scroll-all">
            <table class="getch-table">
                <thead>
                    <tr>
                        <th class="col-sharp">#</th>
                        <th v-for="(field, index) in conf.fieldSchema"
                            :key="index">
                            {{ field.display }}
                        </th>
                        <th class="recode-tools">操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(recode, index) in conf.dataList"
                        :key="index">
                        <td class="col-sharp">{{ index + 1 }}</td>
                        <td v-for="(field, index) in conf.fieldSchema"
                            :key="index">
                            <div class="table-cell-container text-etc"
                                v-if="field.value != 'deleted_at'">
                                <component v-model="recode[field.value]"
                                    :dictionary="field.dictionary"
                                    :is="field.component"
                                    :foreignValue="recode[`${field.value}Foreign`]"
                                    :config="field.config"
                                    :readonly="!field.config.editInList"></component>
                                <!-- {{recode[field.value]}} -->
                            </div>
                        </td>
                        <td class="recode-tools">
                            <div class="recode-tools-contain">
                                <div class="button"
                                    v-for="(action, index) in conf.tableSchema.row_actions"
                                    v-show="action.condition ? action.condition(recode) : true"
                                    :key="index"
                                    @click="action.action(recode, index)">
                                    {{ action.display }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-show="!conf.loading && conf.dataList.length == 0"
                style="width: 100%;text-align:center;line-height:30px;">暂无记录</div>
            <Waitting v-show="conf.loading"
                :text="'加载中'"></Waitting>
        </div>
        <div class="footer-area">
            <Paginate :config="conf.pageInfo"
                @on-page-changed="getData"></Paginate>
        </div>
        <Window v-if="conf.winConf.display"
            :config="conf.winConf"
            @closeWin="conf.winConf.display = false"></Window>
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiMap } from "../../assets/js/apiMap";
import { tools } from "../../assets/js/common";
const store = useStore()
const tableName = 'mall_goods';
const conf = reactive({
    loading: false,
    tableSchema: {},
    fieldSchema: [],
    pageInfo: {
        page: 1,
        pageSize: 20,
        total: 1,
        count: 1,
    },
    winConf: {
        title: "弹窗",
        url: '',
        type: 0,
        width: 800,
        height: 1000,
        display: false,
        isFull: true,
    },
    queryCondtion: {
        keywordsLike: '',
    },
    keywordsButtons: [
        {
            display: '查询',
            action: e => {
                getData();
            }
        }
    ],
    orderMethod: [],
    dataList: [],
});
const insert = async () => {
    conf.winConf.display = true;
    conf.winConf.url = `/goods/edit/0`;

}
const getData = async () => {
    conf.loading = true;
    conf.dataList = [];
    store.dispatch({
        type: 'request',
        url: `/admin/${tableName.split('_').join('/')}/pageList`,
        method: 'get',
        params: {
            page: conf.pageInfo.page, pageSize: conf.pageInfo.pageSize, condition: conf.queryCondtion, order: conf.orderMethod
        }
    }
    ).then(res => {
        conf.loading = false;
        if (!res) {
            return;
        }
        conf.dataList = res.list;
        delete res.list;
        conf.pageInfo = res;
    });
}
const updateRecode = recode => {
    store.dispatch({
        type: apiMap.MallGoodsUpdate,
        data: {
            id: recode.id,
            data: recode,
        }
    }).then(res => {
        recode = res;
    })
}
onMounted(async () => {
    await store.dispatch({
        type: apiMap.SysTableSelectBy,
        data: {
            key: 'value', value: tableName
        }
    }).then(res => {
        conf.tableSchema = res;
    });
    await store.dispatch({
        type: apiMap.SysTableFieldPageList,
        params: {
            page: 1, pageSize: 100, condition: { table_value: tableName, show_in_list: 1 }, order: { position: 'asc' }
        }
    }).then(res => {
        conf.fieldSchema = res.list
    });
    await getData();
    conf.tableSchema.row_actions = {
        edit: {
            action: (recode) => {
                conf.winConf.display = true;
                conf.winConf.url = `/goods/edit/${recode.id}`;
            },
            display: '编辑',
        },
        up: {
            condition: (recode) => recode.status == 0,
            action: (recode) => {
                recode.status = 1;
                updateRecode(recode);
            },
            display: '上架',
        },
        down: {
            condition: (recode) => recode.status != 0,
            action: (recode) => {
                recode.status = 0;
                updateRecode(recode);
            },
            display: '下架',
        },
        recommend: {
            action: (recode) => {
                recode.status = 2;
                updateRecode(recode);
            },
            display: '推荐',
        },
        delete: {
            action: (recode, index) => {
                if (!window.confirm('是否删除选中数据')) {
                    return;
                }
                store.dispatch({
                    type: apiMap[tools.ucWords(tableName).replaceAll(' ', '') + 'Trash'],
                    data: {
                        ids: recode.id
                    }
                }).then(res => {
                    if (res) {
                        top.window.sendMessage('删除成功');
                        conf.dataList.splice(index, 1);
                        return;
                    }
                    top.window.sendMessage('删除失败');
                });
            },
            display: '删除',
        },
    }
})
</script>
<style lang="less" scoped>
.table-list-root {
    width: 100%;
    height: 100%;
    padding: 8px;

    .handle-area {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;

        .input-box-root {
            width: 300px;
            margin: 0;
        }
    }

    .data-area {
        width: 100%;
        padding: 0px 0 8px;
        border-bottom: 1px solid;

        td,
        th {
            padding: 0 10px;
            text-align: center;

            .table-cell-container {
                max-width: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .footer-area {
        position: relative;
        height: 80px;
        padding: 10px;
    }
}
</style>