<template>
  <div
    :class="[
      'page',
      {
        'menu-active': pageConfig.menuDisplayStatus,
      },
    ]"
  >
    <PageHeaderVue></PageHeaderVue>
    <PageLabelBarVue
      :pageInfo="pageInfo"
      @pageSelect="pageSelect($event)"
    ></PageLabelBarVue>
    <div class="page-container scroll">
      <iframe
        v-for="(pageItem, index) in pageInfo.pageList"
        :class="['page-container-iframe', { active: index == pageInfo.activeLabelIndex }]"
        :src="pageItem.href"
        frameborder="0"
      ></iframe>
      <!-- <SKUDemoVue></SKUDemoVue> -->
      <!-- {{pageInfo}} -->
    </div>
    <div class="page-footer">
      <div class="page-footer-left-container"></div>
      <div class="page-footer-center-container">@利荟科技</div>
      <div class="page-footer-right-container">
        <span class="page-footer-right-time-now">{{ timeNow }}</span>
      </div>
    </div>
  </div>
  <div :class="['sys-menu', 'normal', { active: pageConfig.menuDisplayStatus }]">
    <div class="sys-menu-switch">
      <div @click="menuSwitchClick()">||</div>
    </div>
    <div class="sys-menu-container scroll scroll-y">
      <page-menu-node
        class="normal"
        v-for="(item, index) in pageConfig.menuTree"
        :node="item"
        @menuTrigger="menuTrigger"
      >
      </page-menu-node>
    </div>
  </div>
  <div class="message-container" :class="{ hide: !message.length }">
    <div v-for="(item, index) in message" :class="['message', item.type]">
      {{ item.type == undefined ? item : `[${item.type}] : ${item.content}` }}
    </div>
  </div>
  <div class="media-preview" v-show="pageConfig.mediaPreview.display">
    <MediaContainer
      :resource="pageConfig.mediaPreview.resource"
      :size="pageConfig.mediaPreview.size"
    ></MediaContainer>
    <div class="tools">
      <span class="iconfont icon-zoom-out-icon" @click="resourceZoomOut"></span>
      <span class="iconfont icon-power-icon" @click="resourceClose"></span>
      <span class="iconfont icon-reload-2-icon" @click="resourceRepeat"></span>
      <span class="iconfont icon-zoom-in-icon" @click="resourceZoomIn"></span>
    </div>
  </div>
</template>

<script setup>
/*
    后台结构
    index+iframe实现
*/
import PageMenuNode from "@/components/page-struct/PageMenuNode.vue";
import { computed, onMounted, reactive } from "vue";
import { tools } from "@/assets/js/common";
import PageLabelBarVue from "@/components/page-struct/PageLabelBar.vue";
import PageHeaderVue from "@/components/page-struct/top-bar/PageHeader.vue";
import { useStore } from "vuex";
import { apiMap } from "../assets/js/apiMap";
let store = useStore();
// 加载用户信息
// 加载菜单
let pageConfig = reactive({
  menuDisplayStatus: true,
  menuTree: [],
  mediaPreview: {
    display: false,
    size: 1,
    resource:
      "http://lhhb.oss-cn-hangzhou.aliyuncs.com/storage/jpg/afd488f0b2399eee0e12b3051a635414.jpg",
  },
});
const resourceZoomOut = () => {
  if (pageConfig.mediaPreview.size <= 0.5) {
    return;
  }
  pageConfig.mediaPreview.size -= 0.1;
};
const resourceClose = () => {
  resourceRepeat();
  pageConfig.mediaPreview.resource = "";
  pageConfig.mediaPreview.display = false;
};
const resourceRepeat = () => {
  pageConfig.mediaPreview.size = 1;
};
const resourceZoomIn = () => {
  if (pageConfig.mediaPreview.size >= 2) {
    return;
  }
  pageConfig.mediaPreview.size += 0.1;
};
const message = computed(() => {
  return store.state.message;
});
top.window.sendMessage = (message) => {
  store.dispatch({
    type: "addMessage",
    message: message,
  });
};
const pageInfo = reactive({
  pageList: [],
  activeLabelIndex: 0,
});
// 打开首页
// 打开登录页
const openIndex = async () => {
  pageInit();
  menuTrigger({ name: "首页", href: "/home", code: "home", static: true });
};
const openLogin = async () => {
  pageInfo.pageList = [];
  pageConfig.menuTree = [
    {
      name: "请登录",
      options: [
        { name: "登录", code: "identify.login", href: "/identify/login", static: true },
      ],
    },
  ];
  menuTrigger({ name: "首页", href: "/home", code: "home", static: true });
  // menuTrigger({
  //   name: "登录",
  //   code: "identify.login",
  //   href: "/identify/login",
  //   static: true,
  // });
};
const pageInit = async () => {
  // 获取菜单
  // 获取用户信息
  store
    .dispatch({
      type: apiMap.SysMenuPageList,
      params: {
        page: 1,
        pageSize: 1000,
        condition: { "type.in": [2, 3] },
        order: [{ type: "desc" }, { id: "asc" }],
      },
    })
    .then((res) => {
      if (res) {
        pageConfig.menuTree = menuDataFormat(res.list);
      }
    });
};
const menuDataFormat = (menuData, result = []) => {
  let keyMap = {};
  // 选出目录  选出选项
  for (let i = 0, len = menuData.length; i < len; i++) {
    let recode = menuData[i];
    if (recode.type == 3) {
      recode.options = [];
      keyMap[`_${recode.id}`] = result.length;
      result.push({
        ...recode,
        isOpen: false,
      });
    }
  }
  for (let i = 0, len = menuData.length; i < len; i++) {
    let recode = menuData[i];
    if (recode.type == 2) {
      result[keyMap[`_${recode.parent_id}`]].options.push({
        ...recode,
        href: recode.config.href,
      });
    }
  }
  return result;
};
const userInfo = computed(async () => {
  let info = {};
  await store
    .dispatch({
      type: "getUserInfo",
    })
    .then((res) => {
      info = res;
    });
  return info;
});
onMounted(async () => {
  window.handle = {
    openIndex,
    openLogin,
    menuTrigger,
    getDictionary, //: key => getDictionary(key).then(res => res)
    openResource,
    initDictionary,
  };
  await initDictionary();
  await pageInit();
  await openIndex();
  // openLogin();
});
const openResource = (resource) => {
  pageConfig.mediaPreview.display = true;
  pageConfig.mediaPreview.resource = resource;
};
const initDictionary = async (page = 1, pageSize = 50) => {
  await store
    .dispatch({
      type: apiMap.SysDictionaryPageList,
      params: {
        page: 1,
        pageSize: 50,
      },
    })
    .then(async (res) => {
      for (const key in res.list) {
        if (Object.hasOwnProperty.call(res.list, key)) {
          const element = res.list[key];
          store.commit({
            type: "setDictionary",
            data: element,
          }); //.dictionary[element.code] = element;
        }
      }
      if (res.count == pageSize) {
        await initDictionary(++page, pageSize);
      }
    });
};
const getDictionary = async (key) => {
  return store.dispatch({
    type: "getDictionary",
    key,
  });
};
const menuSwitchClick = () => {
  pageConfig.menuDisplayStatus = !pageConfig.menuDisplayStatus;
};
const menuTrigger = (menu) => {
  console.log('=================menu', menu);
  pageInfo.activeLabelIndex = 0;
  let page = queryPageList(menu.code);
  if (!page) {
    pageInfo.pageList.push(menu);
    pageSelect(pageInfo.pageList.length - 1);
    return;
  }
  pageSelect(page.index);
};
const queryPageList = (key) => {
  let pageLen = pageInfo.pageList.length;
  for (let i = 0, j = pageLen - 1; i <= j; i++, j--) {
    let pageI = pageInfo.pageList[i];
    let pageJ = pageInfo.pageList[j];
    if (pageI.code == key) {
      return { page: pageI, index: i };
    }
    if (pageJ.code == key) {
      return { page: pageJ, index: j };
    }
  }
  return null;
};
const pageSelect = (index) => {
  pageInfo.activeLabelIndex = index;
};
const timeNow = computed(() => {
  return tools.dateFormat("Y-m-d");
});
// pageConfig.menuTree = [
//     {
//         title: 'demo',
//         options: [
//             { label: '数据列表', key: 'demo.data.list', href: '/demo/data/list' },
//             { label: '数据编辑', key: 'demo.data.edit', href: '/demo/data/edit' },
//             { label: '数据展示', key: 'demo.data.display', href: 'demo/data/display' },
//             // { label: '前端配置', key: 'qdpz', href: '#' },
//             // { label: '前端配置', key: 'qdpz', href: '#' },
//             // { label: '前端配置', key: 'qdpz', href: '#' },
//         ]
//     },
// ];
// import { getCurrentInstance } from 'vue';

// export default {
//     setup(props) {
//         let global = getCurrentInstance();
//         console.log("全局变量", global);
//     }
// };

/**
 * 主进程 ->
 */
</script>
<style lang="less" scoped>
@menuWidth: 200px;

.media-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  // width: 50%;
  // height: 50%;
  top: 0%;
  left: 0%;
  background-color: var(--bg-transparent-99);
  z-index: var(--index-top);
  overflow: hidden;

  // display: flex;
  // align-items: center;
  // justify-content: center;

  ::v-deep .media-container-root {
    position: absolute;
  }

  & > .tools {
    position: absolute;
    bottom: 30px;
    height: 30px;
    width: 100%;
    // background-color: var(--color-light-11);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--index-top);
    margin: 0 auto;

    & > .iconfont {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      color: white;
      background-color: var(--bg-transparent-99);
      margin: 0 10px;

      &:hover {
        opacity: 0.5;
        font-size: 18px;
      }
    }
  }
}

.sys-menu {
  position: absolute;
  height: calc(100% - var(--line-height-1) - var(--line-height-5));
  left: -@menuWidth;
  background-color: var(--color-light-10);
  top: var(--line-height-1);
  color: var(--color-light-7);
  width: @menuWidth;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--color-light-4);
  transition: var(--animate-transition);

  &.normal {
    // width: 200px;
    // left: -200px;
    .sys-menu-container {
      padding: 0;
      background-color: #333243;
    }
  }

  // box-shadow: 5px 0px 4px -4px #000;
  &.active {
    left: 0;
  }

  .sys-menu-switch {
    position: absolute;
    opacity: 0.8;
    left: 100%;
    // background-color: var(--color-theme-light-3);
    width: 20px;
    height: 80px;
    color: var(--color-light-10);
    border: 10px solid red;
    border-color: transparent transparent transparent var(--color-theme-light-3);
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div {
      width: 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -10px;
      top: 0;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .sys-menu-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px 14px 10px 15px;
  }
}

.page {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--color-light-11);
  padding: calc(var(--line-height-1) + var(--line-height-4)) 12px var(--line-height-4);
  transition: var(--animate-transition);

  .page-footer {
    width: 100%;
    height: var(--line-height-5);
    background-color: var(--color-light-2);
    color: var(--color-light-10);
    font-size: var(--font-size-1);
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    .page-footer-right-container {
      text-align: right;
    }
  }

  .page-container {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: hidden;

    .page-container-iframe {
      width: 100%;
      height: 100%;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  &.menu-active {
    padding-left: @menuWidth + 12px;

    ::v-deep .page-label-bar {
      padding-left: @menuWidth + 12px;
    }
  }
}

.message-container {
  width: 200px;
  position: fixed;
  right: 0;
  top: 60px;
  height: auto;
  z-index: 1;
  color: white;
  transition: var(--animate-transition);

  &.hide {
    height: 0;
  }

  .message {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--animate-transition);
    background-color: var(--el-color-success);
    // height: 30px;
    margin: 10px auto;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    opacity: 0.8;
    padding: 10px 10px;
    line-height: 20px;

    &.warn {
      color: white;
      background-color: var(--el-color-warning);
    }

    &.error {
      color: white;
      background-color: var(--el-color-danger);
    }
  }
}
</style>
