<template>
    <div class="window"
        @click.self="closeWindow"
        v-show="config.display">
        <div class="window-contain"
            @click.stop
            :style="{ width: width, height: height }">
            <div class="window-contain-header">
                <div class="window-contain-title">{{ config.title }}</div>
                <div class="window-full-icon iconfont"
                    :class="{
                        'icon-resize-minus-3-icon': config.isFull,
                        'icon-resize-plus-3-icon': !config.isFull,
                    }"
                    @click="fullScreen"></div>
                <div class="window-close iconfont icon-power-icon"
                    title="关闭"
                    @click="closeWindow"></div>
            </div>
            <div class="window-body">
                <iframe v-if="config.type == '0'"
                    :src="config.url"
                    frameborder="0"></iframe>
                <template v-else-if="config.type == '1'"
                    v-html="config.url"></template>
                <slot v-else-if="config.type == '2'"></slot>
            </div>
        </div>
    </div>
</template>
<script>
// 后期需要添加动画
import { computed, toRefs } from "vue";
import { useStore } from "vuex";

export default {
    props: {
        config: Object,
    },
    setup(props, context) {
        let store = useStore(),
            { config } = toRefs(props),
            width = computed(() => {
                if (config.value.isFull) {
                    return "100%";
                }
                if (isNaN(config.value.width)) {
                    return config.value.width;
                } else {
                    return `${config.value.width}px`;
                }
            }),
            height = computed(() => {
                if (config.value.isFull) {
                    return "100%";
                }
                if (isNaN(config.value.height)) {
                    return config.value.height;
                } else {
                    return `${config.value.height}px`;
                }
            }),
            defaultConfig = {
                title: "",
                url: "",
                type: 0, // 0:iframe,1:html,2:component
                width: 600,
                height: 400,
                display: false,
                isFull: false,
            };
        config.value.title = config.value.title
            ? config.value.title
            : defaultConfig.title;
        config.value.url = config.value.url ? config.value.url : defaultConfig.url;
        config.value.width =
            config.value.width && !isNaN(config.value.width)
                ? config.value.width
                : defaultConfig.width;
        config.value.height =
            config.value.height && !isNaN(config.value.height)
                ? config.value.height
                : defaultConfig.height;
        config.value.type = config.value.type ? config.value.type : defaultConfig.type;
        function fullScreen() {
            config.value.isFull = !config.value.isFull;
        }
        function closeWindow() {
            context.emit('closeWin')
            if (config.value.type == 0) {

            } else {
                config.value.display = false;
            }
        }
        return {
            closeWindow,
            fullScreen,
            width,
            height,
        };
    },
};
</script>
<style lang="less">
@import url("~@/assets/less/global-config/config.less");
// 弹窗

.window {
    background-color: var(--bg-transparent-99);
    color: @textColor;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .window-contain {
        padding: 30px 0px 0;
        border: 1px solid var(--color-theme);
        border-radius: 8px;
        background-color: var(--el-color-white);
        overflow: auto;
        position: relative;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
        transition: all ease .3s;

        .window-contain-header {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0px 15px;
            width: 100%;
            background-color: var(--color-theme);
            height: 30px;
            line-height: 30px;
            color: white;
            text-align: left;

            .window-close {
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                cursor: pointer;
                text-align: center;

                &:hover {
                    opacity: 0.8;
                }
            }

            .window-full-icon {
                cursor: pointer;
                position: absolute;
                right: 30px;
                top: 0;
                width: 30px;
                text-align: center;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .window-body {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: relative;

            &>iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .window {
        padding: 0;

        .window-contain {
            .window-full-icon {
                display: none;
            }

            border-radius: 0;
            border: none;
            height: 100% !important;
            width: 100% !important;
        }
    }
}
</style>
