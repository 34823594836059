<template>

    <div v-if="readonly"
        class="time-selector-root readonly">
        {{valueScheme.d}}天
        {{valueScheme.h}}时
        {{valueScheme.i}}分
        {{valueScheme.s}}秒
    </div>
    <div v-else
        class="time-selector-root">
        <InputBox class="time-selector-el"
            v-model="valueScheme.d"
            :placeholder="'天'"
            :suffix="'天'"
            :border="true"
            :readonly="readonly"
            :preg="'/^[0-9]{1,3}$/'"
            :len="3"
            :default="0"
            @change="dataChange"></InputBox>
        <InputBox class="time-selector-el"
            v-model="valueScheme.h"
            :placeholder="'时'"
            :suffix="'时'"
            :border="true"
            :readonly="readonly"
            :preg="'/^[0-9]{1,3}$/'"
            :len="3"
            :default="0"
            @change="dataChange"></InputBox>
        <InputBox class="time-selector-el"
            v-model="valueScheme.i"
            :placeholder="'分'"
            :suffix="'分'"
            :border="true"
            :readonly="readonly"
            :preg="'/^[0-9]{1,3}$/'"
            :len="3"
            :default="0"
            @change="dataChange"></InputBox>
        <InputBox class="time-selector-el"
            v-model="valueScheme.s"
            :placeholder="'秒'"
            :suffix="'秒'"
            :border="true"
            :readonly="readonly"
            :preg="'/^[0-9]{1,3}$/'"
            :len="3"
            :default="0"
            @change="dataChange"></InputBox>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
const props = defineProps({
    readonly: Boolean,
    modelValue: { type: [Number, String] }
});
const emit = defineEmits([
    'update:modelValue'
]);
const valueScheme = reactive({
    d: 0,// 日
    h: 0,// 时
    i: 0,// 分
    s: 0,// 秒
});

onMounted(async () => {
    initVlaue();
});
const initVlaue = () => {
    let t = Number(props.modelValue);
    let d = t % 60;
    t = (t - d) / 60;
    valueScheme.s = d;
    d = t % 60;
    t = (t - d) / 60;
    valueScheme.i = d;
    d = t % 24;
    t = (t - d) / 24;
    valueScheme.h = d;
    valueScheme.d = t;
}
watch(() => props.modelValue, () => {
    initVlaue();
})
const dataChange = () => {
    let t =
        Number(valueScheme.s) +
        Number(valueScheme.i * 60) +
        Number(valueScheme.h * 3600) +
        Number(valueScheme.d * 24 * 3600);
    emit('update:modelValue', t)
}
</script>
<style lang="less" scoped>
.time-selector-root {
    width: 100%;
    height: 100%;
    display: flex;
    min-width: 240px;

    &.readonly {
        min-width: unset;
        white-space: nowrap;
    }

    .time-selector-el {
        width: 60px;
        max-width: 60px;
    }
}
</style>
