<template>
    <div class="data-list-root">
        <div class="data-header"
            >
            <div class="data-row"
                >
                <div class="data-cell ordinal">#</div>
                <div v-for="(col,index) in columns"
                    class="data-cell text-etc"
                    :style="{width:col.size}">{{col.value}}</div>

            </div>
        </div>
        <div class="data-body">
            <div v-for="(row,index) in list"
                class="data-row"
                >
                <div class="data-cell ordinal">{{index+1}}</div>
                <div v-for="(col,colIndex) in columns"
                    class="data-cell"
                    :style="{width:col.size}"
                    :key="colIndex">
                    <component v-if="col.component"
                        :is="col.component"
                        v-model="row[col.value]"></component>
                    <template v-else>{{row[col.value]}}
                    </template>
                </div>
            </div>
        </div>
        <div class="data-footer"
            >
            <!-- <PaginateVue></PaginateVue> -->111
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
const defaultConf = {
    height: '32px',
};
const realHeight = computed(() => {
    return props.height ?? defaultConf.height;
})
const props = defineProps({
    columns: Array,
    list: Array,
    paginate: Object
})
</script>

<style lang="less" scoped>
.data-list-root {
    position: relative;
    border: 1px solid var(--color-light-11);

    .data-row {
        height: var(--table-row-height);
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--color-light-11);

        .data-cell {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 10px;
            font-size: 14px;
        border-right: 1px solid var(--color-light-11);

            &.ordinal {
                width: 48px;
            }

            &:nth-last-child(1) {
                border-right: 0;
            }
        }

        &:nth-last-child(1) {
            border-bottom: 0;
        }
    }

    .data-header {
        display: flex;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;
        background-color: var(--color-theme-light-8);
        .data-cell {
            font-weight: bold;
            text-align: center;
        }

        border-bottom: 1px solid var(--color-light-11);
    }


    .data-body {
        height: 100%;
        overflow: auto;

    }

    .data-footer {
        border-top: 1px solid var(--color-light-11);
        display: flex;
        align-items: center;
        padding: 4px 10px;
    }
}
</style>