export default {
            MallGoodsStoreInsert: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/goods/store/insert',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreInsertMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/goods/store/insertMulti',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreUpdate: ({ dispatch, commit, state }, payload) => {
                let id=payload.data.id;delete payload.data.id;
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/goods/store/update/${id}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreUpdateMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/goods/store/updateMulti',
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreSelect: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/goods/store/select/${payload.data.id}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreSelectBy: ({ dispatch, commit, state }, payload) => {
                
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/goods/store/selectBy/${payload.data.key}/${payload.data.value}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStorePageList: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/goods/store/pageList',
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreTrash: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/goods/store/trash/${payload.data.ids}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreRecovery: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/goods/store/recovery/${payload.data.ids}`,
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallGoodsStoreDelete: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/goods/store/delete/${payload.data.ids}`,
                    method: 'delete',
                    data: payload.data,
                    params: payload.params,
                });
            }
        };