export default {
            SysTableFieldInsert: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/sys/table/field/insert',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldInsertMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/sys/table/field/insertMulti',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldUpdate: ({ dispatch, commit, state }, payload) => {
                let id=payload.data.id;delete payload.data.id;
                return dispatch({
                    type: 'request',
                    url: `/admin/sys/table/field/update/${id}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldUpdateMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/sys/table/field/updateMulti',
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldSelect: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/sys/table/field/select/${payload.data.id}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldSelectBy: ({ dispatch, commit, state }, payload) => {
                
                
                return dispatch({
                    type: 'request',
                    url: `/admin/sys/table/field/selectBy/${payload.data.key}/${payload.data.value}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldPageList: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/sys/table/field/pageList',
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldTrash: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/sys/table/field/trash/${payload.data.ids}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldRecovery: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/sys/table/field/recovery/${payload.data.ids}`,
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            SysTableFieldDelete: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/sys/table/field/delete/${payload.data.ids}`,
                    method: 'delete',
                    data: payload.data,
                    params: payload.params,
                });
            }
        };