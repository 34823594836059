<template>
	<div :class="['input-box-root',{
		'border':border,
		'radius':radius,
		'with-button':buttonGroup
	}]">
		<span v-if="readonly">{{valueScheme}}</span>
		<template v-else>
			<div v-show="prefix"
				class="input-prefix"
				ref="prefixDom">
				{{prefix}}
			</div>
			<div class="input-container">
				<span v-show="readonly">{{valueScheme}}</span>
				<input v-show="!readonly"
					:type="type"
					:placeholder="placeholder??'请输入内容'"
					v-model="valueScheme"
					:maxlength="len"
					:readonly="readonly"
					@input="onInput"
					@change="onChange"
					@focus="$event.target.select()">

				<p v-show="data.isError"
					class="remarks">* {{data.errorMessage}}</p>
			</div>
			<div class="input-suffix"
				v-show="suffix">{{suffix}}
			</div>
			<div class="input-button-group">
				<div v-for="(btn,index) in buttonGroup"
					:class="['button']"
					:key="btn"
					@click="btnClick(btn)">{{btn.display}}</div>
			</div>
		</template>
	</div>
</template>
<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
const emit = defineEmits(['update:modelValue', 'change'])
const props = defineProps({
	modelValue: [String, Number],
	len: [String, Number],
	border: Boolean,
	radius: Boolean,
	readonly: Boolean,
	prefix: String,
	suffix: String,
	default: [String, Number],
	preg: [String, RegExp],
	inputPreg: [String, RegExp],
	type: { type: String, default: 'text' },
	placeholder: { type: String, default: '请输入内容' },
	// {emit:,display}
	buttonGroup: { type: Array, default: [] },
});
const valueScheme = ref('');
onMounted(async () => {
	initVlaue();
});
const initVlaue = () => {
	if (props.type == 'money') {
		valueScheme.value = parseFloat(props.modelValue).toFixed(2);
		// console.log(valueScheme.value);
	} else {
		valueScheme.value = props.modelValue;
	}
}
watch(() => props.modelValue, () => {
	initVlaue();
})
const btnClick = btn => {
	btn.action(valueScheme.value);
}
const onInput = (e) => {
	// try {
	// 	if (!eval(props.preg).test(valueScheme.value)) {
	// 		valueScheme.value = props.default ?? '';
	// 		data.isError = true;
	// 		data.errorMessage = '内容格式错误';
	// 	} else {
	// 		data.isError = false;
	// 	}
	// } catch (e) {

	// }
	emit('update:modelValue', valueScheme.value)
}
const onChange = (e) => {
	try {
		if (!eval(props.preg).test(valueScheme.value)) {
			valueScheme.value = props.default ?? '';
			data.isError = true;
			data.errorMessage = '内容格式错误';
		} else {
			data.isError = false;
		}
	} catch (e) {

	}
	emit('update:modelValue', valueScheme.value)
	emit('change', valueScheme.value)
}
const data = reactive({
	isError: false,
	errorMessage: '',
});
</script>
<style lang="less" scoped>
.input-box-root {
	width: 100%;
	height: var(--input-height);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	// margin-bottom: 20px;

	&.with-button {
		border-radius: 4px;
	}

	.input-prefix {
		white-space: nowrap;
		// text-overflow: ;
		padding-right: 10px;
	}

	&.border {
		.input-container {
			border: 1px solid var(--color-theme-light-4);
		}
	}


	&.radius {
		.input-container {
			border-radius: 4px;
		}
	}

	.input-container {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;

		span {
			padding: 0 10px;
		}

		input {
			width: 100%;
			height: 100%;
			border: none;
			outline: 0;
			background-color: transparent;
			padding: 0 10px;
			border-radius: inherit;

			&[readonly] {
				background-color: var(--color-light-9);
			}
		}

		.remarks {
			position: absolute;
			top: calc(100% + 4px);
			color: var(--el-color-error);
			font-size: 12px;
			width: 100%;
			text-align: left;
			padding: 0 10px;
		}
	}

	.input-button-group {
		height: 100%;
		display: flex;

		.button {
			white-space: nowrap;
			height: calc(100% - 2px);
			display: flex;
			align-items: center;
			margin: 0;
			border-radius: 0;
			border-right: 1px solid;

			&:nth-child(1) {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}

			&:nth-last-child(1) {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				border: none;
			}
		}
	}

}
</style>