<template>
    <div class="legal-info-root">
        <!-- <div class="item">
            <span class="title">姓名</span>
            <Label :text="modelValue.name"></Label>
        </div>
        <div class="item">
            <span class="title">电话</span>
            <Label :text="modelValue.mobile"></Label>
        </div> -->
        <MediaUpload v-model="modelValue.idCard"
            :readonly="true"></MediaUpload>
        <MediaUpload v-model="modelValue.idCardBack"
            :readonly="true"></MediaUpload>
    </div>
</template>
<script setup>
import { onMounted, reactive, watch } from 'vue';
const props = defineProps({
    modelValue: [Object, null, undefined]
});
const initVlaue = () => {

}
watch(() => props.modelValue, () => {
    initVlaue();
})
onMounted(async () => {
    initVlaue();
});
</script>

<style lang="less" scoped>
.legal-info-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .item {
        width: 100%;
        padding-left: 60px;
        position: relative;
        display: flex;
        align-items: center;

        .title {
            width: 60px;
            padding: 0 10px;
            position: absolute;
            left: 0;
        }
    }
}
</style>