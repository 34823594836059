/** @format */

export default {
	setUserInfo({ commit }, payload) {
		delete payload.type;
		localStorage.setItem('userProfile', JSON.stringify(payload));
		console.log('设置用户信息为', payload.data);
		commit({
			type: 'setUserinfo',
			data: payload.data,
		});
	},
	getUserInfo({ state }, payload) {
		let profile = state.userInfo.profile;
		if (!profile) {
			// 尝试从缓存或者后台获取profile
			profile = localStorage.getItem('userProfile');
			try {
				profile = JSON.parse(profile);
				state.userInfo.profile = profile;
			} catch (e) {}
		}
		return profile;
	},
	setToken({ state }, payload) {
		localStorage.setItem('token', payload.token);
		state.userInfo.token = payload.token;
	},
	getToken({ state, commit }, payload) {
		let token = state.userInfo.token;
		if (!token) {
			token = localStorage.getItem('token');
			state.userInfo.token = token;
		}
		return token;
	},
	addMessage({ state }, payload) {
		if (state.message.length >= 20) {
			state.message.shift();
		}
		state.message.push(payload.message);
		setTimeout(() => {
			state.message.shift();
		}, 3000);
	},
	setCache({ dispatch }, payload) {
		if (!top.window.cacheCustom || top.window.cacheCustom.length > 10000) {
			top.window.cacheCustom = {};
		}
		top.window.cacheCustom[payload.key] = payload.value;
	},
	getCache({ state }, payload) {
		try {
			return top.window.cacheCustom[payload.key];
		} catch {
			return null;
		}
	},
	getDictionary({ state }, payload) {
		// if
		return state.dictionary[payload.key]; //[payload.key];
	},
};
