<template>
    <div class="shop-info-display-root"
        v-if="data.pageLoaded">
        <div class="top">
            <div class="logo">
                <MediaUpload v-model="data.shop.logo"></MediaUpload>
            </div>
            <div class="basic-info">
                <div class="row">
                    <span class="title">店铺名称</span>
                    <InputBox v-model="data.shop.name"
                        :border="true"></InputBox>
                </div>
                <div class="row">
                    <span class="title">店铺状态</span>
                    <Label style="width: 100px;text-align:center;"
                        :text="data.dictionary.shopStatus.mapper[data.shop.status]"></Label>
                </div>
                <div class="row">
                    <span class="title">店铺地址</span>
                    <span>{{data.shop.address}}-{{data.shop.address_detail}}</span>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="row">
                <span class="title">店铺背景</span>
                <span>
                    <MediaUpload v-model="data.shop.background"></MediaUpload>
                </span>
            </div>
            <div class="row">
                <span class="title">关键词</span>
                <span>
                    <InputBox v-model="data.shop.keywords"
                        :placeholder="'请输入店铺关键词'"
                        :border="true"></InputBox>
                </span>
            </div>
            <div class="row">
                <div class="button"
                    @click="save">更新店铺信息</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../../../assets/js/apiMap';
const store = useStore();
const data = reactive({
    pageLoaded: false,
    noShop: false,
    dictionary: {

    },
    shop: {
        id: '',
        keywords: '',
        background: '',
        category_code: '',
        set_top: '',
        name: '',
        logo: '',
        address: '',
        address_detail: '',
        point: '',
        manager: '',
        manager_mobile: '',
        license: '',
        banner: '',
        images: '',
        legal_info: '',
        sold_count: '',
        refunded_count: '',
        status: '',
        remarks: '',
        created_at: '',
    },
    userInfo: {

    }
})

const save = () => {
    store.dispatch({
        type: 'request',
        url: '/api/shop/updateMine',
        method: 'put',
        data: data.shop
    }).then(res => {
        if (res) {
            top.window.sendMessage({
                type: 'success',
                content: '保存成功'
            })
        }
    });
}

onMounted(async () => {
    await top.window.handle.getDictionary('shop_status').then(
        res => {
            data.dictionary.shopStatus = res;
        }
    )
    await store.dispatch({
        type: 'getUserInfo'
    }).then(res => {
        data.userInfo = res.data;
    });
    await store.dispatch({
        type: apiMap.MallShopSelectBy,
        data: {
            key: 'user_id',
            value: data.userInfo.id,
        }
    }).then(res => {
        if (res) {
            data.shop = res;
        } else {
            data.noShop = true;
        }
    });
    data.pageLoaded = true;
})
</script>
<style lang="less" scoped>
.shop-info-display-root {
    .top {
        height: 100px;
        padding-left: 100px;
        position: relative;
        border-bottom: 1px solid var(--color-light-6);

        .logo {
            width: 100px;
            height: 100px;
            position: absolute;
            left: 0;
            top: 0;

            ::v-deep .media-upload-root {
                width: 100%;
                height: 100%;

                .media-upload-root .img-container .img {
                    margin: 0;
                }
            }
        }

        .basic-info {
            width: 100%;
            height: 100%;
            padding: 10px;

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;

                .title {
                    width: 70px;

                }
            }
        }
    }

    .bottom {
        padding: 10px 15px;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 0;

            .title {
                width: 70px;
            }
        }
    }
}
</style>