<template>
    <div class="shop_shelves_display_root">
        <table class="getch-table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>编号</th>
                    <th>分类名称</th>
                    <th>备注</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in data.shelves"
                    :key="index">
                    <td>{{index+1}}</td>
                    <td>{{item.id}}</td>
                    <td>
                        <InputBox v-model="item.name"></InputBox>
                    </td>
                    <td>
                        <InputBox v-model="item.remarks"></InputBox>
                    </td>
                    <td class="recode-tools">
                        <div class="recode-tools-contain">
                            <div class="button"
                                @click="save(item,index)">保存</div>
                            <div class="button"
                                @click="move(item.id,item.shop_id,'up',-1,item)">上移</div>
                            <div class="button"
                                @click="move(item.id,item.shop_id,'down',1,item)">下移</div>
                            <!-- <div class="button"
                                v-for="action in conf.tableSchema.row_actions"
                                v-show="action.condition?action.condition(recode):true"
                                @click="action.action(recode,index)">
                                {{ action.display }}
                            </div> -->
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="40">
                        <div class="button"
                            @click="addRow">新增一行</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../../../assets/js/apiMap';
const store = useStore();
const data = reactive({
    pageLoaded: false,
    dictionary: {

    },
    shelves: [],
    userInfo: {
    }
})

const addRow = () => {
    data.shelves.push({
        id: '',
        name: '新分类',
        remarks: '',
        code: '',
    });
}
const move = (id,
    shopId,
    action, op, recode) => {
    store.dispatch({
        type: 'request',
        url: '/api/shop/shelves/move',
        method: 'put',
        data: {
            id, shopId, action
        }
    }).then(res => {
        if (res == true) {
            top.window.sendMessage({
                type: 'success',
                content: '排序已更新'
            })
            loadList();
            // recode.order += op;
            // data.shelves.sort((a, b) => {
            //     return a.order < b.order ? 1 : -1;
            // });
        } else {
            top.window.sendMessage({
                type: 'success',
                content: res
            })
        }
    })
}
const save = (recode, index) => {
    let url = recode.id ? '/api/shop/shelves/update' : '/api/shop/shelves/create'
    let method = recode.id ? 'put' : 'post'
    store.dispatch({
        type: 'request',
        url,
        method,
        data: recode,
    }).then(res => {
        if (res) {
            data.shelves[index] = res;
            top.window.sendMessage({
                type: 'success',
                content: '保存成功'
            })
        }
    })
}
const loadList = async () => {
    data.shelves = []
    store.dispatch({ // 加载货架信息
        url: '/api/shop/shelves/list',
        type: 'request',
        method: 'get',
        params: { shop_id: 0 }
    }).then(res => {
        if (res) {
            data.shelves = res;
        } else {
        }
    });
}
onMounted(async () => {
    await top.window.handle.getDictionary('shop_status').then(
        res => {
            data.dictionary.shopStatus = res;
        }
    )
    await store.dispatch({
        type: 'getUserInfo'
    }).then(res => {
        data.userInfo = res.data;
    });
    await loadList();
    data.pageLoaded = true;
})
</script>

<style lang="less" scoped>
.shop_shelves_display_root {
    width: 100%;
}
</style>