/** @format */

export const apiMap = {
	MallShopReport: 'MallShopReport',
	CommunityIndexInsert: 'CommunityIndexInsert',
	CommunityIndexInsertMulti: 'CommunityIndexInsertMulti',
	CommunityIndexUpdate: 'CommunityIndexUpdate',
	CommunityIndexUpdateMulti: 'CommunityIndexUpdateMulti',
	CommunityIndexSelect: 'CommunityIndexSelect',
	CommunityIndexSelectBy: 'CommunityIndexSelectBy',
	CommunityIndexPageList: 'CommunityIndexPageList',
	CommunityIndexTrash: 'CommunityIndexTrash',
	CommunityIndexRecovery: 'CommunityIndexRecovery',
	CommunityIndexDelete: 'CommunityIndexDelete',
	logout: 'logout',
	smsLogin:'smsLogin',
	login: 'login',
	setPassword: 'setPassword',
	userProfile: 'userProfile',

	MallCartInsert: 'MallCartInsert',
	MallCartInsertMulti: 'MallCartInsertMulti',
	MallCartUpdate: 'MallCartUpdate',
	MallCartUpdateMulti: 'MallCartUpdateMulti',
	MallCartSelect: 'MallCartSelect',
	MallCartSelectBy: 'MallCartSelectBy',
	MallCartPageList: 'MallCartPageList',
	MallCartTrash: 'MallCartTrash',
	MallCartRecovery: 'MallCartRecovery',
	MallCartDelete: 'MallCartDelete',

	MallGoodsInsert: 'MallGoodsInsert',
	MallGoodsInsertMulti: 'MallGoodsInsertMulti',
	MallGoodsUpdate: 'MallGoodsUpdate',
	MallGoodsUpdateMulti: 'MallGoodsUpdateMulti',
	MallGoodsSelect: 'MallGoodsSelect',
	MallGoodsSelectBy: 'MallGoodsSelectBy',
	MallGoodsPageList: 'MallGoodsPageList',
	MallGoodsTrash: 'MallGoodsTrash',
	MallGoodsRecovery: 'MallGoodsRecovery',
	MallGoodsDelete: 'MallGoodsDelete',

	MallGoodsCategoryInsert: 'MallGoodsCategoryInsert',
	MallGoodsCategoryInsertMulti: 'MallGoodsCategoryInsertMulti',
	MallGoodsCategoryUpdate: 'MallGoodsCategoryUpdate',
	MallGoodsCategoryUpdateMulti: 'MallGoodsCategoryUpdateMulti',
	MallGoodsCategorySelect: 'MallGoodsCategorySelect',
	MallGoodsCategorySelectBy: 'MallGoodsCategorySelectBy',
	MallGoodsCategoryPageList: 'MallGoodsCategoryPageList',
	MallGoodsCategoryTrash: 'MallGoodsCategoryTrash',
	MallGoodsCategoryRecovery: 'MallGoodsCategoryRecovery',
	MallGoodsCategoryDelete: 'MallGoodsCategoryDelete',

	MallGoodsStoreInsert: 'MallGoodsStoreInsert',
	MallGoodsStoreInsertMulti: 'MallGoodsStoreInsertMulti',
	MallGoodsStoreUpdate: 'MallGoodsStoreUpdate',
	MallGoodsStoreUpdateMulti: 'MallGoodsStoreUpdateMulti',
	MallGoodsStoreSelect: 'MallGoodsStoreSelect',
	MallGoodsStoreSelectBy: 'MallGoodsStoreSelectBy',
	MallGoodsStorePageList: 'MallGoodsStorePageList',
	MallGoodsStoreTrash: 'MallGoodsStoreTrash',
	MallGoodsStoreRecovery: 'MallGoodsStoreRecovery',
	MallGoodsStoreDelete: 'MallGoodsStoreDelete',

	MallOrderInsert: 'MallOrderInsert',
	MallOrderInsertMulti: 'MallOrderInsertMulti',
	MallOrderUpdate: 'MallOrderUpdate',
	MallOrderUpdateMulti: 'MallOrderUpdateMulti',
	MallOrderSelect: 'MallOrderSelect',
	MallOrderSelectBy: 'MallOrderSelectBy',
	MallOrderPageList: 'MallOrderPageList',
	MallOrderTrash: 'MallOrderTrash',
	MallOrderRecovery: 'MallOrderRecovery',
	MallOrderDelete: 'MallOrderDelete',

	MallOrderAddressInsert: 'MallOrderAddressInsert',
	MallOrderAddressInsertMulti: 'MallOrderAddressInsertMulti',
	MallOrderAddressUpdate: 'MallOrderAddressUpdate',
	MallOrderAddressUpdateMulti: 'MallOrderAddressUpdateMulti',
	MallOrderAddressSelect: 'MallOrderAddressSelect',
	MallOrderAddressSelectBy: 'MallOrderAddressSelectBy',
	MallOrderAddressPageList: 'MallOrderAddressPageList',
	MallOrderAddressTrash: 'MallOrderAddressTrash',
	MallOrderAddressRecovery: 'MallOrderAddressRecovery',
	MallOrderAddressDelete: 'MallOrderAddressDelete',

	MallOrderCallbackInsert: 'MallOrderCallbackInsert',
	MallOrderCallbackInsertMulti: 'MallOrderCallbackInsertMulti',
	MallOrderCallbackUpdate: 'MallOrderCallbackUpdate',
	MallOrderCallbackUpdateMulti: 'MallOrderCallbackUpdateMulti',
	MallOrderCallbackSelect: 'MallOrderCallbackSelect',
	MallOrderCallbackSelectBy: 'MallOrderCallbackSelectBy',
	MallOrderCallbackPageList: 'MallOrderCallbackPageList',
	MallOrderCallbackTrash: 'MallOrderCallbackTrash',
	MallOrderCallbackRecovery: 'MallOrderCallbackRecovery',
	MallOrderCallbackDelete: 'MallOrderCallbackDelete',

	MallOrderCommentInsert: 'MallOrderCommentInsert',
	MallOrderCommentInsertMulti: 'MallOrderCommentInsertMulti',
	MallOrderCommentUpdate: 'MallOrderCommentUpdate',
	MallOrderCommentUpdateMulti: 'MallOrderCommentUpdateMulti',
	MallOrderCommentSelect: 'MallOrderCommentSelect',
	MallOrderCommentSelectBy: 'MallOrderCommentSelectBy',
	MallOrderCommentPageList: 'MallOrderCommentPageList',
	MallOrderCommentTrash: 'MallOrderCommentTrash',
	MallOrderCommentRecovery: 'MallOrderCommentRecovery',
	MallOrderCommentDelete: 'MallOrderCommentDelete',

	MallOrderExchangeInsert: 'MallOrderExchangeInsert',
	MallOrderExchangeInsertMulti: 'MallOrderExchangeInsertMulti',
	MallOrderExchangeUpdate: 'MallOrderExchangeUpdate',
	MallOrderExchangeUpdateMulti: 'MallOrderExchangeUpdateMulti',
	MallOrderExchangeSelect: 'MallOrderExchangeSelect',
	MallOrderExchangeSelectBy: 'MallOrderExchangeSelectBy',
	MallOrderExchangePageList: 'MallOrderExchangePageList',
	MallOrderExchangeTrash: 'MallOrderExchangeTrash',
	MallOrderExchangeRecovery: 'MallOrderExchangeRecovery',
	MallOrderExchangeDelete: 'MallOrderExchangeDelete',

	MallOrderLogisticsInsert: 'MallOrderLogisticsInsert',
	MallOrderLogisticsInsertMulti: 'MallOrderLogisticsInsertMulti',
	MallOrderLogisticsUpdate: 'MallOrderLogisticsUpdate',
	MallOrderLogisticsUpdateMulti: 'MallOrderLogisticsUpdateMulti',
	MallOrderLogisticsSelect: 'MallOrderLogisticsSelect',
	MallOrderLogisticsSelectBy: 'MallOrderLogisticsSelectBy',
	MallOrderLogisticsPageList: 'MallOrderLogisticsPageList',
	MallOrderLogisticsTrash: 'MallOrderLogisticsTrash',
	MallOrderLogisticsRecovery: 'MallOrderLogisticsRecovery',
	MallOrderLogisticsDelete: 'MallOrderLogisticsDelete',

	MallOrderPaymentInsert: 'MallOrderPaymentInsert',
	MallOrderPaymentInsertMulti: 'MallOrderPaymentInsertMulti',
	MallOrderPaymentUpdate: 'MallOrderPaymentUpdate',
	MallOrderPaymentUpdateMulti: 'MallOrderPaymentUpdateMulti',
	MallOrderPaymentSelect: 'MallOrderPaymentSelect',
	MallOrderPaymentSelectBy: 'MallOrderPaymentSelectBy',
	MallOrderPaymentPageList: 'MallOrderPaymentPageList',
	MallOrderPaymentTrash: 'MallOrderPaymentTrash',
	MallOrderPaymentRecovery: 'MallOrderPaymentRecovery',
	MallOrderPaymentDelete: 'MallOrderPaymentDelete',

	MallOrderRefundInsert: 'MallOrderRefundInsert',
	MallOrderRefundInsertMulti: 'MallOrderRefundInsertMulti',
	MallOrderRefundUpdate: 'MallOrderRefundUpdate',
	MallOrderRefundUpdateMulti: 'MallOrderRefundUpdateMulti',
	MallOrderRefundSelect: 'MallOrderRefundSelect',
	MallOrderRefundSelectBy: 'MallOrderRefundSelectBy',
	MallOrderRefundPageList: 'MallOrderRefundPageList',
	MallOrderRefundTrash: 'MallOrderRefundTrash',
	MallOrderRefundRecovery: 'MallOrderRefundRecovery',
	MallOrderRefundDelete: 'MallOrderRefundDelete',

	MallOrderServiceInsert: 'MallOrderServiceInsert',
	MallOrderServiceInsertMulti: 'MallOrderServiceInsertMulti',
	MallOrderServiceUpdate: 'MallOrderServiceUpdate',
	MallOrderServiceUpdateMulti: 'MallOrderServiceUpdateMulti',
	MallOrderServiceSelect: 'MallOrderServiceSelect',
	MallOrderServiceSelectBy: 'MallOrderServiceSelectBy',
	MallOrderServicePageList: 'MallOrderServicePageList',
	MallOrderServiceTrash: 'MallOrderServiceTrash',
	MallOrderServiceRecovery: 'MallOrderServiceRecovery',
	MallOrderServiceDelete: 'MallOrderServiceDelete',

	MallOrderSnapshotInsert: 'MallOrderSnapshotInsert',
	MallOrderSnapshotInsertMulti: 'MallOrderSnapshotInsertMulti',
	MallOrderSnapshotUpdate: 'MallOrderSnapshotUpdate',
	MallOrderSnapshotUpdateMulti: 'MallOrderSnapshotUpdateMulti',
	MallOrderSnapshotSelect: 'MallOrderSnapshotSelect',
	MallOrderSnapshotSelectBy: 'MallOrderSnapshotSelectBy',
	MallOrderSnapshotPageList: 'MallOrderSnapshotPageList',
	MallOrderSnapshotTrash: 'MallOrderSnapshotTrash',
	MallOrderSnapshotRecovery: 'MallOrderSnapshotRecovery',
	MallOrderSnapshotDelete: 'MallOrderSnapshotDelete',

	MallShopInsert: 'MallShopInsert',
	MallShopInsertMulti: 'MallShopInsertMulti',
	MallShopUpdate: 'MallShopUpdate',
	MallShopUpdateMulti: 'MallShopUpdateMulti',
	MallShopSelect: 'MallShopSelect',
	MallShopSelectBy: 'MallShopSelectBy',
	MallShopPageList: 'MallShopPageList',
	MallShopTrash: 'MallShopTrash',
	MallShopRecovery: 'MallShopRecovery',
	MallShopDelete: 'MallShopDelete',

	MallShopCategoryInsert: 'MallShopCategoryInsert',
	MallShopCategoryInsertMulti: 'MallShopCategoryInsertMulti',
	MallShopCategoryUpdate: 'MallShopCategoryUpdate',
	MallShopCategoryUpdateMulti: 'MallShopCategoryUpdateMulti',
	MallShopCategorySelect: 'MallShopCategorySelect',
	MallShopCategorySelectBy: 'MallShopCategorySelectBy',
	MallShopCategoryPageList: 'MallShopCategoryPageList',
	MallShopCategoryTrash: 'MallShopCategoryTrash',
	MallShopCategoryRecovery: 'MallShopCategoryRecovery',
	MallShopCategoryDelete: 'MallShopCategoryDelete',

	MallShopCouponInsert: 'MallShopCouponInsert',
	MallShopCouponInsertMulti: 'MallShopCouponInsertMulti',
	MallShopCouponUpdate: 'MallShopCouponUpdate',
	MallShopCouponUpdateMulti: 'MallShopCouponUpdateMulti',
	MallShopCouponSelect: 'MallShopCouponSelect',
	MallShopCouponSelectBy: 'MallShopCouponSelectBy',
	MallShopCouponPageList: 'MallShopCouponPageList',
	MallShopCouponTrash: 'MallShopCouponTrash',
	MallShopCouponRecovery: 'MallShopCouponRecovery',
	MallShopCouponDelete: 'MallShopCouponDelete',

	MallShopShelvesInsert: 'MallShopShelvesInsert',
	MallShopShelvesInsertMulti: 'MallShopShelvesInsertMulti',
	MallShopShelvesUpdate: 'MallShopShelvesUpdate',
	MallShopShelvesUpdateMulti: 'MallShopShelvesUpdateMulti',
	MallShopShelvesSelect: 'MallShopShelvesSelect',
	MallShopShelvesSelectBy: 'MallShopShelvesSelectBy',
	MallShopShelvesPageList: 'MallShopShelvesPageList',
	MallShopShelvesTrash: 'MallShopShelvesTrash',
	MallShopShelvesRecovery: 'MallShopShelvesRecovery',
	MallShopShelvesDelete: 'MallShopShelvesDelete',

	SysChinaDistrictInsert: 'SysChinaDistrictInsert',
	SysChinaDistrictInsertMulti: 'SysChinaDistrictInsertMulti',
	SysChinaDistrictUpdate: 'SysChinaDistrictUpdate',
	SysChinaDistrictUpdateMulti: 'SysChinaDistrictUpdateMulti',
	SysChinaDistrictSelect: 'SysChinaDistrictSelect',
	SysChinaDistrictSelectBy: 'SysChinaDistrictSelectBy',
	SysChinaDistrictPageList: 'SysChinaDistrictPageList',
	SysChinaDistrictTrash: 'SysChinaDistrictTrash',
	SysChinaDistrictRecovery: 'SysChinaDistrictRecovery',
	SysChinaDistrictDelete: 'SysChinaDistrictDelete',

	SysConfigInsert: 'SysConfigInsert',
	SysConfigInsertMulti: 'SysConfigInsertMulti',
	SysConfigUpdate: 'SysConfigUpdate',
	SysConfigUpdateMulti: 'SysConfigUpdateMulti',
	SysConfigSelect: 'SysConfigSelect',
	SysConfigSelectBy: 'SysConfigSelectBy',
	SysConfigPageList: 'SysConfigPageList',
	SysConfigTrash: 'SysConfigTrash',
	SysConfigRecovery: 'SysConfigRecovery',
	SysConfigDelete: 'SysConfigDelete',

	SysDictionaryInsert: 'SysDictionaryInsert',
	SysDictionaryInsertMulti: 'SysDictionaryInsertMulti',
	SysDictionaryUpdate: 'SysDictionaryUpdate',
	SysDictionaryUpdateMulti: 'SysDictionaryUpdateMulti',
	SysDictionarySelect: 'SysDictionarySelect',
	SysDictionarySelectBy: 'SysDictionarySelectBy',
	SysDictionaryPageList: 'SysDictionaryPageList',
	SysDictionaryTrash: 'SysDictionaryTrash',
	SysDictionaryRecovery: 'SysDictionaryRecovery',
	SysDictionaryDelete: 'SysDictionaryDelete',

	SysLogisticsComponyInsert: 'SysLogisticsComponyInsert',
	SysLogisticsComponyInsertMulti: 'SysLogisticsComponyInsertMulti',
	SysLogisticsComponyUpdate: 'SysLogisticsComponyUpdate',
	SysLogisticsComponyUpdateMulti: 'SysLogisticsComponyUpdateMulti',
	SysLogisticsComponySelect: 'SysLogisticsComponySelect',
	SysLogisticsComponySelectBy: 'SysLogisticsComponySelectBy',
	SysLogisticsComponyPageList: 'SysLogisticsComponyPageList',
	SysLogisticsComponyTrash: 'SysLogisticsComponyTrash',
	SysLogisticsComponyRecovery: 'SysLogisticsComponyRecovery',
	SysLogisticsComponyDelete: 'SysLogisticsComponyDelete',

	SysMenuInsert: 'SysMenuInsert',
	SysMenuInsertMulti: 'SysMenuInsertMulti',
	SysMenuUpdate: 'SysMenuUpdate',
	SysMenuUpdateMulti: 'SysMenuUpdateMulti',
	SysMenuSelect: 'SysMenuSelect',
	SysMenuSelectBy: 'SysMenuSelectBy',
	SysMenuPageList: 'SysMenuPageList',
	SysMenuTrash: 'SysMenuTrash',
	SysMenuRecovery: 'SysMenuRecovery',
	SysMenuDelete: 'SysMenuDelete',

	SysMenuRightInsert: 'SysMenuRightInsert',
	SysMenuRightInsertMulti: 'SysMenuRightInsertMulti',
	SysMenuRightUpdate: 'SysMenuRightUpdate',
	SysMenuRightUpdateMulti: 'SysMenuRightUpdateMulti',
	SysMenuRightSelect: 'SysMenuRightSelect',
	SysMenuRightSelectBy: 'SysMenuRightSelectBy',
	SysMenuRightPageList: 'SysMenuRightPageList',
	SysMenuRightTrash: 'SysMenuRightTrash',
	SysMenuRightRecovery: 'SysMenuRightRecovery',
	SysMenuRightDelete: 'SysMenuRightDelete',

	SysTableInsert: 'SysTableInsert',
	SysTableInsertMulti: 'SysTableInsertMulti',
	SysTableUpdate: 'SysTableUpdate',
	SysTableUpdateMulti: 'SysTableUpdateMulti',
	SysTableSelect: 'SysTableSelect',
	SysTableSelectBy: 'SysTableSelectBy',
	SysTablePageList: 'SysTablePageList',
	SysTableTrash: 'SysTableTrash',
	SysTableRecovery: 'SysTableRecovery',
	SysTableDelete: 'SysTableDelete',

	SysTableFieldInsert: 'SysTableFieldInsert',
	SysTableFieldInsertMulti: 'SysTableFieldInsertMulti',
	SysTableFieldUpdate: 'SysTableFieldUpdate',
	SysTableFieldUpdateMulti: 'SysTableFieldUpdateMulti',
	SysTableFieldSelect: 'SysTableFieldSelect',
	SysTableFieldSelectBy: 'SysTableFieldSelectBy',
	SysTableFieldPageList: 'SysTableFieldPageList',
	SysTableFieldTrash: 'SysTableFieldTrash',
	SysTableFieldRecovery: 'SysTableFieldRecovery',
	SysTableFieldDelete: 'SysTableFieldDelete',

	UserInsert: 'UserInsert',
	UserInsertMulti: 'UserInsertMulti',
	UserUpdate: 'UserUpdate',
	UserUpdateMulti: 'UserUpdateMulti',
	UserSelect: 'UserSelect',
	UserSelectBy: 'UserSelectBy',
	UserPageList: 'UserPageList',
	UserTrash: 'UserTrash',
	UserRecovery: 'UserRecovery',
	UserDelete: 'UserDelete',

	UserAddressInsert: 'UserAddressInsert',
	UserAddressInsertMulti: 'UserAddressInsertMulti',
	UserAddressUpdate: 'UserAddressUpdate',
	UserAddressUpdateMulti: 'UserAddressUpdateMulti',
	UserAddressSelect: 'UserAddressSelect',
	UserAddressSelectBy: 'UserAddressSelectBy',
	UserAddressPageList: 'UserAddressPageList',
	UserAddressTrash: 'UserAddressTrash',
	UserAddressRecovery: 'UserAddressRecovery',
	UserAddressDelete: 'UserAddressDelete',

	UserAgentInsert: 'UserAgentInsert',
	UserAgentInsertMulti: 'UserAgentInsertMulti',
	UserAgentUpdate: 'UserAgentUpdate',
	UserAgentUpdateMulti: 'UserAgentUpdateMulti',
	UserAgentSelect: 'UserAgentSelect',
	UserAgentSelectBy: 'UserAgentSelectBy',
	UserAgentPageList: 'UserAgentPageList',
	UserAgentTrash: 'UserAgentTrash',
	UserAgentRecovery: 'UserAgentRecovery',
	UserAgentDelete: 'UserAgentDelete',

	UserCouponInsert: 'UserCouponInsert',
	UserCouponInsertMulti: 'UserCouponInsertMulti',
	UserCouponUpdate: 'UserCouponUpdate',
	UserCouponUpdateMulti: 'UserCouponUpdateMulti',
	UserCouponSelect: 'UserCouponSelect',
	UserCouponSelectBy: 'UserCouponSelectBy',
	UserCouponPageList: 'UserCouponPageList',
	UserCouponTrash: 'UserCouponTrash',
	UserCouponRecovery: 'UserCouponRecovery',
	UserCouponDelete: 'UserCouponDelete',

	UserFinaceInsert: 'UserFinaceInsert',
	UserFinaceInsertMulti: 'UserFinaceInsertMulti',
	UserFinaceUpdate: 'UserFinaceUpdate',
	UserFinaceUpdateMulti: 'UserFinaceUpdateMulti',
	UserFinaceSelect: 'UserFinaceSelect',
	UserFinaceSelectBy: 'UserFinaceSelectBy',
	UserFinacePageList: 'UserFinacePageList',
	UserFinaceTrash: 'UserFinaceTrash',
	UserFinaceRecovery: 'UserFinaceRecovery',
	UserFinaceDelete: 'UserFinaceDelete',

	UserFinaceWaterInsert: 'UserFinaceWaterInsert',
	UserFinaceWaterInsertMulti: 'UserFinaceWaterInsertMulti',
	UserFinaceWaterUpdate: 'UserFinaceWaterUpdate',
	UserFinaceWaterUpdateMulti: 'UserFinaceWaterUpdateMulti',
	UserFinaceWaterSelect: 'UserFinaceWaterSelect',
	UserFinaceWaterSelectBy: 'UserFinaceWaterSelectBy',
	UserFinaceWaterPageList: 'UserFinaceWaterPageList',
	UserFinaceWaterTrash: 'UserFinaceWaterTrash',
	UserFinaceWaterRecovery: 'UserFinaceWaterRecovery',
	UserFinaceWaterDelete: 'UserFinaceWaterDelete',

	UserFinaceWithdrawInsert: 'UserFinaceWithdrawInsert',
	UserFinaceWithdrawInsertMulti: 'UserFinaceWithdrawInsertMulti',
	UserFinaceWithdrawUpdate: 'UserFinaceWithdrawUpdate',
	UserFinaceWithdrawUpdateMulti: 'UserFinaceWithdrawUpdateMulti',
	UserFinaceWithdrawSelect: 'UserFinaceWithdrawSelect',
	UserFinaceWithdrawSelectBy: 'UserFinaceWithdrawSelectBy',
	UserFinaceWithdrawPageList: 'UserFinaceWithdrawPageList',
	UserFinaceWithdrawTrash: 'UserFinaceWithdrawTrash',
	UserFinaceWithdrawRecovery: 'UserFinaceWithdrawRecovery',
	UserFinaceWithdrawDelete: 'UserFinaceWithdrawDelete',

	UserLogInsert: 'UserLogInsert',
	UserLogInsertMulti: 'UserLogInsertMulti',
	UserLogUpdate: 'UserLogUpdate',
	UserLogUpdateMulti: 'UserLogUpdateMulti',
	UserLogSelect: 'UserLogSelect',
	UserLogSelectBy: 'UserLogSelectBy',
	UserLogPageList: 'UserLogPageList',
	UserLogTrash: 'UserLogTrash',
	UserLogRecovery: 'UserLogRecovery',
	UserLogDelete: 'UserLogDelete',

	UserMessageInsert: 'UserMessageInsert',
	UserMessageInsertMulti: 'UserMessageInsertMulti',
	UserMessageUpdate: 'UserMessageUpdate',
	UserMessageUpdateMulti: 'UserMessageUpdateMulti',
	UserMessageSelect: 'UserMessageSelect',
	UserMessageSelectBy: 'UserMessageSelectBy',
	UserMessagePageList: 'UserMessagePageList',
	UserMessageTrash: 'UserMessageTrash',
	UserMessageRecovery: 'UserMessageRecovery',
	UserMessageDelete: 'UserMessageDelete',

	UserOauthInsert: 'UserOauthInsert',
	UserOauthInsertMulti: 'UserOauthInsertMulti',
	UserOauthUpdate: 'UserOauthUpdate',
	UserOauthUpdateMulti: 'UserOauthUpdateMulti',
	UserOauthSelect: 'UserOauthSelect',
	UserOauthSelectBy: 'UserOauthSelectBy',
	UserOauthPageList: 'UserOauthPageList',
	UserOauthTrash: 'UserOauthTrash',
	UserOauthRecovery: 'UserOauthRecovery',
	UserOauthDelete: 'UserOauthDelete',

	UserRoleInsert: 'UserRoleInsert',
	UserRoleInsertMulti: 'UserRoleInsertMulti',
	UserRoleUpdate: 'UserRoleUpdate',
	UserRoleUpdateMulti: 'UserRoleUpdateMulti',
	UserRoleSelect: 'UserRoleSelect',
	UserRoleSelectBy: 'UserRoleSelectBy',
	UserRolePageList: 'UserRolePageList',
	UserRoleTrash: 'UserRoleTrash',
	UserRoleRecovery: 'UserRoleRecovery',
	UserRoleDelete: 'UserRoleDelete',

	UserVipInsert: 'UserVipInsert',
	UserVipInsertMulti: 'UserVipInsertMulti',
	UserVipUpdate: 'UserVipUpdate',
	UserVipUpdateMulti: 'UserVipUpdateMulti',
	UserVipSelect: 'UserVipSelect',
	UserVipSelectBy: 'UserVipSelectBy',
	UserVipPageList: 'UserVipPageList',
	UserVipTrash: 'UserVipTrash',
	UserVipRecovery: 'UserVipRecovery',
	UserVipDelete: 'UserVipDelete',
	
	UserResourceCircleInsert: 'UserResourceCircleInsert',
	UserResourceCircleInsertMulti: 'UserResourceCircleInsertMulti',
	UserResourceCircleUpdate: 'UserResourceCircleUpdate',
	UserResourceCircleUpdateMulti: 'UserResourceCircleUpdateMulti',
	UserResourceCircleSelect: 'UserResourceCircleSelect',
	UserResourceCircleSelectBy: 'UserResourceCircleSelectBy',
	UserResourceCirclePageList: 'UserResourceCirclePageList',
	UserResourceCircleTrash: 'UserResourceCircleTrash',
	UserResourceCircleRecovery: 'UserResourceCircleRecovery',
	UserResourceCircleDelete: 'UserResourceCircleDelete',
};
