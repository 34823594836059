<template>
    <div class="personal-info-root">
        <div class="suspense-box"
            ref="suspenseBox">
            <div class="user-summary">
                <div class="summary-bg"></div>
                <div class="user-head">
                    <img :src="data.userInfo.avatar"
                        alt="用户头像.jpg" />
                </div>
                <div class="user-name">
                    <h3>
                        {{ data.userInfo.nickname }}
                        <!-- <span v-if="user.token"
                            class="iconfont icon-edit-icon"></span> -->
                    </h3>
                    <p>({{ data.userInfo.mobile }})</p>
                </div>
            </div>
            <div class="profile-item">
                <b>用户角色：</b>
                <span>{{ data.roleInfo[data.userInfo.role_id]?data.roleInfo[data.userInfo.role_id].name:'unknown'
                }}</span>
            </div>
            <div class="profile-item">
                <b>手机号：</b>
                <span>{{ data.userInfo.mobile}}</span>
            </div>
            <div class="profile-item">
                <b>累计消费：</b>
                <span>{{data.userInfo.cost_count }}</span>
            </div>
            <div class="profile-item">
                <b>账户余额：</b>
                <span>{{data.finace.balance }}</span>
            </div>
            <!-- <div class="bottom-buttons">
                <div class="button replace-pwd normal-fill"
                    @click="jumpToFindPwd">更新用户信息</div>
                <div class="button logout danger-fill"
                    @click="logout">安全退出</div>
            </div> -->
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../../../assets/js/apiMap';
const store = useStore();
const data = reactive({
    userInfo: {

    },
    roleInfo: {},
    finace: {

    }
});
onMounted(async () => {
    await store.dispatch({
        type: 'getUserInfo'
    }).then(res => {
        data.userInfo = res.data;
    })
    store.dispatch({
        type: apiMap.UserRolePageList,
        params: {
            page: 1, pageSize: 100
        }
    }).then(res => {
        if (res && res.list) {
            for (const key in res.list) {
                if (Object.hasOwnProperty.call(res.list, key)) {
                    const element = res.list[key];
                    data.roleInfo[element.id] = element;
                }
            }
        }
    })
    store.dispatch({
        type: apiMap.UserFinaceSelectBy,
        data: {
            key: 'user_id',
            value: data.userInfo.id
        }
    }).then(res => {
        if (res) {
            data.finace = res;
        }
    });
});
</script>

<style lang="less" scoped>
.personal-info-root {
    width: 100%;
    height: 100%;

    .suspense-box {
        border: 1px solid var(--color-light-2);
        color: var(--color-light-2);
        overflow: hidden;
        transition: all ease 0.3s;
        width: 100%;
        right: 10px;
        cursor: auto;
        background-color: white;

        .user-summary {
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--color-light-2);

            .summary-bg {
                height: 60px;
                background-color: var(--color-theme-light-1);
            }

            .user-head {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin: -40px auto 0;
                background-color: var(--color-theme-light-1);
                border: 3px solid white;
                display: flex;
                align-items: center;
                justify-content: center;

                &>img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }

                &>span {
                    font-size: 30px;
                    color: white;
                }
            }

            .user-name {
                text-align: center;
                line-height: 1.5;
                position: relative;

                .icon-edit-icon {
                    cursor: pointer;
                    font-weight: normal;
                }
            }
        }

        .profile-item {
            font-size: 14px;
            line-height: 45px;
            padding: 0 10px;

            b {
                width: 100px;
                text-align: justify;
                text-align-last: justify;
                display: inline-block;
            }
        }
    }
}
</style>