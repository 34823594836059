/** @format */

import { useStore } from 'vuex';
// import axios from 'axios';
import SparkMD5 from 'spark-md5';

let tools = {
	openResource(resource) {
		top.window.handle.openResource(resource);
	},
	moneyFormat(val) {
		return parseFloat(val).toFixed(2);
	},
	menuTrigger(name, code, href, isStatic = false) {
		top.window.handle.menuTrigger({ name, code, href, static: isStatic });
	},
	ucWords(str) {
		str = str.toLowerCase().replaceAll('_', ' ');
		var strarr = str.split(' ');
		var result = '';
		for (var i in strarr) {
			result +=
				strarr[i].substring(0, 1).toUpperCase() +
				strarr[i].substring(1) +
				' ';
		}
		return result;
	},
	dateFormat(format = '', value = null) {
		format = format ? format : 'Y-m-d H:i:s';
		value = this.timestrampFixed(value);
		let date = new Date(value);
		let y = date.getFullYear();
		let MM = date.getMonth() + 1;
		MM = MM < 10 ? '0' + MM : MM;
		let d = date.getDate();
		d = d < 10 ? '0' + d : d;
		let h = date.getHours();
		h = h < 10 ? '0' + h : h;
		let m = date.getMinutes();
		m = m < 10 ? '0' + m : m;
		let s = date.getSeconds();
		s = s < 10 ? '0' + s : s;
		let dateString = format
			.replace('Y', y)
			.replace('m', MM)
			.replace('d', d)
			.replace('H', h)
			.replace('i', m)
			.replace('s', s);
		return dateString;
	},
	timestrampFixed(value) {
		value =
			isNaN(value) || !value
				? new Date().getTime()
				: `${value}`.length <= 10
				? Number(value) * 1000
				: value;
		return value;
	},
	fileUpload(
		file,
		url,
		table,
		field,
		finishChunck = null,
		fail = null,
		finish = null,
		eachSize = 1024 * 1024 * 1,
		chunk = 0
	) {
		var totalSize = file.size,
			fileName = file.name,
			chunks = Math.floor(file.size / eachSize),
			blobFrom = chunk * eachSize, // 分段开始
			blobTo =
				(chunk + 1) * eachSize > totalSize
					? totalSize
					: (chunk + 1) * eachSize, // 分段结尾
			// percent = (100 * blobTo / totalSize).toFixed(1), // 已上传的百分比
			timeout = 5000, // 超时时间
			fd = new FormData();
		fd.append('theFile', file.slice(blobFrom, blobTo)); // 分好段的文件
		fd.append('fileName', fileName); // 文件名
		fd.append('totalSize', totalSize); // 文件总大小
		fd.append('chunks', chunks); // 文件总大小
		fd.append('chunk', chunk); // 文件总大小
		fd.append('table', table); // 文件总大小
		fd.append('field', field); // 文件总大小
		$.ajax({
			type: 'post',
			url: url,
			data: fd,
			processData: false,
			contentType: false,
			timeout: timeout,
			success: (rs) => {
				// 上传成功
				if (rs.code == 1) {
					if (typeof finishChunck == 'function') {
						finishChunck({ chunk: chunk + 1, chunks: chunks + 1 }); // 用于分析进度
					}
					if (chunk === chunks) {
						if (typeof finish == 'function') {
							finish(rs);
						}
					} else {
						chunk++;
						fileUpload(
							file,
							url,
							table,
							field,
							finishChunck,
							fail,
							finish,
							eachSize,
							chunk
						);
					}
				} else {
					if (typeof fail == 'function') {
						fail(rs);
					}
				}
			},
			error: () => {
				if (typeof fail == 'function') {
					fail();
				}
			},
		});
	},
	uploadFile(file) {
		// element-ui 将文件信息 { name, size, type, uid, webkitRelativePath }保存在 file.raw 字段里
		const isJPG = /(png|jpeg|jpg|svg|gif|timg)/g.test(file.raw.type);
		if (!isJPG) {
			this.$message.error('上传头像图片格式错误!');
			return;
		}
		var fileReader = new FileReader();
		var spark = new SparkMD5();
		var blobSlice =
			File.prototype.mozSlice ||
			File.prototype.webkitSlice ||
			File.prototype.slice;
		const chunkSize = 1024 * 1024; // 文件分块 每一块2KB
		// Math.ceil 向上取整 如果有小数 整数加一 该文件一共多少块
		const chunks = Math.ceil(file.size / chunkSize);
		var currentChunk = 0; // 当前块数
		// 先去读取 才会触发 onload 将文件转成Buffer
		fileReader.readAsArrayBuffer(file.raw);
		fileReader.onload = (e) => {
			spark.append(e.target.result);
			currentChunk++;
			// 分块
			if (currentChunk < chunks) {
				var start = currentChunk * chunkSize;
				end =
					start + chunkSize >= file.size
						? file.size
						: start + chunkSize;
				fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
			} else {
				console.info(`${file.name} hash --->`, spark.end());
			}
			return false;
		};
	},
};
function parseResponse(res, action = null) {
	store = useStore();
	res = res.data;
	if (res.code == 1) {
		if (typeof action == 'function') {
			action(res.data.data);
		}
		// entityList.value = res.data;
		store.dispatch('displayMessage', {
			text: res.msg,
			timeCount: 3000,
		});
	} else if (res.msg) {
		store.dispatch('displayMessage', {
			text: res.msg,
			timeCount: 3000,
		});
	} else {
		store.dispatch('displayMessage', {
			text: '系统错误：无法解析响应数据',
			timeCount: 3000,
		});
	}
	console.log(store);
}
export { tools, parseResponse };
