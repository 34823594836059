/** @format */

const { default: axios } = require('axios');

export default {
	async request({ commit, state, dispatch }, payload) {
		// url, method, data
		let token = '';
		await dispatch({
			type: 'getToken',
		}).then((res) => {
			token = res;
		});
		let params = {};
		if (payload.params) {
			for (const key in payload.params) {
				if (Object.hasOwnProperty.call(payload.params, key)) {
					let value = payload.params[key];
					if (typeof value == 'object' || typeof value == 'array') {
						value = window.btoa(encodeURI(JSON.stringify(value)));
					}
					params[key] = value;
				}
			}
		}
		const config = {
			baseURL: `${payload.url}`,
			timeout: payload.timeout ?? 10000,
			data: payload.data,
			params: params,
			method: payload.method ?? 'get',
			headers: {
				Authorization: `bearer ${token}`,
				...(payload.headers ?? {}),
			},
		};
		const instance = axios.create({});
		return instance(config)
			.then((res) => {
				if (res.data.code == 1) {
					return res.data.data;
				} else throw res.data.msg;
			})
			.catch((e, d) => {
				if (!e) {
					top.window.sendMessage({
						type: 'error',
						content: '服务器响应为空',
					});
					return;
				}
				if (!e.response) {
					top.window.sendMessage({
						type: 'error',
						content: e,
					});
					return;
				}
				if (e.response.data.code == 9000000) {
					top.handle.openLogin();
					return;
				}
				top.window.sendMessage({
					type: 'error',
					content: e.response.data.msg,
				});
			});
	},
	upload({ dispatch }, payload) {
		const formData = new FormData();
		formData.append('content', payload.file);
		return axios
			.post('/api/sys/file/upload', formData)
			.then((res) => {
				if (res.data.code == 1) {
					return res.data.data;
				}
				throw res.data.msg;
			})
			.catch((e, d) => {
				if (e.response.data.code == 9000000) {
					window.handle.openLogin();
				}
				top.window.sendMessage(e.response.data.msg);
			});
	},
};
