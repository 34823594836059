<template>
    <div class="table-edit-root">
        <div class="buttons-contain">
            <div v-show="conf.data.status == 2"
                class="button"
                @click="sendGoods()">发货</div>
            <div v-show="
                conf.data.service &&
                conf.data.service.type == 0 &&
                (conf.data.service.status == 0 || conf.data.service.status == 1)
            "
                class="button"
                @click="sendGoods()">
                补发货
            </div>
            <div v-show="
                conf.data.service &&
                conf.data.service.type == 0 &&
                (conf.data.service.status == 0 || conf.data.service.status == 1)
            "
                class="button"
                @click="serviceHandle(2)">
                标记为已处理
            </div>
            <div v-show="
                conf.data.service &&
                conf.data.service.type == 0 &&
                (conf.data.service.status == 0 || conf.data.service.status == 1)
            "
                class="button"
                @click="serviceHandle(4)">
                拒绝售后
            </div>
            <div class="button"
                v-show="id != 0"
                @click="getData()">刷新</div>
            <!-- <div class="button"
                @click="deleteRecode()">删除</div> -->
        </div>
        <div class="order-info">
            <template v-if="conf.data.service">
                <div class="row row-title">售后请求</div>
                <div class="row">
                    <div class="col">售后类型</div>
                    <div class="col">{{ conf.serviceType.mapper[conf.data.service.type] }}</div>
                </div>
                <div class="row">
                    <div class="col">售后状态</div>
                    <div class="col">{{ conf.serviceStatus.mapper[conf.data.service.status] }}</div>
                </div>
                <div class="row">
                    <div class="col">备注</div>
                    <div class="col">{{ conf.data.service.remarks }}</div>
                </div>
                <div class="row">
                    <div class="col">图集</div>
                    <div class="col">
                        <MediaUpload v-model="conf.data.service.images"
                            :readonly="true"></MediaUpload>
                    </div>
                </div>
                <div class="row"
                    v-if="conf.data.service.type == 1 || conf.data.service.type == 2">
                    <!-- 退款信息 -->
                    <RefundInfo :refund="conf.data.refund"
                        :refundGoods="conf.data.callback"></RefundInfo>
                </div>
                <div class="row"
                    v-if="conf.data.service.type == 0">
                    <!-- 退款信息 -->
                    <ExchangeGoods :exchange="conf.data.exchange"
                        :snapshot="conf.data.snapshot"></ExchangeGoods>
                </div>
                <!-- <div class="row">
                    <div class="button-group">
                        <div class="button">同意退款</div>
                        <div class="button">拒绝退款</div>
                        <div class="button">标记为已处理</div>
                        <div class="button">拒绝售后</div>
                    </div>
                </div> -->
            </template>
            <div class="row row-title">订单信息</div>
            <div class="row">
                <div class="col">订单编号</div>
                <div class="col">{{ conf.data.id }}</div>
            </div>
            <!-- <div class="row">
                <div class="col">客户手机号</div>
                <div class="col">
                    <ForeignKey v-model="conf.data.user_id"
                        :readonly="true"
                        :config="field.config"></ForeignKey>
                </div>
            </div> -->
            <div class="row">
                <div class="col">下单店铺</div>
                <div class="col">
                    <img :src="conf.data.shop_logo"
                        alt="" />
                    {{ conf.data.shop_name }}
                </div>
            </div>
            <div class="row">
                <div class="col">订单金额</div>
                <div class="col"
                    style="white-space: nowrap">
                    总金额：
                    <InputBox class="money"
                        v-model="conf.data.amount"
                        :prefix="'总金额'"
                        :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                        :type="'money'"
                        :readonly="true"></InputBox>，实付款：
                    <InputBox class="money"
                        v-model="conf.data.payment"
                        :prefix="'实付款'"
                        :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                        :type="'money'"
                        :readonly="true"></InputBox>
                </div>
            </div>
            <div class="row">
                <div class="col">折扣详情</div>
                <div class="col">
                    <OrderDiscount v-model="conf.data.discount"></OrderDiscount>
                </div>
            </div>
            <div class="row">
                <div class="col">订单状态</div>
                <div class="col"
                    v-if="conf.orderStatus.mapper">
                    {{ conf.orderStatus.mapper[conf.data.status] }}
                </div>
            </div>
            <!-- <div class="row">
                <div class="col">结算记录</div>
                <div class="col">{{ conf.data.settlement_id }}</div>
            </div> -->
            <div class="row">
                <div class="col">备注</div>
                <div class="col">{{ conf.data.remarks }}</div>
            </div>
            <div class="row">
                <div class="col">下单时间</div>
                <div class="col">
                    <DateFormat v-model="conf.data.created_at"
                        :readonly="true"></DateFormat>
                </div>
            </div>
            <template v-if="conf.data.address">
                <div class="row row-title">收货地址</div>
                <div class="row">
                    <div class="col">姓名</div>
                    <div class="col">{{ conf.data.address.name }}</div>
                </div>
                <div class="row">
                    <div class="col">电话</div>
                    <div class="col">{{ conf.data.address.mobile }}</div>
                </div>
                <div class="row">
                    <div class="col">地址</div>
                    <div class="col">{{ conf.data.address.address }}</div>
                </div>
                <div class="row">
                    <div class="col">详细地址</div>
                    <div class="col">{{ conf.data.address.address_detail }}</div>
                </div>
            </template>
            <div class="row row-title">商品信息</div>
            <div class="row">
                <table class="getch-table">
                    <tr>
                        <th>#</th>
                        <th>封面</th>
                        <th>商品</th>
                        <th>规格</th>
                        <th>单价</th>
                        <th>数量</th>
                        <th>运费</th>
                    </tr>
                    <tr v-for="(goods, index) in conf.data.snapshot">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <MediaUpload v-model="goods.cover"
                                :readonly="true"></MediaUpload>
                        </td>
                        <td>{{ goods.name }}</td>
                        <td>{{ goods.store_name }}</td>
                        <td>
                            <InputBox class="money"
                                v-model="goods.price"
                                :prefix="'总金额'"
                                :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                                :type="'money'"
                                :readonly="true"></InputBox>
                        </td>
                        <td>{{ goods.count }}</td>
                        <td>
                            <InputBox class="money"
                                v-model="goods.freight_basic"
                                :prefix="'总金额'"
                                :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                                :type="'money'"
                                :readonly="true"></InputBox>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="row row-title">物流信息</div>
            <div class="row"
                v-if="conf.data.logistics">
                <table class="getch-table">
                    <tr>
                        <th>#</th>
                        <th>配送方式</th>
                        <th>快递公司</th>
                        <th>运单号</th>
                        <th>发货时间</th>
                    </tr>
                    <tr v-for="(logistics, index) in conf.data.logistics">
                        <td>{{ index + 1 }}</td>
                        <td>
                            {{ conf.logisticsMethod[logistics.method] }}
                        </td>
                        <td>{{ logistics.com }}</td>
                        <td>{{ logistics.logistics_number }}</td>
                        <td>
                            <DateFormat v-model="logistics.created_at"
                                :readonly="true"></DateFormat>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <Window :config="conf.winSend"
            @closeWin="
    conf.winSend.display = false;
conf.winSend.url = '';
            "></Window>
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiMap } from "../../assets/js/apiMap";
import Window from "../../components/lib/tools/Window.vue";
const store = useStore();
const route = useRoute();
const tableName = "mall_order";
const id = route.params.id;
const conf = reactive({
    logisticsMethod: { 0: "自配", 1: "快递" },
    serviceType: {},
    serviceStatus: {},
    orderStatus: {},
    tableSchema: {},
    fieldSchema: [],
    winSend: {
        title: "发货",
        url: "",
        type: 0,
        width: 600,
        height: 400,
        display: false,
        isFull: false,
    },
    pageInfo: {},
    queryCondtion: {
        keywords: "",
    },
    orderMethod: [],
    data: {},
});
const getData = async () => {
    store
        .dispatch({
            type: "request",
            url: `/admin/${tableName.split("_").join("/")}/select/${id}`,
            method: "get",
        })
        .then((res) => {
            if (!res) {
                top.window.handle.addMessage({
                    type: "error",
                    content: "订单不存在",
                });
                return;
            }
            conf.data = res;
        });
};
const saveRecode = () => {
    let url = `/admin/${tableName.split("_").join("/")}/${id != 0 ? `update/${id}` : "insert"
        }`;
    let data = {};
    if (id == 0) {
        data = conf.data;
    } else {
        data = {
            data: conf.data,
        };
    }
    store
        .dispatch({
            type: "request",
            url: url,
            method: id != 0 ? "put" : "post",
            data,
        })
        .then((res) => {
            if (id == 0) {
                location.reload();
            }
            for (const key in res) {
                if (Object.hasOwnProperty.call(res, key)) {
                    const element = res[key];
                    conf.data[key] = element;
                }
            }
            top.window.sendMessage("保存成功");
        });
};
const serviceHandle = (e) => {
    if (e == 2 && !top.window.confirm("请确保您已经完成商品补发货,是否继续标记为已处理?")) {
        return;
    }
    store
        .dispatch({
            type: "request",
            url: "/api/order/after_sold/handle",
            method: "post",
            data: {
                orderId: id,
                status: e,
            },
        })
        .then((res) => {
            if (res) {
                window.location.reload();
            }
        });
};
const sendGoods = () => {
    conf.winSend.display = true;
    conf.winSend.url = "/order/send/" + id;
};
// const deleteRecode=()=>{
//     store.dispatch({
//         type: 'request',
//         url: `/admin/${tableName.split('_').join('/')}/delete/${id}`,
//         method: 'delete',
//     }
//     ).then(res => {

//     });
// }
onMounted(async () => {
    await store
        .dispatch({
            type: apiMap.SysTableSelectBy,
            data: {
                key: "value",
                value: tableName,
            },
        })
        .then((res) => {
            conf.tableSchema = res;
        });
    await store
        .dispatch({
            type: apiMap.SysTableFieldPageList,
            params: {
                page: 1,
                pageSize: 100,
                condition: { table_value: tableName },
                order: { position: "asc" },
            },
        })
        .then((res) => {
            conf.fieldSchema = res.list;
            for (let i = 0, l = conf.fieldSchema.length; i < l; i++) {
                let field = conf.fieldSchema[i];
                if (field.type == "json") {
                    conf.data[field.value] = Object(JSON.parse(field.default));
                    continue;
                }
                conf.data[field.value] = field.default;
                if (field.component == "ForeignKey") {
                    conf.data[`${field.value}Foreign`] = {};
                    conf.data[`${field.value}Foreign`][field.config.foreignField] = "";
                    conf.data[`${field.value}Foreign`][field.config.foreignDisplayField] = "";
                }
            }
        });
    await top.window.handle.getDictionary("order_service_type").then((res) => {
        conf.serviceType = res ? res : {};
    });
    await top.window.handle.getDictionary("order_service_status").then((res) => {
        conf.serviceStatus = res ? res : {};
    });
    await top.window.handle.getDictionary("order_status").then((res) => {
        conf.orderStatus = res ? res : {};
    });
    if (id != 0) {
        await getData();
    }
});
</script>
<style lang="less" scoped>
.table-edit-root {
    width: 100%;
    padding: 10px 30px;
    height: 100%;

    .row {
        border-bottom: 1px solid var(--color-light-9);
        display: flex;
        align-items: center;

        &.row-title {
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            color: var(--color-theme-light-9);
            background-color: var(--color-theme-light-4);
        }

        .col {
            border: none;
            text-align: left;
            min-height: 30px;
            padding: 10px;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
            }

            display: flex;
            align-items: center;

            &:nth-child(1) {
                padding: 0 10px;
                text-align: right;
                width: 140px;
                height: unset;
                align-items: center;
                justify-content: flex-end;
                font-weight: bold;
            }
        }

        .button-group {
            display: flex;
            padding: 10px 0;
        }
    }
}
</style>
