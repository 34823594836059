export default {
            UserOauthInsert: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/user/oauth/insert',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthInsertMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/user/oauth/insertMulti',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthUpdate: ({ dispatch, commit, state }, payload) => {
                let id=payload.data.id;delete payload.data.id;
                return dispatch({
                    type: 'request',
                    url: `/admin/user/oauth/update/${id}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthUpdateMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/user/oauth/updateMulti',
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthSelect: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/user/oauth/select/${payload.data.id}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthSelectBy: ({ dispatch, commit, state }, payload) => {
                
                
                return dispatch({
                    type: 'request',
                    url: `/admin/user/oauth/selectBy/${payload.data.key}/${payload.data.value}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthPageList: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/user/oauth/pageList',
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthTrash: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/user/oauth/trash/${payload.data.ids}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthRecovery: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/user/oauth/recovery/${payload.data.ids}`,
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            UserOauthDelete: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/user/oauth/delete/${payload.data.ids}`,
                    method: 'delete',
                    data: payload.data,
                    params: payload.params,
                });
            }
        };