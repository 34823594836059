<template>
    <div class="table-edit-root">
        <div class="buttons-contain">
            <div class="button"
                @click="saveRecode()">保存</div>
        </div>
        <template v-if="conf.fieldSchema.order_id">
            <div class="row">
                <div class="col data-name">{{conf.fieldSchema.order_id.display}}</div>
                <div class="col table-cell-container text-etc">
                    <InputBox :placeholder="conf.fieldSchema.order_id.display"
                        :preg="conf.fieldSchema.order_id.preg"
                        :len="conf.fieldSchema.order_id.len"
                        :readonly="conf.fieldSchema.order_id.config.readonly"
                        v-model="conf.data.order_id"
                        style="justify-content: flex-start;"></InputBox>
                </div>
            </div>
            <div class="row">
                <div class="col data-name">{{conf.fieldSchema.method.display}}</div>
                <div class="col table-cell-container text-etc">
                    <RadioBox v-model="conf.data.method"
                        :readonly="conf.fieldSchema.method.config.readonly"
                        :config="conf.fieldSchema.method.config"></RadioBox>
                </div>
            </div>
            <div class="row"
                v-show="conf.data.method==1">
                <div class="col data-name">{{conf.fieldSchema.com.display}}</div>
                <div class="col table-cell-container text-etc">
                    <ForeignKey :config="conf.fieldSchema.com.config"
                        :border="true"
                        :radius="true"
                        :placeholder="conf.fieldSchema.com.display"
                        :preg="conf.fieldSchema.com.preg"
                        :len="conf.fieldSchema.com.len"
                        :readonly="conf.fieldSchema.com.config.readonly"
                        :foreignValue="conf.data['comForeign']"
                        v-model="conf.data.com"></ForeignKey>
                </div>
            </div>
            <div class="row"
                v-show="conf.data.method==1">
                <div class="col data-name">{{conf.fieldSchema.logistics_number.display}}</div>
                <div class="col table-cell-container text-etc">
                    <InputBox :config="conf.fieldSchema.logistics_number.config"
                        :border="true"
                        :radius="true"
                        :placeholder="conf.fieldSchema.logistics_number.display"
                        :preg="conf.fieldSchema.logistics_number.preg"
                        :len="conf.fieldSchema.logistics_number.len"
                        :readonly="conf.fieldSchema.logistics_number.config.readonly"
                        :foreignValue="conf.data['logistics_numberForeign']"
                        v-model="conf.data.logistics_number"></InputBox>
                </div>
            </div>
            <div class="row">
                <div class="col data-name">{{conf.fieldSchema.remarks.display}}</div>
                <div class="col table-cell-container text-etc">
                    <InputBox :config="conf.fieldSchema.remarks.config"
                        :border="true"
                        :radius="true"
                        :placeholder="conf.fieldSchema.remarks.display"
                        :preg="conf.fieldSchema.remarks.preg"
                        :len="conf.fieldSchema.remarks.len"
                        :readonly="conf.fieldSchema.remarks.config.readonly"
                        :foreignValue="conf.data['remarksForeign']"
                        v-model="conf.data.remarks"></InputBox>
                </div>
            </div>
        </template>
        <!-- <template v-for="(field,index) in conf.fieldSchema"
            :key="index">
            {{f(field)}}
        </template> -->
        <!-- <ForeighKey></ForeighKey> -->
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiMap } from "../../assets/js/apiMap";
const f = field => {
    return `<div class="row">
            <div class="col data-name">{{conf.fieldSchema.${field.value}.display}}</div>
                <div class="col table-cell-container text-etc">
                    <${field.component} :config="conf.fieldSchema.${field.value}.config"
                        :border="true"
                        :radius="true"
                        :placeholder="conf.fieldSchema.${field.value}.display"
                        :preg="conf.fieldSchema.${field.value}.preg"
                        :len="conf.fieldSchema.${field.value}.len"
                        :readonly="conf.fieldSchema.${field.value}.config.readonly"
                        :foreignValue="conf.data['${field.value}Foreign']"
                        v-model="conf.data.${field.value}"></${field.component}>
                </div>
            </div>`;
}
const store = useStore()
const route = useRoute();
const tableName = 'mall_order_logistics';
const orderNumber = route.params.order_id;
const id = 0;
const conf = reactive({
    tableSchema: {},
    fieldSchema: {},
    pageInfo: {

    },
    queryCondtion: {
        keywords: '',
    },
    orderMethod: [],
    data: {},
});
const getData = async () => {
    store.dispatch({
        type: 'request',
        url: `/admin/${tableName.split('_').join('/')}/select/${id}`,
        method: 'get',
    }
    ).then(res => {
        conf.data = res;
    });
}
const saveRecode = () => {
    let url = `/admin/${tableName.split('_').join('/')}/${(id != 0 ? `update/${id}` : 'insert')}`
    let data = {};
    if (id == 0) {
        data = conf.data;
    } else {
        data = {
            data: conf.data
        }
    }
    store.dispatch({
        type: 'request',
        url: '/api/order/logistics/submit',
        method: 'post',
        data: {
            order_id: conf.data.order_id,
            logistics_method: conf.data.method,
            logistics_number: conf.data.logistics_number,
            com: conf.data.com,

        }
    }
    ).then(res => {
        if (id == 0) {
            parent.location.reload();
        }
        top.window.sendMessage('保存成功');
    });
}
// const deleteRecode=()=>{
//     store.dispatch({
//         type: 'request',
//         url: `/admin/${tableName.split('_').join('/')}/delete/${id}`,
//         method: 'delete',
//     }
//     ).then(res => {

//     });
// }
onMounted(async () => {
    await store.dispatch({
        type: apiMap.SysTableSelectBy,
        data: {
            key: 'value', value: tableName
        }
    }).then(res => {
        conf.tableSchema = res;
    });
    await store.dispatch({
        type: apiMap.SysTableFieldPageList,
        params: {
            page: 1, pageSize: 100, condition: {
                table_value: tableName,
                'value.in': [
                    // 'id',
                    'order_id',
                    'com',
                    'logistics_number',
                    // 'freight',
                    'method',
                    'remarks',

                ],
            }, order: { position: 'asc' }
        }
    }).then(res => {
        // conf.fieldSchema = res.list
        for (let i = 0, l = res.list.length; i < l; i++) {
            let field = res.list[i];
            conf.fieldSchema[field.value] = field;

            if (field.type == 'json') {
                conf.data[field.value] = Object(JSON.parse(field.default));
                continue;
            }
            conf.data[field.value] = field.default;
            if (field.component == 'ForeignKey') {
                conf.data[`${field.value}Foreign`] = {}
                conf.data[`${field.value}Foreign`][field.config.foreignField] = '';
                conf.data[`${field.value}Foreign`][field.config.foreignDisplayField] = '';
            }
        }
        conf.data.order_id = orderNumber;
    });
    if (id != 0) {
        await getData();
    }
})
</script>
<style lang="less" scoped>
.table-edit-root {
    width: 100%;
    padding: 10px 30px;


    // border: 1px solid;
    .row {
        border-bottom: 1px solid var(--color-light-9);
        display: flex;
        align-items: center;

        .col {
            border: none;

            &.table-cell-container {
                text-align: left;
                padding: 10px 15px 10px 10px;
                width: 500px;
                overflow: unset;

                .input-box-root {
                    margin: 20px 0;
                }
            }

            &.data-name {
                text-align: right;
                font-weight: bold;
                font-size: 14px;
                width: 150px;
                padding: 0px 20px 0 10px;
            }
        }
    }

}
</style>