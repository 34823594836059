/** @format */

export default {
	increment(context) {
		context.commit('increment', 1);
	},
	incrementNew({ commit }) {
		commit('increment', 1);
		return 10000;
	},
	incrementAsync({ commit }, payload) {
		setTimeout(() => {
			commit('increment', payload.amount);
		}, 1000);
	},
	actionsTest: () => {
		return 1;
	},
};
