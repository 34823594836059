<template>
    <div class="exchange-goods-root">
        <div class="row row-title">
            换货商品
        </div>
        <div class="row">
            <div class="col flex-1">商品图标</div>
            <div class="col flex-2">商品名称</div>
            <div class="col flex-2">商品规格</div>
            <div class="col flex-1">商品单价</div>
            <div class="col flex-1">购买数量</div>
        </div>
        <template v-for="(goods,index) in snapshot">
            <div class="row"
                v-if="exchangeMap[goods.id]">
                <div class="col flex-1">
                    <MediaUpload v-model="goods.cover"
                        :readonly="true"></MediaUpload>
                </div>
                <div class="col flex-2">{{goods.name}}</div>
                <div class="col flex-2">{{goods.store_name}}</div>
                <div class="col flex-1">
                    <InputBox class="money"
                        v-model="goods.price"
                        :prefix="'总金额'"
                        :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                        :type="'money'"
                        :readonly="true"></InputBox>
                </div>
                <div class="col  flex-1">{{goods.count}}</div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps([
    'exchange', 'snapshot'
])
const exchangeMap = computed(() => {
    let ret = {};
    for (const key in props.exchange) {
        if (Object.hasOwnProperty.call(props.exchange, key)) {
            const element = props.exchange[key];
            ret[element.goods_id] = element;
        }
    }
    return ret;
})
// 换货商品
</script>
<style lang="less" scoped>
.exchange-goods-root {
    width: 100%;

    .row {
        min-height: 30px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid var(--color-light-9);

        &.row-title {
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            color: var(--color-theme-light-9);
            background-color: var(--color-theme-light-4);
        }

        .col {
            text-align: center;

            &.flex-1 {
                width: 100px;
            }

            &.flex-2 {
                width: 190px;
            }
        }
    }
}
</style>