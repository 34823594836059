<template>
  <div class="page-header">
    <div class="websit-info">
      <img
        class="websit-logo"
        src="~@/assets/images/logo.png"
        alt=""
        style="margin-right: 10px"
      />
      焙库云仓-后台管理系统
    </div>
    <!-- <div class="route-guid">商品管理<span>/</span>库存中心</div> -->
    <div class="user-info" v-if="userInfo.nickname">
      <img class="user-avatar" :src="userInfo.avatar" alt="" />
      <div class="display">
        <p>{{ userInfo.nickname }}</p>
        <p>{{ userInfo.mobile }}</p>
      </div>
      <div class="button" @click="logout">退出登录</div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { apiMap } from "../../../assets/js/apiMap";

// import TopUserInfoVue from '../TopUserInfo.vue';
const store = useStore();
let userInfo = reactive({});
onMounted(async () => {
  await store
    .dispatch({
      type: "getUserInfo",
    })
    .then((res) => {
      // userInfo = res.data;
      for (const key in res.data) {
        if (Object.hasOwnProperty.call(res.data, key)) {
          const element = res.data[key];
          userInfo[key] = element;
        }
      }
    });
});
// 退出登录
const logout = () => {
  store
    .dispatch({
      type: "logout",
    })
    .then(async (res) => {
      await store.dispatch({
        type: "setUserInfo",
        data: {},
      });
      top.window.location.reload(); //handle.openLogin();
    });
};
</script>
<style lang="less" scoped>
.page-header {
  width: 100%;
  height: var(--line-height-1);
  background-color: var(--color-theme);
  border-bottom: 1px solid var(--color-light-4);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .websit-info,
  .user-info {
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: var(--color-theme-light-5);
  }

  .websit-logo,
  .user-avatar {
    border-radius: 50%;
    width: var(--line-height-3);
    height: var(--line-height-3);
  }

  .websit-info {
    font-size: var(--font-size-6);
    font-weight: bold;
  }

  .user-info {
    color: white;

    .user-avatar {
      border-radius: 50%;
      background-color: white;
      border: 1px solid white;
      width: 40px;
      height: 40px;
    }

    .display {
      text-align: center;
      padding: 0 15px;
      line-height: 1.5;
    }
  }
}
</style>
