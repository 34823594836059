<template>
  <div :class="['foreign-key-root', { readonly: readonly }]">
    <span v-if="readonly">{{ valueScheme.display }}</span>
    <InputBox
      v-else
      v-model="valueScheme.display"
      :buttonGroup="buttonGroup"
      :placeholder="placeholder"
      :readonly="false"
    ></InputBox>
    <Window :config="winConf" @closeWin="winConf.display = false"></Window>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  placeholder: [String, Number, null, undefined],
  foreignValue: { type: [Object], default: {} },
  config: Object,
  readonly: Boolean,
  modelValue: { type: [Number, String, Boolean] },
});
const buttonGroup = ref([
  {
    action: (e) => {
      winConf.title = "选择数据";
      winConf.url = `/table/select/${props.config.foreignTable}?foreignField=${props.config.foreignField}&foreignDisplayField=${props.config.foreignDisplayField}`;
      winConf.display = true;
      if (!top.window[props.config.foreignTable]) {
        top.window[props.config.foreignTable] = {};
      }
      top.window[props.config.foreignTable].selected = (recode) => {
        console.log(recode);
        valueScheme.value = recode[props.config.foreignField];
        valueScheme.display = recode[props.config.foreignDisplayField];
        props.foreignValue = !props.foreignValue ? {} : props.foreignValue;
        props.foreignValue[props.config.foreignField] = recode[props.config.foreignField];
        props.foreignValue[props.config.foreignDisplayField] =
          recode[props.config.foreignDisplayField];
        winConf.display = false;
        // delete top.window[props.config.foreignTable].selected;
        // delete top.window[props.config.foreignTable];
        dataChange();
      };
    },
    display: "选择",
  },
  // {
  //     'emit': 'onPreview',
  //     'display': '查看',
  // }
]);
const winConf = reactive({
  title: "弹窗",
  url: "",
  type: 0,
  width: 800,
  height: 1000,
  display: false,
  isFull: true,
});

const emit = defineEmits(["update:modelValue"]);
const valueScheme = reactive({
  value: "",
  display: "",
});

onMounted(async () => {
  await initVlaue();
});
// 点击打开数据列表，选择需要的数据
const initVlaue = async () => {
  if (!props.foreignValue) {
    return;
  }
  valueScheme.value = props.modelValue;
  valueScheme.display = props.foreignValue[props.config.foreignDisplayField];
};
watch(
  async () => props.modelValue,
  () => {
    initVlaue();
  }
);
const dataChange = () => {
  emit("update:modelValue", valueScheme.value);
};
const preview = () => {
  winConf.title = "查看数据";
};
</script>
<style lang="less">
.foreign-key-root {
  width: 100%;
  height: 30px;
  margin: 10px 0;
  border: 1px solid var(--color-theme-light-4);
  line-height: 28px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  &.readonly {
    border: none;
  }
}
</style>
