<template>
    <div class="platform-config-root">
        <div class="left">
            <div v-for="(node,index) in datas.dirList"
                :class="['left-node',{active:node.id==datas.currentRecode.id}]"
                @click="setActive(node.id)">{{node.name}}</div>
        </div>
        <div class="right">
            <div class="top-buttons">
                <div class="button"
                    @click="save()">保存</div>
                <div class="button"
                    @click="setActive(datas.currentRecode.id)">重置</div>
            </div>
            <div class="value-edit scroll scroll-all">
                <MediaUpload v-if="datas.currentRecode.code=='banner'||datas.currentRecode.code=='community_banner'"
                    v-model="datas.currentRecode.value"></MediaUpload>
                <div v-else-if="datas.currentRecode.code=='kingkong'"
                    class="kingkong">
                    <table>
                        <tr>
                            <th>#</th>
                            <th>名称</th>
                            <th>图标</th>
                            <th>操作</th>
                        </tr>
                        <tr v-for="(item,index) in datas.currentRecode.value">
                            <td>{{index+1}}</td>
                            <td>
                                <InputBox v-model="item.title"
                                    :border="true"
                                    :placeholder="'请输入名称(最多6个字)'"
                                    :len="6"></InputBox>
                            </td>
                            <td>
                                <MediaUpload v-model="item.img"></MediaUpload>
                            </td>
                            <td>
                                <div class="buttons">
                                    <div class="button"
                                        @click="moveUp(index)">上移</div>
                                    <div class="button"
                                        @click="moveDown(index)">下移</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <Richbox v-else-if="isRichBox"
                    v-model="datas.currentRecode.value"></Richbox>
                <div v-else-if="datas.currentRecode.code=='privilege'"
                    class="privilege">
                    <table>
                        <tr>
                            <th>会员名称</th>
                            <th>会员价格</th>
                            <th>会员权益</th>
                        </tr>
                        <tr v-for="(node,index) in datas.currentRecode.value"
                            :key="index">
                            <td>
                                <InputBox v-model="node.name"
                                    :placeholder="'会员名称'"
                                    :len="20"></InputBox>
                            </td>
                            <td>
                                <InputBox v-model="node.price"
                                    :placeholder="'会员价格'"
                                    :preg="'/^[0-9]{1,8}(.[0-9]{1,4})?$/'"
                                    :len="11"></InputBox>
                            </td>
                            <td class="nopadding">
                                <div>
                                    <table>
                                        <tr>
                                            <td>
                                                购买商品优惠
                                            </td>
                                            <td>
                                                <InputBox v-model="node.privilege.discount"
                                                    :preg="'/^0(.[0-9]{1,4})?$/'"
                                                    :placeholder="'购买商品折扣(小于1的小数)'"
                                                    :len="6"></InputBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                达到目标数量退会员费
                                            </td>
                                            <td>
                                                <InputBox v-model="node.privilege.refundCondition"
                                                    :preg="'/^[0-9]{1,6}$/'"
                                                    :len="6"></InputBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                一级分销比例
                                            </td>
                                            <td>
                                                <InputBox v-model="node.privilege.commit1"
                                                    :preg="'/^0(.[0-9]{1,4})?$/'"
                                                    :placeholder="'佣金比例(小于1的小数)'"
                                                    :len="6"></InputBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                二级分销比例
                                            </td>
                                            <td>
                                                <InputBox v-model="node.privilege.commit2"
                                                    :preg="'/^0(.[0-9]{1,4})?$/'"
                                                    :placeholder="'佣金比例(小于1的小数)'"
                                                    :len="6"></InputBox>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-else-if="datas.currentRecode.code=='agent'"
                    class="privilege">
                    <table>
                        <tr>
                            <th>结算类型</th>
                            <th>分成比例</th>
                        </tr>
                        <tr>
                            <td colspan="2"
                                style="color: var(--el-color-danger);">* 低于0.1元的订单暂不计入分红</td>
                        </tr>
                        <tr>
                            <td>
                                省级代理
                            </td>
                            <td class="nopadding">
                                <InputBox v-model="datas.currentRecode.value.pro"
                                    :preg="'/^0(.[0-9]{1,4})?$/'"
                                    :placeholder="'代理分成'"
                                    :len="6"></InputBox>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                市级代理
                            </td>
                            <td class="nopadding">
                                <InputBox v-model="datas.currentRecode.value.city"
                                    :preg="'/^0(.[0-9]{1,4})?$/'"
                                    :placeholder="'代理分成'"
                                    :len="6"></InputBox>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                区/县代理
                            </td>
                            <td class="nopadding">
                                <InputBox v-model="datas.currentRecode.value.contry"
                                    :preg="'/^0(.[0-9]{1,4})?$/'"
                                    :placeholder="'代理分成'"
                                    :len="6"></InputBox>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                平台抽成
                            </td>
                            <td class="nopadding">
                                <InputBox v-model="datas.currentRecode.value.shop"
                                    :preg="'/^0(.[0-9]{1,4})?$/'"
                                    :placeholder="'平台抽成'"
                                    :len="6"></InputBox>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../assets/js/apiMap';
import InputBox from '../../components/lib/edit/InputBox.vue';

const store = useStore();
const datas = reactive({
    dirList: [],
    currentRecode: {

    }
})
const isRichBox = computed(() => {
    return datas.currentRecode.code == 'secret_agreement' ||
        datas.currentRecode.code == 'user_agreement' ||
        datas.currentRecode.code == 'about'
})
const getData = async () => {
    await store.dispatch({
        type: apiMap.SysConfigPageList,
        data: {
            page: 1,
            pageSize: 10,
            condition: {
            },
            order: {
                code: 'asc',
            },
            queryField: ['id', 'code', 'name'],
        }
    }).then(res => {
        datas.dirList = res.list;
        if (res.list[0]) {
            setActive(res.list[0].id);
        }
    });
}
const setActive = async id => {
    datas.currentRecode = {};
    await store.dispatch({
        type: apiMap.SysConfigSelect,
        data: { id: id }
    }).then(res => {
        datas.currentRecode = res;
    });
}
const save = async () => {
    if (!datas.currentRecode.id) {
        top.window.sendMessage('请先在左侧选择要修改的数据');
        return
    }
    await store.dispatch({
        type: apiMap.SysConfigUpdate,
        data: { id: datas.currentRecode.id, data: datas.currentRecode }
    }).then(res => {
        if (res && res.id) {
            top.window.sendMessage('保存成功')
            datas.currentRecode = res;
        }
    });
}
const moveUp = index => {
    let prev = datas.currentRecode.value[index - 1];
    if (!prev) {
        top.window.sendMessage('到顶了');
        return;
    }
    let current = datas.currentRecode.value[index];
    datas.currentRecode.value[index - 1] = current;
    datas.currentRecode.value[index] = prev;
}
const moveDown = index => {
    let next = datas.currentRecode.value[index + 1];
    if (!next) {
        top.window.sendMessage('到底了');
        return;
    }
    let current = datas.currentRecode.value[index];
    datas.currentRecode.value[index + 1] = current;
    datas.currentRecode.value[index] = next;
}
onMounted(() => {
    getData();
})
</script>
<style lang="less" scoped>
.platform-config-root {
    width: 100%;
    height: 100%;
    display: flex;

    .left {
        height: 100%;
        width: 200px;
        border-right: 1px solid #337ad7;
        padding: 10px 15px;

        .left-node {
            height: 48px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #337ad7;
            cursor: pointer;

            &:hover,
            &.active {
                background-color: #337ad7;
                border: 1px solid white;
                color: white;
            }
        }
    }

    .right {
        height: 100%;
        width: calc(100% - 150px);
        padding-top: 36px;
        position: relative;

        .value-edit {
            width: 100%;
            height: 100%;

            .privilege {
                td {
                    padding: 20px 0;

                    &.nopadding {
                        padding: 0;
                    }
                }
            }

            .buttons {
                display: flex;

            }
        }

        .top-buttons {
            width: 100%;
            height: 36px;
            padding: 0 15px;
            border-bottom: 1px solid #337ad7;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;

            .button {
                width: 60px;
                padding: 0;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>