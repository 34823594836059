<template>
    <router-view></router-view>
</template>

<script setup>

</script>
<style lang="less">
@import url("~@/assets/less/global-config/config.less");
@import url("~@/assets/less/reset.less");
@import url("~@/assets/less/style.less");
</style>
