<template>
    <InputBox :conf="telConf"></InputBox>
    <InputBox v-if="methodConf.value == 1"
        :conf="pwdConf"></InputBox>
    <InputBox v-if="methodConf.value == 2"
        :conf="vfcConf"></InputBox>
    <RadioBox :conf="methodConf"></RadioBox>
    <p class="jump-to-find-pwd"
        @click="jumpToFindPwd">重置密码</p>
    <div :class="['login-button', { disabled: isLogging }]"
        @click="login">立即登录</div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex"
// import InputBox from "../../universal/InputBox.vue";
// import RadioBox from "../../universal/RadioBox.vue";
export default {
    name: "Log",
    // components: { InputBox, RadioBox },
    setup() {
        var store = useStore();
        let methodConf = ref({
            key: "",
            value: "1",
            options: [
                { value: "1", label: "密码登录", key: "pwdLogin" },
                { value: "2", label: "短信登录", key: "vfcLogin" },
            ]
        });
        let telConf = ref({
            value: "",
            length: "11",
            rule: /^[0-9]{11}$/,
            required: true,
            placeholder: "请输入手机号",
            errorMsg: "手机号必须是11位国内有效手机号",
        })
        let pwdConf = ref({
            value: "",
            length: "20",
            rule: /^.{6,20}$/,
            placeholder: "请输入登录密码",
            required: true,
            type: "password",
            errorMsg: "密码必须是6-20位任意字符",
        })
        let vfcConf = ref({
            length: "4",
            rule: /^[0-9]{4}$/,
            required: true,
            placeholder: "请输入验证码",
            errorMsg: "验证码必须是4为有效数字",
            buttons: [
                { display: "获取验证码" },
            ]
        })
        let isLogging = computed(() => {
            return store.state.identify.logState;
        });
        let loginable = computed(() => {
            if (isLogging.value) {
                return false;
            }
            return ((telConf.value.rule.test(telConf.value.value)) ? (
                methodConf.value.value == 1 ? (
                    pwdConf.value.rule.test(pwdConf.value.value) ? true : false) : (
                        methodConf.value.value == 2) ? (
                    vfcConf.value.rule.test(vfcConf.value.value) ? true : false
                ) : false
            ) : false);
        })
        onMounted(() => {
        })
        function login() {
            if (!loginable.value) {
                alert("本地错误：无法提交数据");
                return false;
            }
            store.commit("updateLogState", true);
            store.dispatch("login", {
                method: methodConf.value.value,
                tel: telConf.value.value,
                key: methodConf.value.value == 1 ? pwdConf.value.value : methodConf.value.value == 2 ? vfcConf.value.value : "",
            })
        }
        function jumpToFindPwd() {
            store.dispatch("changeIdentifyState", "FindPwd");
        }
        return {
            // vars
            telConf, vfcConf, pwdConf, methodConf, isLogging,
            // computed
            loginable,
            // func
            jumpToFindPwd, login
        }
    }
}
</script>
<style lang="less" scoped>
@import url("~@/assets/less/global-config/config.less");

.input-box {
    margin-bottom: 30px;
}

.jump-to-find-pwd {
    text-align: center;
    cursor: pointer;
    line-height: 26px;

    &:hover {
        text-decoration: underline;
    }
}

.radio-box {
    padding-top: 0;

    .radio-option {
        border-color: transparent;
    }
}

.login-button {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
    background-color: var(--color-theme-light-1);
    color: white;
    border-radius: 16px;
    margin-top: 10px;
    cursor: pointer;

    &.disabled {
        background-color: #c1c1c1;
        cursor: not-allowed;
    }
}
</style>
