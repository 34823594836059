/** @format */

import { createApp, nextTick } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import '@/assets/plugins/iconfont/iconfont.css';
import componentTools  from './components/lib/index.js';
// import Axios from "axios";
// Axios.defaults.baseURL="http://admin.cjky.com/";
// Axios.defaults.proxy.host="http://admin.cjky.com/";
const app = createApp(App);

// 自定义指令
app.directive('slide-vertical', {
	beforeMount(el, binding) {
		slideVertical(el, binding.value);
	},
	updated(el, binding) {
		slideVertical(el, binding.value);
	},
});
app.directive('slide-horizontal', {
	beforeMount(el, binding) {
		slideHorizontal(el, binding.value);
	},
	updated(el, binding) {
		slideHorizontal(el, binding.value);
	},
});

// 全局组件
// console.log(componentsInstaller);
componentTools.componentsInstaller(app);
app.use(store);
app.use(router);
// console.log("ENV:", process.env);
app.config.globalProperties.$env = process.env;
app.mount('#app');

function slideVertical(el, val) {
	let height = 0;
	if (val) {
		el.style.display = '';
		height = el.scrollHeight;
		height = height > window.innerHeight ? window.innerHeight : height;
		el.style.display = '';
	} else {
		el.style.height = `0px`;
		setTimeout(() => {
			el.style.display = 'none';
		}, 300);
	}
	el.style.height = `${height}px`;
}

function slideHorizontal(el, val) {
	let width = 0;
	if (val) {
		el.style.display = '';
		width = el.scrollWidth;
		let configWidth = el.getAttribute('data-width');
		width = configWidth
			? configWidth
			: width > window.innerWidth
			? window.innerWidth
			: width;
		el.style.display = '';
	} else {
		el.style.width = `0px`;
		setTimeout(() => {
			el.style.display = 'none';
		}, 400);
	}
	// console.log(el.style.width);
	el.style.width = `${width}px`;
}
