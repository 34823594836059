/** @format */

export default {
	logout: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/logout',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	userProfile: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/profile',
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	smsLogin: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/api/user/smsLogin',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	login: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/login',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	setPassword: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/setPassword',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	UserInsert: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/insert',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	UserInsertMulti: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/insertMulti',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	UserUpdate: ({ dispatch, commit, state }, payload) => {
		let id = payload.data.id;
		delete payload.data.id;
		return dispatch({
			type: 'request',
			url: `/admin/user/update/${id}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	UserUpdateMulti: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/updateMulti',
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	UserSelect: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/select/${payload.data.id}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	UserSelectBy: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/selectBy/${payload.data.key}/${payload.data.value}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	UserPageList: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/pageList',
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	UserTrash: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/trash/${payload.data.ids}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	UserRecovery: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/recovery/${payload.data.ids}`,
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	UserDelete: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/delete/${payload.data.ids}`,
			method: 'delete',
			data: payload.data,
			params: payload.params,
		});
	},
};
