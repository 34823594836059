/** @format */

export default {
	MallShopReport: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/mall/shop/report',
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopInsert: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/mall/shop/insert',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopInsertMulti: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/mall/shop/insertMulti',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopUpdate: ({ dispatch, commit, state }, payload) => {
		let id = payload.data.id;
		delete payload.data.id;
		return dispatch({
			type: 'request',
			url: `/admin/mall/shop/update/${id}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopUpdateMulti: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/mall/shop/updateMulti',
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopSelect: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/mall/shop/select/${payload.data.id}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopSelectBy: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/mall/shop/selectBy/${payload.data.key}/${payload.data.value}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopPageList: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/mall/shop/pageList',
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopTrash: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/mall/shop/trash/${payload.data.ids}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopRecovery: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/mall/shop/recovery/${payload.data.ids}`,
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	MallShopDelete: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/mall/shop/delete/${payload.data.ids}`,
			method: 'delete',
			data: payload.data,
			params: payload.params,
		});
	},
};
