<template>
    <div class="key-value-root"
        :class="{readonly:readonly}">
        <div class="row"
            v-show="!isChildren">
            <div class="explode">/</div>
            <div class="key">键</div>
            <div class="type">类型</div>
            <div class="value">值</div>
            <div class="handle">操作</div>
        </div>
        <div class="row"
            v-for="(item,index) in valueScheme"
            :key="index">
            <div :class="['explode','iconfont',{
                'icon-right':item.type=='object'&&!item.open,
                'icon-down':item.type=='object'&&item.open,
            }]"
                @click="openChildren(item)"></div>
            <div class="key">
                <InputBox v-if="!readonly"
                    v-model="item.key"
                    :placeholder="'键'"
                    @change="onUpdate"></InputBox>
                <span v-else>{{item.key}}</span>
            </div>
            <div class="type">
                <InputBox v-if="false"
                    v-model="item.type"
                    :placeholder="'类型'"
                    @change="onUpdate"></InputBox>
                <span v-else>{{item.type}}</span>
            </div>
            <div class="value">
                <InputBox v-if="!readonly&&item.type!='object'"
                    v-model="item.value"
                    :placeholder="'值'"
                    @change="onUpdate"></InputBox>
                <span v-else>{{item.type=='object'?'object':item.value}}</span>
            </div>
            <div class="handle">
                <div class="button"
                    @click="removeRow(index)">删除</div>
            </div>
            <div class="children" v-show="item.open" v-if="item.type=='object'">
                <KeyValue v-model="item.value"
                    :readonly="readonly"
                    :isChildren="true"></KeyValue>
            </div>
        </div>
        <div class="row"
            v-if="!readonly">
            <div class="button hover"
                @click="addRow">增加一行</div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
const props = defineProps({
    isChildren: Boolean,
    readonly: Boolean,
    modelValue: { type: [Object, Array] }
});
const emit = defineEmits([
    'update:modelValue'
]);
const valueScheme = ref([]);

onMounted(async () => {
    initVlaue();
});
const initVlaue = () => {
    valueScheme.value = [];
    if (typeof props.modelValue != 'object') {
        return;
    }
    for (const key in props.modelValue) {
        if (Object.hasOwnProperty.call(props.modelValue, key)) {
            const element = props.modelValue[key];
            valueScheme.value.push({
                key: key,
                value: element,
                type: typeof element,
                open: false
            });
        }
    }
}
watch(() => props.modelValue, () => {
    initVlaue();
})
let addCount = 0;
const openChildren = (item) => {
    console.log(item);
    if (item.type == 'object')
        item.open = !item.open
}
const addRow = () => {
    valueScheme.value.push({
        key: `key_${addCount}`,
        value: ''
    });
    addCount++;
    onUpdate();
}
const onUpdate = () => {
    let ret = {};
    for (let i = 0, l = valueScheme.value.length; i < l; i++) {
        let el = valueScheme.value[i];
        ret[el.key] = el.value;
    }
    emit('update:modelValue', ret);
}
const removeRow = (index) => {
    valueScheme.value.splice(index, 1);
    onUpdate();
}
</script>
<style lang="less" scoped>
.key-value-root {
    width: 100%;
    height: auto;
    min-width: 400px;
    border: 1px solid var(--color-light-9);

    &.readonly {
        .row {

            .handle {
                display: none;
            }
        }
    }

    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px solid var(--color-light-9);

        &:nth-last-child(1) {
            border: none;
        }

        min-height: 30px;

        &>div {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 29px;
            border-right: 1px solid var(--color-light-9);
            overflow: hidden;

            input {
                padding: 0 4px;
            }

            &:nth-last-child(1) {
                border: none;
            }
        }

        .input-box-root {
            margin-bottom: 0;
        }

        .explode {
            width: 30px;
            min-width: 30px;
            cursor: pointer;
        }

        .key {
            width: 100px;
            min-width: 100px;
        }

        .type {
            width: 70px;
            min-width: 70px;
        }

        .value {
            width: calc(100% - 240px);

        }

        .handle {
            width: 40px;
            min-width: 40px;
        }

        .button {
            min-height: 24px;
            height: 24px;
            font-size: 12px;
            padding: 2px 4px;
            line-height: 18px;
            margin: 0 auto;
        }

        .children {
            width: 10%;
            min-width: 100%;
            padding-left: 10px;
        }
    }
}
</style>
