<template>
    <div class="sku-config-root"
        :class="{'readonly':readonly}">
        <div class="unit"
            v-for="(unit,key) in valueScheme"
            :key="key">
            <div class="name">
                <InputBox v-model="unit.display"
                    :len="10"
                    :readonly="readonly"
                    :placeholder="'一级规格名称'"
                    @change="onUpdate"></InputBox>
                <span class="iconfont icon-trash-icon"
                    @click="removeRow(key)"
                    title="删除规格"></span>
            </div>
            <div class="options">
                <div v-for="(option,index) in unit.value"
                    :key="index"
                    class="option-item">
                    <InputBox v-model="option.name"
                        :len="10"
                        :readonly="readonly"
                        :placeholder="'二级规格名称'"
                        @change="onUpdate"></InputBox>
                    <span class="iconfont icon-trash-icon"
                        @click="removeOption(unit,index)"
                        title="删除选项"></span>
                </div>
                <div class="button iconfont icon-plus-icon"
                    @click="addOption(unit)"></div>
            </div>
        </div>
        <div class="button"
            @click="addRow">新增一级规格</div>

        <p class="notice danger">* 修改规格后需要重新配置商品库存，请谨慎操作</p>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
let props = defineProps({
    readonly: Boolean,
    modelValue: [Object, Array]
});
const emit = defineEmits([
    'update:modelValue',
    'change'
]);
const valueScheme = ref([]);
const unitButtons = ref([
    {
        display: '删除',
        emit: 'removeUnit',
    }
])
const storeList = computed(() => {
    let data = JSON.stringify(valueScheme.value);
    // 消除数据响应
    return storeInit(JSON.parse(data));
})
onMounted(async () => {
    initVlaue();
});
watch(() => props.modelValue, () => {
    initVlaue();
})
const initVlaue = () => {
    valueScheme.value = props.modelValue;
}
const addRow = () => {
    valueScheme.value.push({
        "value": [], "display": "一级规格"
    });
    onUpdate();
}
const addOption = (unit) => {
    unit.value.push({
        "name": "二级规格新选项"
    })
    onUpdate();
}
const removeOption = (unit, index) => {
    unit.value.splice(index, 1);
    onUpdate();
}
const onUpdate = () => {
    let ret = [];
    let num = 0;
    for (let i = 0, l = valueScheme.value.length; i < l; i++) {
        let options = valueScheme.value[i];
        ret.push({
            code: i,
            display: options.display,
            value: [],
        });
        for (let j = 0, lj = options.value.length; j < lj; j++, num++) {
            let option = options.value[j];
            ret[i].value.push({
                code: num,
                name: option.name,
            })
        }
    }
    emit('update:modelValue', ret);
    emit('change', ret);
}
const removeRow = (index) => {
    valueScheme.value.splice(index, 1);
    onUpdate();
}
</script>
<style lang="less" scoped>
.sku-config-root {
    width: 100%;
    min-width: 400px;
    padding-bottom: 10px;

    .notice {
        line-height: 30px;
    }

    .unit {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--color-light-9);
        margin: 10px 0;

        .name {
            height: 30px;
            overflow: hidden;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 30px;
            border: 1px solid var(--color-theme-light-4);
            background-color: var(--color-light-8);

            .input-box-root {
                margin: 0;
            }

            position: relative;
        }

        .options {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 10px 0;

            .icon-plus-icon {
                margin: 0 10px 10px 0;
            }

            .option-item {
                height: 30px;
                overflow: hidden;
                width: 222px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 30px;
                border: 1px solid var(--color-theme-light-4);
                border-radius: 4px;
                margin-bottom: 10px;

                &:nth-child(2n-1) {
                    margin-right: 10px;
                }

                .input-box-root {
                    margin: 0;
                }

                position: relative;
            }
        }

        .name>.icon-trash-icon,
        .option-item>.icon-trash-icon {
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 100%;
            color: var(--el-color-danger);
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

    }

    &>.button {
        width: 100px;
        text-align: center;
    }

    &.readonly {
        .notice {
            display: none;
        }

        .unit {
            .name {
                .input-box-root {
                    margin: 0;
                }

                position: relative;
            }

            .options {
                .icon-plus-icon {
                    display: none;
                }
            }

            .name>.icon-trash-icon,
            .option-item>.icon-trash-icon {
                display: none;
            }

        }

        &>.button {
            display: none;
        }
    }
}
</style>
