<template>
	<div :class="['switch-box', { 'switch-on': valueScheme }]"
		@click="switchFunc">
		<div class="switch-bar">
			<span class="switch-thumb"></span>
		</div>
	</div>
</template>
<script setup>
import { onMounted, reactive, ref, watch } from 'vue';

const props = defineProps({
	readonly: Boolean,
	modelValue: { type: [Number, String, Boolean] }
});

const emit = defineEmits([
	'update:modelValue'
]);
const valueScheme = ref(false);

onMounted(async () => {
	initVlaue();
});
const initVlaue = () => {
	valueScheme.value = props.modelValue ? true : false;
}
watch(() => props.modelValue, () => {
	initVlaue();
})
const dataChange = () => {
	emit('update:modelValue', valueScheme.value ? 1 : 0);
}
const switchFunc = () => {
	if (props.readonly) {
		return;
	}
	valueScheme.value = !valueScheme.value
	dataChange();
}
</script>
<style lang="less">
@import url("~@/assets/less/global-config/config.less");
@switchThemeOff: #acaeb1;

.switch-box {
	width: 25px;
	height: 10px;
	border-radius: 5px;
	position: relative;
	border: 1px solid @switchThemeOff;
	background-color: @switchThemeOff;
	margin: 10px auto;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 4px @switchThemeOff;
	}

	.switch-bar {
		width: 0;
		height: 100%;
		position: absolute;
		left: 0;
		transition: width ease .3s;

		.switch-thumb {
			display: block;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			border: 1px solid @switchThemeOff;
			background-color: white;
			position: absolute;
			top: -3px;
			right: -13px;
		}
	}

	&.switch-on {
		border: 1px solid var(--color-theme-light-1);
		background-color: @switchThemeOff;

		&:hover {
			box-shadow: 0 0 4px @switchThemeOff;
		}

		.switch-bar {
			background-color: var(--color-theme-light-1);
			width: calc(100% - 12px);

			.switch-thumb {
				border: 1px solid var(--color-theme-light-1);
			}
		}
	}
}
</style>