/** @format */

export default {
	SysTableInsert: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/sys/table/insert',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableInsertMulti: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/sys/table/insertMulti',
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableUpdate: ({ dispatch, commit, state }, payload) => {
		let id=payload.data.id;delete payload.data.id;
		return dispatch({
			type: 'request',
			url: `/admin/sys/table/update/${id}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableUpdateMulti: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/sys/table/updateMulti',
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableSelect: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/sys/table/select/${payload.data.id}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableSelectBy: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/sys/table/selectBy/${payload.data.key}/${payload.data.value}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTablePageList: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/sys/table/pageList',
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableTrash: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/sys/table/trash/${payload.data.ids}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableRecovery: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/sys/table/recovery/${payload.data.ids}`,
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	SysTableDelete: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/sys/table/delete/${payload.data.ids}`,
			method: 'delete',
			data: payload.data,
			params: payload.params,
		});
	},
};
