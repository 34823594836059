const importFn = require.context('./', true, /\.vue$/)
export default {
  componentsInstaller (app) {
    importFn.keys().forEach(path => {
      const component = importFn(path).default
      const name=path.replace(/^.+\/([A-z0-9_]+).vue$/g,"$1");
      app.component(name, component);
    })
  }
}
