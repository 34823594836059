<template>
    <Container :horizontal="'center'"
        :vertical="'center'">
        <div class="page-login-root">
            <InputBox v-model="datas.mobile"
                :border="true"
                :prefix="'手机号'"
                :preg="'/^\\d{11}$/'"
                :radius="true"
                :len="11"></InputBox>
            <InputBox v-show="datas.methodConf.value == 0 || datas.findPwd"
                :border="true"
                :radius="true"
                :type="'password'"
                :prefix="'密&nbsp;&nbsp;&nbsp;码'"
                :len="20"
                v-model="datas.password"></InputBox>
            <InputBox v-show="datas.methodConf.value == 1 || datas.findPwd"
                :border="true"
                :radius="true"
                :len="'6'"
                :prefix="'验证码'"
                :buttonGroup="datas.sendSms"
                v-model="datas.code"></InputBox>
            <RadioBox style="margin-top: -20px;justify-content: center;"
                v-show="!datas.findPwd"
                v-model="datas.methodConf.value"
                :config="datas.methodConf"></RadioBox>
            <p class="jump-to-find-pwd"
                @click="datas.findPwd = !datas.findPwd">{{ datas.findPwd ? '继续登录' : '重置密码' }}</p>
            <div :class="['login-button', { disabled: datas.isLogging }]"
                @click="login">{{ datas.findPwd ? '重置密码' : '立即登录' }}</div>
        </div>
    </Container>
</template>

<script setup>
import { reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../assets/js/apiMap.js'

const store = useStore();
const datas = reactive({
    findPwd: false,
    btnText: '立即登录',
    isLogging: false,
    isSendSms: false,
    smsAgain: 0,
    mobile: '18085192480',
    password: '123456',
    code: '',
    sendSms: [
        {
            display: '发送验证码',
            action: (button) => {
                if (!datas.mobile) {
                    top.window.sendMessage({ type: 'warn', content: '请输入手机号', });
                    return;
                }
                if (datas.isSendSms || datas.smsAgain > 0) {
                    top.window.sendMessage({ type: 'warn', content: '操作频繁', });
                    return;
                }

                datas.isSendSms = true;
                datas.sendSms[0].display = `发送中...`;
                store.dispatch({
                    type: 'request',
                    url: '/api/sms/send',
                    method: 'post',
                    data: {
                        mobile: datas.mobile,
                        type: datas.findPwd ? 2 : 1,
                    }
                }).then(res => {
                    datas.isSendSms = false;
                    if (!res) {
                        top.window.sendMessage({ type: 'error', content: '发送失败', });
                        datas.sendSms[0].display = `发送验证码`;
                    } else {
                        top.window.sendMessage({ type: 'success', content: '发送成功', });
                        datas.smsAgain = 60;
                        let interval = setInterval(() => {
                            if (datas.smsAgain <= 0) {
                                datas.smsAgain = 0;
                                clearInterval(interval);
                                datas.sendSms[0].display = `发送验证码`;
                                return;
                            }
                            --datas.smsAgain;
                            datas.sendSms[0].display = `${datas.smsAgain}s后再次发送`;
                        }, 1000);
                    }
                })
            }
        }
    ],
    methodConf: {
        dictionary: 'login_method',
        // key: "",
        value: "0",
        // options: [
        //     { value: "1", label: "密码登录", key: "pwdLogin" },
        //     // { value: "2", label: "短信登录", key: "vfcLogin" },
        // ]
    }
})
const login = async () => {
    if (!datas.mobile) {
        top.window.sendMessage({ type: 'warn', content: '请输入手机号', });
        return;
    }
    if (datas.isLogging) {
        top.window.sendMessage({ type: 'warn', content: '正在提交数据，请勿重复操作', });
        return;
    }
    datas.isLogging = true;
    if (datas.findPwd) {
        store.dispatch({
            type: 'request',
            method: 'put',
            url: '/api/user/setPassword',
            data: {
                mobile: datas.mobile,
                password: datas.password,
                code: datas.code,
            }
        }).then(res => {
            datas.isLogging = false;
            if (res) {
                top.window.sendMessage({ type: 'success', content: '密码已重置', });
                datas.findPwd = false;
            } else {
                top.window.sendMessage({ type: 'warn', content: '重置密码失败，请稍后重试', });
            }
        });
        return;
    }
    store.dispatch({
        type: datas.methodConf.value == 0 ? apiMap.login : apiMap.smsLogin,
        data: datas.methodConf.value == 0 ? { mobile: datas.mobile, password: datas.password } : { mobile: datas.mobile, code: datas.code },
    }).then(res => { // 登录成功，获取用户信息
        datas.isLogging = false;
        store.dispatch({
            type: 'setToken',
            token: res.token
        })
        return res;
    }).then(() => {
        store.dispatch({
            type: apiMap.userProfile
        }).then((resProfile) => {
            store.dispatch({
                type: 'setUserInfo',
                data: resProfile
            }).then(() => {
                top.window.location.reload()// .handle.openIndex()
            })
        })
    })
}
const jumpToFindPwd = () => {

}
</script>
<style lang="less" scoped>
.page-login-root {
    width: 400px;
    border: 1px solid var(--color-theme-light-6);
    border-radius: 10px;
    background-color: var(--el-color-white);
    padding: 30px;

    .input-box-root {
        margin-bottom: 30px;
    }

    .radio-box {
        padding-top: 0;
        width: 100%;
        justify-content: center;

        .radio-option {
            border-color: transparent;
        }
    }

    .jump-to-find-pwd {
        text-align: center;
        cursor: pointer;
        line-height: 26px;

        &:hover {
            text-decoration: underline;
        }
    }

    .login-button {
        width: 100%;
        margin: 0 auto;
        padding: 10px 0;
        text-align: center;
        background-color: var(--color-theme-light-1);
        color: white;
        border-radius: 16px;
        margin-top: 10px;
        cursor: pointer;

        &.disabled {
            background-color: var(--color-light-8);
            cursor: not-allowed;
        }
    }
}
</style>
