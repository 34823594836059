import {
    createStore
} from "vuex";
// import axios from "axios";
import actions from "./actions";
import mutations from "./mutations";
import state from "./states";
import getters from "./getters";
const modules = {

}
export default createStore({
    actions,
    mutations,
    state,
    getters,
    modules,
});
