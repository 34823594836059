<template>
    <div>
        <div class="cku-config">
            <div>selected={{selectedOptions}}</div>
            <div class="sku-item"
                v-for="(item,index) in skuConfig"
                :key="index">
                <span>{{item.display}}</span>
                <template v-for="(option,key) in item.value"
                    :key="key">
                    <div :class="['options' ,{
                        'selected':((1<<option.code)|selectedOptions)==selectedOptions,
                        'disabled':!optionSelectable(item,option),
                    }]"
                        @click="select(item,option)">
                        {{option.code}}=>{{option.name}}({{optionSelectable(item,option)}})
                        <!-- <input type="radio"
                            :id="`label_${index}_${key}`"
                            :value="option.code"
                            v-model="selectedOptions[index]"
                            @change=""> -->
                        <!-- <label :for="`label_${index}_${key}`">{{option.name}}</label> -->
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
const props = defineProps({
    skuConfig: [Array, Object],
    storeList: [Array, Object]
})
const selectedOptions = ref(0)
const optionSelectable = (item, option) => {
    for (let i = 0, l = props.storeList.length; i < l; i++) {
        let store = props.storeList[i];
        let storeCode = Number(store.sku_code);
        let selectedTemp = selectedOptions.value;
        let inc = 1 << option.code;
        let newSelected = selectedTemp + inc;
        // 判断当前选项有没有被选中
        if ((selectedTemp | inc) != selectedTemp) {
            selectedTemp = newSelected;
            // 暂时清除同级别已选的其它选项
            selectedTemp = clearSelectNeighbor(item, option, selectedTemp)
        }
        // 选中当前规格 且选中后库存大于0
        if ((storeCode | selectedTemp) == storeCode && Number(store.stock) > 0) {
            console.log(storeCode, selectedTemp, Number(store.stock));
            return true;
        }
    }
    return false;
}
const select = (item, option) => {
    let key = 1 << option.code;
    key *= ((key | selectedOptions.value) == selectedOptions.value) ? -1 : 1;
    if (key > 0 && !optionSelectable(item, option)) {
        console.log('不可选');
        return;
    }
    selectedOptions.value = clearSelectNeighbor(item, option, selectedOptions.value);
    selectedOptions.value += key;
}
// 取消已选择的其它同级别属性，并返回新的已选结果
const clearSelectNeighbor = (item, current, selected) => {
    for (let i = 0, l = item.value.length; i < l; i++) {
        if (current.code == item.value[i].code) {
            continue;
        }
        let key = 1 << item.value[i].code;
        if ((key | selected) == selected) {
            selected -= key;
            break;
        }
    }
    return selected
}
// const skuConfig = [];
// const storeList = [];
// watch(
//     () => props.skuConfig,
//     () => {
//         initData();
//     })
// watch(
//     () => props.storeList,
//     () => {
//         initData();
//     })
const initData = () => {
}
onMounted(() => {
    // initData();
})
</script>
<style lang="less" scoped>
.cku-config {
    width: 100%;
    display: flex;
    flex-direction: column;

    .sku-item {
        // margin: 0 10px;
        margin: 10px 0;
        display: flex;
    }

    .options {
        margin: 0 10px;
        cursor: pointer;
        color: #337ad7;
        padding: 10px;

        &.selected {
            border: 1px solid;
        }

        &.disabled {
            color: red;
        }
    }
}
</style>