/** @format */
import axios from 'axios';

const importFn = require.context('./', true, /\.js$/);
const actions = {};
const pathArr = importFn.keys();
for (let i = 0, len = pathArr.length; i < len; i++) {
    if(/^.+apiMap.js$/.test(pathArr[i])){
		continue;
    }
	const result = importFn(pathArr[i]).default;
	if (!result) {
		continue;
	}
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const element = result[key];
            actions[key]=element;
        }
    }
	// actions = Object.assign(actions, result);
}
export default {
	...actions,
}

