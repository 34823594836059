<template>
    <div class="table-edit-root">
        <div class="buttons-contain">
            <div class="button"
                @click="saveRecode()">保存</div>
            <div class="button"
                v-show="id!=0"
                @click="getData()">刷新</div>
            <!-- <div class="button"
                @click="deleteRecode()">删除</div> -->
        </div>
        <template v-for="(field,index) in conf.fieldSchema"
            :key="index">
            <div v-if="field.value!='deleted_at'"
                class="row">
                <div class="col data-name">{{field.display}}</div>
                <div class="col table-cell-container text-etc">
                    <component :is="field.component"
                        :config="field.config"
                        :border="true"
                        :radius="true"
                        :placeholder="field.display"
                        :preg="field.preg"
                        :len="field.len"
                        :readonly="field.config.readonly"
                        :foreignValue="conf.data[`${field.value}Foreign`]"
                        v-model="conf.data[field.value]"></component>
                </div>
            </div>
        </template>
        <!-- <ForeighKey></ForeighKey> -->
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiMap } from "../../assets/js/apiMap";
const store = useStore()
const route = useRoute();
const tableName = route.params.table;
const id = route.params.id;
const conf = reactive({
    tableSchema: {},
    fieldSchema: [],
    pageInfo: {

    },
    queryCondtion: {
        keywords: '',
    },
    orderMethod: [],
    data: {},
});
const getData = async () => {
    store.dispatch({
        type: 'request',
        url: `/admin/${tableName.split('_').join('/')}/select/${id}`,
        method: 'get',
    }
    ).then(res => {
        conf.data = res;
    });
}
const saveRecode = () => {
    let url = `/admin/${tableName.split('_').join('/')}/${(id != 0 ? `update/${id}` : 'insert')}`
    let data = {};
    if (id == 0) {
        data = conf.data;
    } else {
        data = {
            data: conf.data
        }
    }
    store.dispatch({
        type: 'request',
        url: url,
        method: id != 0 ? 'put' : 'post',
        data
    }
    ).then(res => {
        if (id == 0) {
            location.reload();
        }
        for (const key in res) {
            if (Object.hasOwnProperty.call(res, key)) {
                const element = res[key];
                conf.data[key] = element;
            }
        }
        top.window.sendMessage('保存成功');
    });
}
// const deleteRecode=()=>{
//     store.dispatch({
//         type: 'request',
//         url: `/admin/${tableName.split('_').join('/')}/delete/${id}`,
//         method: 'delete',
//     }
//     ).then(res => {

//     });
// }
onMounted(async () => {
    await store.dispatch({
        type: apiMap.SysTableSelectBy,
        data: {
            key: 'value', value: tableName
        }
    }).then(res => {
        conf.tableSchema = res;
    });
    await store.dispatch({
        type: apiMap.SysTableFieldPageList,
        params: {
            page: 1, pageSize: 100, condition: { table_value: tableName }, order: { position: 'asc' }
        }
    }).then(res => {
        conf.fieldSchema = res.list
        for (let i = 0, l = conf.fieldSchema.length; i < l; i++) {
            let field = conf.fieldSchema[i];
            if (field.type == 'json') {
                conf.data[field.value] = Object(JSON.parse(field.default));
                continue;
            }
            conf.data[field.value] = field.default;
            if (field.component == 'ForeignKey') {
                conf.data[`${field.value}Foreign`] = {}
                conf.data[`${field.value}Foreign`][field.config.foreignField] = '';
                conf.data[`${field.value}Foreign`][field.config.foreignDisplayField] = '';
            }
        }
    });
    if (id != 0) {
        await getData();
    }
})
</script>
<style lang="less" scoped>
.table-edit-root {
    width: 100%;
    padding: 10px 30px;


    // border: 1px solid;
    .row {
        border-bottom: 1px solid var(--color-light-9);
        display: flex;
        align-items: center;

        .col {
            border: none;

            &.table-cell-container {
                text-align: left;
                padding: 10px 15px 10px 10px;
                width: 500px;
                overflow: unset;

                .input-box-root {
                    margin: 20px 0;
                }
            }

            &.data-name {
                text-align: right;
                font-weight: bold;
                font-size: 14px;
                width: 150px;
                padding: 0px 20px 0 10px;
            }
        }
    }

}
</style>