<template>
    <div class="table-edit-root">
        <div class="order-info">
            <template v-if="conf.data.address">
                <div class="row row-title">详情内容</div>
				<div class="row">
				    <div class="col">电话</div>
				    <div class="col">{{ conf.data.phone }}</div>
				</div>
				<div class="row">
				    <div class="col">手机</div>
				    <div class="col">{{ conf.data.mobile }}</div>
				</div>
                <div class="row">
                    <div class="col">地址</div>
                    <div class="col">{{ conf.data.address }}{{ conf.data.address_detail}}</div>
                </div>
                <div class="row">
                    <div class="col">简介内容</div>
                    <div class="col">{{ conf.data.introduction }}</div>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiMap } from "../../assets/js/apiMap";
import Window from "../../components/lib/tools/Window.vue";
const store = useStore();
const route = useRoute();
const tableName = "user_resource_circle";
const id = route.params.id;
const conf = reactive({
    serviceType: {},
    serviceStatus: {},
    orderStatus: {},
    tableSchema: {},
    fieldSchema: [],
    pageInfo: {},
    queryCondtion: {
        keywords: "",
    },
    orderMethod: [],
    data: {},
});
const getData = async () => {
    store.dispatch({
            type: "request",
            url: `/admin/${tableName.split("_").join("/")}/select/${id}`,
            method: "get",
        }).then((res) => {
            if (!res) {
                top.window.handle.addMessage({
                    type: "error",
                    content: "资源圈信息已不存在",
                });
                return;
            }
            conf.data = res;
        });
};

onMounted(async () => {
    await store
        .dispatch({
            type: apiMap.SysTableSelectBy,
            data: {
                key: "value",
                value: tableName,
            },
        }).then((res) => {
            conf.tableSchema = res;
        });
    await store
        .dispatch({
            type: apiMap.SysTableFieldPageList,
            params: {
                page: 1,
                pageSize: 100,
                condition: { table_value: tableName },
                order: { position: "asc" },
            },
        })
        .then((res) => {
            conf.fieldSchema = res.list;
            for (let i = 0, l = conf.fieldSchema.length; i < l; i++) {
                let field = conf.fieldSchema[i];
                if (field.type == "json") {
                    conf.data[field.value] = Object(JSON.parse(field.default));
                    continue;
                }
                conf.data[field.value] = field.default;
                if (field.component == "ForeignKey") {
                    conf.data[`${field.value}Foreign`] = {};
                    conf.data[`${field.value}Foreign`][field.config.foreignField] = "";
                    conf.data[`${field.value}Foreign`][field.config.foreignDisplayField] = "";
                }
            }
        });
    if (id != 0) {
        await getData();
    }
});
</script>
<style lang="less" scoped>
.table-edit-root {
    width: 100%;
    padding: 10px 30px;
    height: 100%;

    .row {
        border-bottom: 1px solid var(--color-light-9);
        display: flex;
        align-items: center;

        &.row-title {
            width: 100px;
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            color: var(--color-theme-light-9);
            background-color: var(--color-theme-light-4);
        }

        .col {
            border: none;
            text-align: right;
            min-height: 30px;
            padding: 10px;
            display: flex;
            align-items: center;

            &:nth-child(1) {
                padding: 0 10px;
                text-align: right;
                width: 100px;
                height: unset;
                align-items: center;
                font-weight: bold;
            }
        }

        .button-group {
            display: flex;
            padding: 10px 0;
        }
    }
}
</style>
