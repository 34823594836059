<template>
    <div>
        <span>{{display}}%</span>
    </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps([
    'value', 'toFixed'
]);
const display = computed(() => {
    let value = 0, toFixed = 0;
    if (props && !isNaN(props.value)) {
        value = parseFloat(props.value)
    }
    if (props && !isNaN(props.toFixed)) {
        toFixed = parseInt(props.toFixed)
    }
    return (value * 100).toFixed(toFixed);
})
</script>