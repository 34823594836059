export default {
	UserResourceCircleInsert: ({ dispatch, commit, state}, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/resource/circle/insert',
			method: 'post',
			data: payload.data,
			params: payload.params,
		})
	},
	UserResourceCircleInsertMulti: ({ dispatch, commit, state}, payload) => {
		return dispath({
			type: 'request',
			url: '/admin/user/resource/circle/insertMulti',
			method: 'post',
			data: payload.data,
			params: payload.params,
		})
	},
	UserResourceCircleUpdate: ({ dispatch, commit, state}, payload) => {
		let id = payload.data.id;
		delete payload.data.id;
		return dispatch({
			type: 'request',
			url: `/admin/user/resource/circle/update/${id}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		})
	},
	UserResourceCircleUpdateMulti: ({ dispatch, commit, state}, payload) => {
		return dispatch({
			type: 'request',
			url: '/admin/user/resource/circle/updateMulti',
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	UserResourceCircleSelect: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/resource/circle/select/${payload.data.id}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	UserResourceCircleSelectBy: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/resource/circle/selectBy/${payload.data.key}/${payload.data.value}`,
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	UserResourceCirclePageList: ({ dispathc, commit, state }, payload) => {
		return dispath({
			type: 'request',
			url: '/admin/user/resource/circle/pageList',
			method: 'get',
			data: payload.data,
			params: payload.params,
		});
	},
	UserResourceCircleTrash: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/resource/circle/trash/${payload.data.ids}`,
			method: 'put',
			data: payload.data,
			params: payload.params,
		});
	},
	UserResourceCircleRecovery: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/resource/circle/recovery/${payload.data.ids}`,
			method: 'post',
			data: payload.data,
			params: payload.params,
		});
	},
	UserResourceCircleDelete: ({ dispatch, commit, state }, payload) => {
		return dispatch({
			type: 'request',
			url: `/admin/user/resource/circle/delete/${payload.data.ids}`,
			method: 'delete',
			data: payload.data,
			params: payload.params,
		});
	},
}