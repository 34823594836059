import {
    createRouter,
    createWebHistory
} from 'vue-router'
import {
    routes
} from './routeTable'
// 界面分为page页面和panel面板，页面需要包含菜单和用户信息栏
// console.log(routes);
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
