<template>
    <div :class="['media-upload-root',{
        'multi':typeOfFile=='object'
    }]">
        <input type="file"
            ref="fileInput"
            accept="image/*"
            @change="upload($event)">
        <div class="img-container">
            <template v-if="typeOfFile=='string'">
                <div v-if="files==''"
                    class="img"
                    v-show="!readonly"
                    @click="selectFile()">
                    <span :class="['iconfont','icon-plus-icon']"></span>
                </div>
                <div v-else
                    class="img string">
                    <MediaContainer :resource="files"
                        :size="1"></MediaContainer>
                    <div class="img-tools">
                        <span v-show="!readonly"
                            title="重新选择"
                            :class="['iconfont',{
                                'icon-plus-icon':files=='',
                                'icon-reload-1-icon':files!='',
                            }]"
                            @click="selectFile()"></span>
                        <span title="查看"
                            class="iconfont icon-loupe-icon"
                            @click="imgPreview(files)"></span>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="img array"
                    v-for="(file,index) in files">
                    <MediaContainer :resource="file"
                        :size="1"></MediaContainer>
                    <div class="img-tools">
                        <span v-show="!readonly"
                            title="删除"
                            :class="['iconfont',{
                                'icon-trash-icon':file
                            }]"
                            @click="trash(index)"></span>
                        <span title="查看"
                            class="iconfont icon-loupe-icon"
                            @click="imgPreview(file)"></span>
                    </div>
                </div>
                <div class="img"
                    v-show="!readonly"
                    @click="selectFile()">
                    <span :class="['iconfont','icon-plus-icon']"></span>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { tools } from '../../../assets/js/common';
const files = ref('');
const fileInput = ref();
const props = defineProps({
    readonly: Boolean,
    modelValue: { type: [String, Array] }
})
const emit = defineEmits([
    'update:modelValue', 'change'
])
const typeOfFile = computed(() => {
    return typeof props.modelValue;
});
const route = useRoute();
const store = useStore();
onMounted(async () => {
    initValue();
})
watch(() => props.modelValue, () => {
    initValue();
})
const upload = e => {
    top.window.sendMessage('文件正在上传');
    store.dispatch({
        type: 'upload',
        file: e.target.files[0]
    }).then(res => {
        top.window.sendMessage('文件已上传');
        if (typeOfFile.value == 'string') {
            files.value = res;
        } else {
            files.value.push(res);
        }
        emit('update:modelValue', files.value);
        emit('change', files.value);
    })
}
const initValue = () => {
    files.value = typeOfFile.value == 'string' ? props.modelValue + '' : JSON.parse(JSON.stringify(props.modelValue));
}
const selectFile = async () => {
    // console.log(fileInput.value.value);
    fileInput.value.value = '';
    fileInput.value.click()
}
const trash = (index) => {
    files.value.splice(index, 1);
    emit('update:modelValue', files.value);
    emit('change', files.value);
}
const imgPreview = file => {
    tools.openResource(file)
}
// console.log(route.path);
</script>


<style lang="less" scoped>
.media-upload-root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.multi {
        min-width: 228px;
        // max-width: 228px;

        .img-container {
            display: flex;
            align-items: center;
            justify-content: unset;
            width: 100%;
            // padding: 10px;
            flex-wrap: wrap;
        }
    }

    input[type=file] {
        display: none;
    }

    .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // padding: 10px;
        flex-wrap: wrap;

        .img {
            width: 80px;
            height: 80px;
            border: 1px solid;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: var(--color-light-7);
            background-color: var(--color-light-11);
            margin: 10px;
            cursor: pointer;
            overflow: hidden;

            .img-tools {
                position: absolute;
                width: 100%;
                height: 26px;
                display: none;
                align-items: center;
                justify-content: space-evenly;
                bottom: 0;
                left: 0;

                .iconfont {
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 26px;
                    height: 26px;
                    font-size: 12px;
                    border-radius: 50%;
                    background-color: var(--bg-transparent-99);

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .icon-plus-icon {
                    font-size: 30px;
                }

                .icon-reload-1-icon {
                    color: var(--color-theme);
                }

                .icon-loupe-icon {
                    color: var(--el-color-success);
                }

                .icon-trash-icon {
                    color: var(--el-color-danger);
                }
            }

            &:hover {
                .img-tools {
                    display: flex;
                    z-index: 1;
                    // background-color: var(--bg-transparent-99);
                }
            }
        }
    }
}
</style>
