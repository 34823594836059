<template>
    <div class="menu-tree-root">
        <div v-for="(item,index) in list"
            class="menu-item">
            <div class="content"
                @click.stop="menuSwitch(item)">
                <div class="status"
                    @click.stop="selectMenu(item)">
                    <div v-show="role.right[index]&&role.right[index].status==1"
                        class="status-selected"></div>
                </div>
                {{item.name}}
                <span v-show="item.type==3||item.type==0"
                    :class="['switch','iconfont',{
                        'icon-down':!item.displayChildren,
                        'icon-up':item.displayChildren
                    }]"></span>
            </div>
            <div v-show="item.children&&item.displayChildren"
                class="children">
                <MenuTree :list="item.children"
                    :type="type"
                    :role="role"
                    @checkParent="checkChildren(item,$event)"></MenuTree>
                <!-- <Waitting v-else></Waitting> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../../../assets/js/apiMap';
const store = useStore();
const props = defineProps({
    list: [Array, Object],
    type: [Array, Object],
    role: [Array, Object],
})
const emit = defineEmits(['checkParent']);
const checkRight = el => {
    if (!props.role.right[el.id]) {
        props.role.right[el.id] = {
            "role_id": props.role.id,
            "menu_id": el.id,
            "status": 0,
        }
    }
}
const selectMenu = item => {
    // 选择/取消选择当前节点  选择/取消选择下级节点
    checkRight(item);
    props.role.right[item.id].status = props.role.right[item.id].status == 1 ? 0 : 1;
    for (const key in item.children) { // 同步下级权限状态
        if (Object.hasOwnProperty.call(item.children, key)) {
            checkRight(item.children[key]);
            props.role.right[item.children[key].id].status = props.role.right[item.id].status;
        }
    }
    selectChildren(item, props.role.right[item.id].status);
    emit('checkParent', item);
}
const selectChildren = (item, status) => {
    for (const key in item.children) { // 同步下级权限状态
        if (Object.hasOwnProperty.call(item.children, key)) {
            checkRight(item.children[key]);
            props.role.right[item.children[key].id].status = status
            if (item.children[key].type == 0 || item.children[key].type == 3) {
                selectChildren(item.children[key], status);
            }
        }
    }
}
const checkChildren = (item, child) => {
    checkRight(item);
    if (props.role.right[child.id].status == 1) {
        props.role.right[item.id].status = 1;
        emit('checkParent', item);
        return;
    }
    // 如果每一个下级都没选，那么取消当前节点的选中状态
    for (const key in item.children) { // 同步下级权限状态
        if (Object.hasOwnProperty.call(item.children, key)) {
            checkRight(item.children[key]);
            if (props.role.right[item.children[key].id].status == 1) {
                props.role.right[item.id].status = 1;
                emit('checkParent', item);
                return;
            }
        }
    }
    props.role.right[item.id].status = 0;
}
const menuSwitch = item => {
    item.displayChildren = !item.displayChildren;
}
</script>

<style lang="less" scoped>
.menu-tree-root {
    width: 100%;

    .menu-item {
        width: 100%;

        .content {
            width: 100%;
            height: 32px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--color-theme-light-1);
            padding: 0 31px;
            position: relative;
            border-left: 1px solid var(--color-theme-light-1);
            cursor: pointer;

            &:hover {
                background-color: #f2f2f2;
            }

            .status {
                width: 14px;
                height: 14px;
                border: 1px solid var(--color-theme-light-1);
                border-radius: 50%;
                position: absolute;
                left: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: var(--color-theme-light-8);
                }

                .status-selected {
                    width: 6px;
                    height: 6px;
                    background-color: var(--color-theme-light-1);
                    border-radius: 50%;
                }
            }

            .iconfont.switch {
                position: absolute;
                right: 0;
                width: 31px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .children {
            width: 100%;
            padding-left: 30px;
        }
    }
}
</style>