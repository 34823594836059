<template>
    <div class="sys-menu-node">
        <div class="sys-menu-title"
            @click="node.isOpen=!node.isOpen">{{ node.name }}
            <span :class="['iconfont',{
                'icon-down':!node.isOpen,
                'icon-up':node.isOpen
            }]"></span>
        </div>
        <div class="sys-menu-list"
            v-show="node.isOpen">
            <div class="sys-menu-item text-etc"
                v-for="(item, index) in node.options"
                :key="index"
                @click="menuTrigger(item)"
                :href="item.href">
                {{item.name }}
            </div>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits([
    'menuTrigger'
])
const props = defineProps({
    node: Object
});
const menuTrigger = (item) => {
    emit('menuTrigger', item);
}
</script>
<style lang="less" scoped>
.sys-menu-node {
    width: 100%;

    .sys-menu-title {
        border-bottom: 1px solid var(--color-light-9);
        color: var(--color-light-9);
        font-size: var(--font-size-3);
        padding: 0px 0 10px;
        font-weight: bold;
        cursor: pointer;

        .iconfont {
            display: none;
        }
    }

    .sys-menu-list {
        font-size: var(--font-size-1);
        display: flex;
        flex-wrap: wrap;
        color: var(--color-light-10);
        padding: 10px 0;

        .sys-menu-item {
            background-color: var(--color-theme-light-1);
            border-radius: 4px;
            margin: 0 0px 10px 0;
            padding: 0 4px;
            width: 80px;
            text-align: center;
            line-height: var(--line-height-7);

            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            &:nth-child(3n+2) {
                margin: 0 15px 10px;
            }
        }
    }

    &.normal {
        background-color: transparent;

        .sys-menu-title {
            height: 30px;
            width: 100%;
            line-height: 29px;
            padding: 0 15px;
            text-align: left;
            font-size: inherit;
            color: var(--color-light-9);
            position: relative;

            .iconfont {
                display: flex;
                position: absolute;
                right: 0;
                top: 0;
                width: 29px;
                height: 29px;
                align-items: center;
                justify-content: center;
                background-color: transparent;
            }
        }

        .sys-menu-list {
            font-size: var(--font-size-1);
            display: flex;
            flex-wrap: wrap;
            color: var(--color-light-9);
            padding: 0;
            // background-color: #f2f2f2;
            background-color: transparent;

            .sys-menu-item {
                width: 100%;
                height: 30px;
                line-height: 29px;
                margin: 0;
                border-bottom: 1px solid var(--color-light-9);
                background-color: unset;
                border-radius: 0;
                padding: 0 15px 0 30px;
                text-align: left;
                font-weight: bold;
                color: var(--color-light-9);

                &:nth-child(3n+2) {
                    margin: 0;
                }
            }
        }
    }
}
</style>
