<template>
	<div class="paginate">
		<table class="paginate-table">
			<tr>
				<td class="page-button"
					:class="{ disabled: finalConfig.page == 1 }"
					@click="setPage(1)">首页</td>
				<td class="page-button"
					:class="{ disabled: finalConfig.page == 1 }"
					@click="setPage(finalConfig.page - 1)">上一页</td>
				<td v-for="page in pageList"
					:class="{ current: page == finalConfig.page }"
					@click="setPage(page)">{{ page==-1?'...':page }}</td>
				<td class="page-button"
					:class="{ disabled: finalConfig.page == maxPage }"
					@click="setPage(finalConfig.page + 1)">下一页</td>
				<td class="page-button"
					:class="{ disabled: finalConfig.page == maxPage }"
					@click="setPage(maxPage)">末页</td>
			</tr>
		</table>
		<div class="pagenate-info">
			第
			<input class="current-page-input"
				type="text"
				v-model="finalConfig.page"
				@change="fixedPage" />
			/ {{ maxPage }} 页，
			共 {{ finalConfig.total }} 条记录。
			每页
			<input class="page-size-input"
				type="text"
				v-model="finalConfig.pageSize"
				@change="fixedPageSize" /> 条记录。
		</div>
	</div>
</template>
<script>import { computed, toRefs } from "vue"

export default {
	props: {
		config: Object
	},
	// 	count: 4
	// page: 1
	// pageSize: 10
	// total: 4
	setup(props, context) {
		let { config } = toRefs(props),
			defaultConfig = {
				page: 1,
				pageSize: 30,
				total: 1,
			},
			finalConfig = config;
		finalConfig.value.page = finalConfig.value.page == undefined ? defaultConfig.page : finalConfig.value.page;
		finalConfig.value.pageSize = finalConfig.value.pageSize == undefined ? defaultConfig.pageSize : finalConfig.value.pageSize;
		finalConfig.value.total = finalConfig.value.total == undefined ? defaultConfig.total : finalConfig.value.total;
		let maxPage = computed(() => {
			var pageSize = finalConfig.value.pageSize;
			if (isNaN(pageSize) || pageSize <= 0) {
				finalConfig.value.pageSize = 1;
				pageSize = 1;
			}
			return Math.ceil((finalConfig.value.total <= 0 ? 1 : finalConfig.value.total) / pageSize);
		}),
			pageList = computed(() => {
				var ret = [];
				// 计算页码分段   当前页的上下两页
				let minMiddle = finalConfig.value.page - 2;
				let maxMiddle = finalConfig.value.page + 2;
				minMiddle = minMiddle <= 0 ? 1 : minMiddle;
				maxMiddle = maxMiddle >= maxPage.value ? maxPage.value : maxMiddle;
				// 为1时  直接循环添加     为2时  先加1 大于3时 先加1再加-1 然后顺序添加
				if (minMiddle == 2) {
					ret = [1];
				} else if (minMiddle >= 3) {
					ret = [1, -1];
				}
				for (let i = minMiddle; i <= maxMiddle; i++) {
					ret.push(i);
				}
				if (maxMiddle == maxPage.value - 1) {
					ret.push(maxPage.value);
				} else if (maxMiddle <= maxPage.value - 2) {
					ret.push(-1);
					ret.push(maxPage.value);
				}
				return ret;
			});
		function setPage(page) {
			if (!isNaN(page) && page <= maxPage.value && page > 0) {
				finalConfig.value.page = page;
				context.emit("on-page-changed", page);
			}
		}
		function fixedPage() {
			if (isNaN(finalConfig.value.page) || finalConfig.value.page == '' || finalConfig.value.page == undefined || finalConfig.value.page <= 0 || finalConfig.value.page >= maxPage.value) {
				finalConfig.value.page = 1;
			}
			config.page = finalConfig.value.page
			setPage(finalConfig.value.page);
		}
		function fixedPageSize() {
			if (isNaN(finalConfig.value.pageSize) || finalConfig.value.pageSize == '' || finalConfig.value.pageSize == undefined || finalConfig.value.pageSize <= 0 || finalConfig.value.pageSize >= 1000) {
				finalConfig.value.pageSize = 30;
			}
			config.pageSize = finalConfig.value.pageSize
			setPage(1);
		}
		return {
			maxPage,
			pageList,
			finalConfig,
			setPage, fixedPage, fixedPageSize,
		}
	}
}
</script>
<style lang="less">
.paginate {
	width: 100%;
	overflow: auto;
	height: 60px;
	font-size: 12px;

	&>.paginate-table {
		width: auto;
		min-width: unset;
		margin: 0 auto;
		max-width: 100%;
		height: 30px;
		// border-collapse: collapse;
		position: relative;
		border-collapse: separate;
		border-spacing: 0;

		&>tr {
			&>td {
				border: 1px solid #337ab7;
				border-left-width: 0;
				height: 30px;
				box-sizing: border-box;
				background-color: white;
				padding: 0px 10px;
				line-height: 28px;
				cursor: pointer;

				&:nth-child(1) {
					border-left-width: 1px;
				}

				&.disabled {
					background-color: #dfdfdf;
				}

				&.current {
					background-color: #337ab7;
					color: white;
				}

				&:nth-last-child(3) {
					border-right-width: 0px;
				}
			}

			&>.page-button {
				width: 50px;
				min-width: 50px;
				padding: 0px 4px;
				position: sticky;

				&:nth-child(1) {
					left: 0;
				}

				&:nth-child(2) {
					left: 50px;
				}

				&:nth-last-child(1) {
					right: 0;
				}

				&:nth-last-child(2) {
					right: 50px;
					border-left-width: 1px;
				}
			}
		}
	}

	&>.pagenate-info {
		height: 30px;
		line-height: 1.5;
		width: 100%;
		position: absolute;
		bottom: 0;
		padding: 0 10px;
		color: #646464;
		left: 0;
		font-size: 12px;
		text-align: center;

		.current-page-input,
		.page-size-input {
			width: 30px;
			font-size: 12px;
			text-align: center;
			outline: 0;
			border: 1px solid var(--color-light-6);
		}
	}
}
</style>