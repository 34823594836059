<template>
    <div class="demo-list-root">
            {{list}}
        <!-- <InputDemo v-model="t"></InputDemo> -->
        <List :columns="columns"
            :list="list"
            :paginate="paginate"
            :height="rowHeight"></List>
    </div>
</template>
<script setup>
import { ref } from 'vue';

const rowHeight = '34px';
const columns = [
    {
        value: 'text',
        display: '文本',
        size: '120px',
        len: '30',
        component: '',
    },
    {
        value: 'input',
        display: '可输入文本',
        size: '120px',
        len: '30',
        component: 'InputBox',
        placeholder: '请输入内容'
    },
];
const list = ref([
    {
        text: '演示文本',
        input: '演示输入',
    }
]);
const paginate = [];

</script>
<style lang="less" scoped>
.demo-list-root {
    width: 100%;
    height: 100%;
    padding: 8px;
}
</style>