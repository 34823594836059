<template>
    <div class="table-edit-root">
        <div class="buttons-contain">
            <div class="button"
                @click="saveRecode()">保存</div>
            <div class="button"
                v-show="id!=0"
                @click="getData()">取消修改</div>
            <!-- <div class="button"
                @click="deleteRecode()">删除</div> -->
        </div>
        <!-- <SkuSelector v-if="conf.data.id"
            :skuConfig="conf.data.sku_config"
            :storeList="conf.storeList"></SkuSelector> -->
        <template v-for="(field,index) in conf.fieldSchema"
            :key="index">
            <div v-if="field.value!='deleted_at'"
                class="row">
                <div class="col data-name">{{field.display}}</div>
                <div class="col table-cell-container text-etc">
                    <component :is="field.component"
                        :config="field.config"
                        :dictionary="field.dictionary"
                        :border="true"
                        :radius="true"
                        :placeholder="field.display"
                        :preg="field.preg"
                        :len="field.len"
                        :readonly="field.config.readonly"
                        :foreignValue="conf.data[`${field.value}Foreign`]"
                        @change="fieldChange(field,conf.data[field.value])"
                        v-model="conf.data[field.value]"></component>
                </div>
            </div>
        </template>
        <div class="storeList">
            <table>
                <tr>
                    <th>#</th>
                    <th>规格编号</th>
                    <th>规格名称</th>
                    <th>图片</th>
                    <th>价格</th>
                    <th>库存</th>
                    <th>运费</th>
                </tr>
                <tr v-for="(store,index) in conf.storeList">
                    <td>{{index+1}}</td>
                    <td>{{store.sku_code}}</td>
                    <td>
                        {{store.store_name}}
                    </td>
                    <td>
                        <MediaUpload v-model="store.cover"></MediaUpload>
                    </td>
                    <td>
                        <InputBox v-model="store.price"
                            :preg="/^[0-9]{1,8}(.[0-9]{1,2})?$/"
                            :placeholder="'请输入价格'"
                            :border="true"></InputBox>
                    </td>
                    <td>
                        <InputBox v-model="store.stock"
                            :preg="'/^[0-9]{1,10}$/'"
                            :placeholder="'请输入库存'"
                            @change="setStock"
                            :border="true"></InputBox>
                    </td>
                    <td>
                        <InputBox v-model="store.freight_basic"
                            :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                            :placeholder="'请输入运费'"
                            :border="true"></InputBox>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiMap } from "../../assets/js/apiMap";
const store = useStore()
const route = useRoute();
const tableName = 'mall_goods';
const id = route.params.id;
const conf = reactive({
    tableSchema: {},
    fieldSchema: [],
    pageInfo: {

    },
    queryCondtion: {
        keywords: '',
    },
    orderMethod: [],
    data: {},
    storeList: [],
});
const fieldChange = (field, value) => {
    console.log('参数变化');
    if (field.value == 'sku_config') {
        conf.storeList = storeInit(JSON.parse(JSON.stringify(value)))
        // top.window.sendMessage({
        //     type: 'warn',
        //     content: '商品规格修改后需要重新配置库存'
        // })
    }
}
const getData = async () => {
    store.dispatch({
        type: apiMap.MallGoodsSelect,
        data: { id: id }
    }
    ).then(res => {
        conf.data = res;
        store.dispatch({
            type: apiMap.MallGoodsStorePageList,
            params: {
                page: 1, pageSize: 1000, condition: { goods_id: id }, order: { id: 'asc' }
            }
        }).then(res => {
            conf.storeList = res.list
        })
    });
}
const storeInit = (data, result = []) => {
    let tempStoreList = data.shift();
    if (!tempStoreList) {
        return result;
    }
    const skuBasic = {
        'goods_id': '',
        'sku_code': 0,
        'store_name': [],
        'cover': '',
        'price': 0,
        'freight_basic': 0,
        'freight_append': 0,
        'freight_condition': 0,
        'stock': 0,
        'status': 1,
    };
    if (result.length < 1) {
        for (const index in tempStoreList.value) {
            if (Object.hasOwnProperty.call(tempStoreList.value, index)) {
                const val = tempStoreList.value[index];
                let arr = JSON.parse(JSON.stringify(skuBasic));
                arr.sku_code = arr.sku_code + (1 << val.code)
                arr.store_name.push(val.name)
                arr.price = conf.data.price_min
                result.push(arr);
            }
        }
        // console.log(result);
        return storeInit(data, result);
    }
    let newResult = [];

    for (const index in tempStoreList.value) {
        if (Object.hasOwnProperty.call(tempStoreList.value, index)) {
            const val = tempStoreList.value[index];
            for (const key in result) {
                if (Object.hasOwnProperty.call(result, key)) {
                    let arr = JSON.parse(JSON.stringify(result[key]));
                    arr.sku_code = arr.sku_code + (1 << val.code)
                    arr.store_name.push(val.name)
                    arr.price = conf.data.price_min
                    newResult.push(arr)
                }
            }
        }
    }
    return storeInit(data, newResult);
}
const setStock = () => {
    let stock = 0;
    for (let i = 0, l = conf.storeList.length; i < l; i++) {
        stock += Number(conf.storeList[i].stock);
    }
    conf.data.stock = stock;
}
const saveRecode = () => {
    // let url = `/admin/${tableName.split('_').join('/')}/${(id != 0 ? `update/${id}` : 'insert')}`
    let data = {};
    if (id == 0) {
        data = {
            ...conf.data,
            storeList: conf.storeList
        }
    } else {
        data = {
            id: id,
            data: { ...conf.data, storeList: conf.storeList }
        }
    }
    store.dispatch({
        type: id == 0 ? apiMap.MallGoodsInsert : apiMap.MallGoodsUpdate,
        data
    }
    ).then(res => {
        if (res.id) {
            top.window.sendMessage('保存成功');
            location.reload();
            return;
        }
        top.window.sendMessage({ type: 'error', content: '保存失败' });
        return;
        if (id == 0) {
            location.reload();
        }
        for (const key in res) {
            if (Object.hasOwnProperty.call(res, key)) {
                const element = res[key];
                conf.data[key] = element;
            }
        }
    });
}
// const deleteRecode=()=>{
//     store.dispatch({
//         type: 'request',
//         url: `/admin/${tableName.split('_').join('/')}/delete/${id}`,
//         method: 'delete',
//     }
//     ).then(res => {

//     });
// }
onMounted(async () => {
    await store.dispatch({
        type: apiMap.SysTableSelectBy,
        data: {
            key: 'value', value: tableName
        }
    }).then(res => {
        conf.tableSchema = res;
    });
    await store.dispatch({
        type: apiMap.SysTableFieldPageList,
        params: {
            page: 1, pageSize: 100, condition: {
                table_value: tableName,
                'value.in': [
                    'id',
                    'shop_id',
                    'shelves_id',
                    'name',
                    'cover',
                    'banner',
                    'images',
                    'sku_config',
                    'price_min',
                    'price_max',
                    'status',
                    'hit',
                    'sold',
                    'stock',
                ]
            }, order: { position: 'asc' }
        }
    }).then(res => {
        conf.fieldSchema = res.list
        for (let i = 0, l = conf.fieldSchema.length; i < l; i++) {
            let field = conf.fieldSchema[i];
            if (field.type == 'json') {
                conf.data[field.value] = Object(JSON.parse(field.default));
                continue;
            }
            conf.data[field.value] = field.default;
            if (field.component == 'ForeignKey') {
                conf.data[`${field.value}Foreign`] = {}
                conf.data[`${field.value}Foreign`][field.config.foreignField] = '';
                conf.data[`${field.value}Foreign`][field.config.foreignDisplayField] = '';
            }
        }
    });
    if (id != 0) {
        await getData();
    }
})
</script>
<style lang="less" scoped>
.table-edit-root {
    width: 100%;
    padding: 10px 30px;
    position: relative;
    padding-top: 40px;

    .buttons-contain {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: var(--index-top);
        padding: 0 10px;
        background-color: var(--color-light-11);
    }

    // border: 1px solid;
    .row {
        border-bottom: 1px solid var(--color-light-9);
        display: flex;
        align-items: center;

        .col {
            border: none;

            &.table-cell-container {
                text-align: left;
                padding: 10px 15px 10px 10px;
                width: 500px;
                overflow: unset;

                .input-box-root {
                    margin: 20px 0;
                }
            }

            &.data-name {
                text-align: right;
                font-weight: bold;
                font-size: 14px;
                width: 150px;
                padding: 0px 20px 0 10px;
            }
        }
    }

}
</style>