<template>
    <div class="refund-info-root">
        <div class="row">
            <div class="col">申请退款金额</div>
            <div class="col">
                <InputBox class="money"
                    v-model="refund.request_amount"
                    :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                    :type="'money'"
                    :readonly="true"></InputBox>
            </div>
        </div>
        <div class="row">
            <div class="col">同意退款金额</div>
            <div class="col">
                <InputBox class="money"
                    v-model="refund.response_amount"
                    :border="refund.status==0"
                    :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                    :readonly="refund.status!=0"></InputBox>
            </div>
        </div>
        <div class="row">
            <div class="col">商家回复</div>
            <div class="col">
                <InputBox class="money"
                    v-model="refund.remarks"
                    :placeholder="'请输入回复内容'"
                    :border="refund.status==0"
                    :preg="'/^.{0-255}$/'"
                    :readonly="refund.status!=0"></InputBox>
            </div>
        </div>
        <div class="row">
            <div class="col">退款状态</div>
            <div class="col"
                v-if="conf.refundStatus&&conf.refundStatus.mapper">
                {{conf.refundStatus.mapper[refund.status]}}
            </div>
        </div>
        <div class="row"
            v-if="refund.status==0">
            <div class="button-group">
                <div class="button"
                    @click="refundHandle(1)">同意退款</div>
                <div class="button"
                    @click="refundHandle(3)">拒绝退款</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    refund: Object,
    refundGoods: Array
})
const store = useStore();
const conf = reactive({
    refundStatus: {},
})
onMounted(async () => {
    await top.window.handle.getDictionary('refund_status').then(
        res => {
            conf.refundStatus = res ? res : {};
        }
    )
});
// 退款处理
const refundHandle = (e) => {
    let data = {
        refundId: props.refund.id,
        amount: props.refund.response_amount,
        remarks: props.refund.remarks,
        status: e
    };
    if (data.status == 3 && !data.remarks) {
        top.window.sendMessage({
            type: 'warn',
            content: '请输入拒绝原因'
        })
        return;
    }
    if (data.status == 1 && data.amount <= 0) {
        top.window.sendMessage({
            type: 'warn',
            content: '退款金额不能为0'
        })
        return;
    }
    if (data.status == 1 && data.amount > props.refund.request_amount) {
        top.window.sendMessage({
            type: 'warn',
            content: '退款金额不能超过申请金额'
        })
        return;
    }
    data.remarks = data.remarks ? data.remarks : '同意退款';
    store.dispatch({
        type: 'request',
        url: '/api/order/after_sold/refundHandle',
        data,
        method: 'POST'
    }).then(res => {
        if (res) {
            location.reload();
        }
    })
}
</script>

<style lang="less" scoped>
.refund-info-root {
    width: 100%;

    .row {
        display: flex;
        align-items: center;

        .button-group {
            display: flex;
            padding: 10px 0;
        }

        .col {
            border: none;
            text-align: left;
            min-height: 30px;
            padding: 10px;

            &>.input-box-root {
                margin: 0;
            }

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
            }

            display: flex;
            align-items: center;

            &:nth-child(1) {
                padding: 0 10px;
                text-align: right;
                width: 140px;
                height: unset;
                align-items: center;
                justify-content: flex-end;
                font-weight: bold;
            }
        }
    }
}
</style>