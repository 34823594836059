<template>
    <div class="page-label-bar">
        <!-- <div :class="['page-label-item', { active: -1 == pageInfo.activeLabelIndex }]"
            @click="selectLabell(-1)">
            <span>首页</span>
        </div> -->
        <div v-for="(item, index) in pageInfo.pageList"
            :class="['page-label-item', { 'active': index == pageInfo.activeLabelIndex }]"
            :key="index"
            @click="selectLabell(index)">
            <span>{{ item.name }}</span>
            <div v-if="!item.static"
                class="page-label-item-close-icon"
                @click.stop="removeLabel(index)">
                <span class="iconfont icon-close"></span>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from 'vue';
const props = defineProps([
    'pageInfo'
]);
const emit = defineEmits([
    'pageSelect',
])
const selectLabell = (index) => {
    emit('pageSelect', index);
}
const removeLabel = index => {
    props.pageInfo.pageList.splice(index, 1);
    if (props.pageInfo.pageList.length - 1 < props.pageInfo.activeLabelIndex) {
        props.pageInfo.activeLabelIndex = props.pageInfo.pageList.length - 1;
    } else if (props.pageInfo.activeLabelIndex == index) {
        props.pageInfo.activeLabelIndex = 0
    }
}
</script>
<style lang="less" scoped>
.page-label-bar {
    width: 100%;
    height: var(--line-height-5);
    background-color: var(--color-theme-light-8);
    position: absolute;
    left: 0;
    top: var(--line-height-1);
    padding: 4px 12px;
    display: flex;
    align-items: center;
    overflow: auto;
    transition: var(--animate-transition);

    .page-label-item {
        background-color: var(--color-theme-light-1);
        color: var(--color-light-10);
        padding: 0 30px 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px 0 0;
        position: relative;
        height: var(--line-height-6);
        border-radius: 8px;
        font-size: var(--font-size-2);
        border: 1px solid var(--color-theme-light-1);
        white-space: nowrap;
        cursor: pointer;

        &:nth-child(1) {
            padding-right: 10px;
        }

        .page-label-item-close-icon {
            position: absolute;
            right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 13px;
            height: 13px;
            font-size: var(--font-size-1);
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
            font-weight: 100;
            padding: 1px 0 0;

            &:hover {
                background-color: var(--color-light-10);
                color: var(--color-theme-light-1);
            }
        }

        &.active {
            background-color: var(--color-light-10);
            color: var(--color-theme-light-1);

            .page-label-item-close-icon {
                &:hover {
                    color: var(--color-light-10);
                    background-color: var(--color-theme-light-1);
                }
            }
        }

    }
}
</style>
