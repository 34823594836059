/** @format */

export default {
	userInfo: {
		token: '',
		profile: null,
	},
	dictionary: {},
	count: 1,
	todos: [
		{ id: 1, text: '...', done: true },
		{ id: 2, text: '...', done: false },
	],
	message: [],
	system: {
		config: {
			displayFooter: true,
		},
	},
	publicInfo: {
		pageTitle: 'GETCH-后台管理系统',
		email: '1160076559@qq.com',
		beian: '黔ICP备18000000号',
		right: 'Copyright © 2000-2022 chaojikaoyan.com Inc. All rights reserved.',
	},
	config: {
		serverPath: 'http://admin.cjky.com/',
		menuHidden: false,
		userInfoBoxHidden: true,
		pageLoading: false,
		pageLoadingText: '',
	},
};
