<template>
    <div class="mine-account-root">
        <div class="user-info-panel-container">

            <Panel :title="'个人信息'"
                :border="true">
                <PersonalInfo>
                </PersonalInfo>
            </Panel>
        </div>
        <div class="user-withdraw-container">
            <Panel :title="'提现'"
                :border="true">
                <div class="withdraw-box">
                    <div class="withdraw">
                        <div class="row">
                            <div class="title">提现金额</div>
                            <div class="value">
                                <InputBox v-model="data.withdrawAmount"
                                    :len="9"
                                    :preg="'/^[0-9]{1,8}(.[0-9]{1,2})?$/'"
                                    :border="true"></InputBox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="title">支付密码</div>
                            <div class="value">
                                <InputBox v-model="data.payPassword"
                                    :len="6"
                                    :type="'password'"
                                    :border="true"></InputBox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="button"
                                @click="withdraw">申请提现</div>
                        </div>
                    </div>
                    <div class="withdraw-history">

                        <table class="getch-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>编号</th>
                                    <th>金额</th>
                                    <th>状态</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="data.withdrawList.length==0">
                                    <td colspan="40">
                                        暂无记录
                                    </td>
                                </tr>
                                <tr v-else
                                    v-for="(item,index) in data.withdrawList"
                                    :key="index">
                                    <td>{{index+1}}</td>
                                    <td>{{item.id}}</td>
                                    <td>
                                        <InputBox v-model="item.amount"
                                            :readonly="true"
                                            :type="'money'"></InputBox>
                                    </td>
                                    <td>
                                        <Label :text="data.dictionary.withdraw_status.mapper[item.status]"></Label>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Panel>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../assets/js/apiMap';
const store = useStore();
const data = reactive({
    dictionary: {
        withdraw_status: { mapper: [] }
    },
    withdrawAmount: 0,
    payPassword: '',
    onWatting: false,
    withdrawList: [],
})
const withdraw = () => {
    if (data.onWatting) {
        top.window.sendMessage({
            type: 'warn',
            content: '操作频繁'
        });
        return;
    }
    if (data.withdrawAmount <= 0) {
        top.window.sendMessage({
            type: 'warn',
            content: '提现金额必须大于0'
        });
        return
    }
    if (data.payPassword.length != 6) {
        top.window.sendMessage({
            type: 'warn',
            content: '请输入支付密码'
        });
        return
    }
    data.onWatting = true;
    store.dispatch({
        type: 'request',
        method: 'post',
        url: '/api/user/finace/withdraw',
        data: {
            amount: data.withdrawAmount,
            method: data.payPassword,
            password: 0,
        }
    }).then(res => {
        data.onWatting = false;
        if (rse == true) {
            top.window.sendMessage({
                type: 'success',
                content: '提现成功，请等待管理员审核'
            });
            location.reload();
        } else {
            top.window.sendMessage({
                type: 'error',
                content: '提现失败'
            });
        }
    })
}

onMounted(async () => {
    await top.window.handle.getDictionary('withdraw_status').then(async res => {
        data.dictionary.withdraw_status = res;
    });
    await store.dispatch(
        {
            type: apiMap.UserFinaceWithdrawPageList,
            params: {
                page: 1, pageSize: 5, order: { created_at: 'desc' }
            }
        }
    ).then(res => {
        if (res && res.list) {
            data.withdrawList = res.list
        }
    })
})
</script>
<style lang="less" scoped>
.mine-account-root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .user-info-panel-container {
        width: 300px;
        height: 370px;
    }

    .user-withdraw-container {
        width: calc(100% - 300px);
        height: 370px;

        .withdraw-box {
            width: 100%;
            height: 100%;
            display: flex;

            .withdraw {
                width: 300px;
                border-right: 1px solid;
                padding: 0 15px;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                ::v-deep .panel-root {
                    height: 100%;
                }

                .row {
                    width: 100%;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    padding-left: 100px;
                    position: relative;

                    .title {
                        font-weight: bold;
                        width: 100px;
                        text-align: right;
                        position: absolute;
                        left: 0;
                        padding: 0 10px;
                    }

                    .value {
                        width: 100%;
                    }
                }
            }

            .withdraw-history {
                width: calc(100% - 300px);
                padding: 0 15px;
            }
        }
    }
}
</style>