export default {
            MallOrderAddressInsert: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/address/insert',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressInsertMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/address/insertMulti',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressUpdate: ({ dispatch, commit, state }, payload) => {
                let id=payload.data.id;delete payload.data.id;
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/address/update/${id}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressUpdateMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/address/updateMulti',
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressSelect: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/address/select/${payload.data.id}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressSelectBy: ({ dispatch, commit, state }, payload) => {
                
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/address/selectBy/${payload.data.key}/${payload.data.value}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressPageList: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/address/pageList',
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressTrash: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/address/trash/${payload.data.ids}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressRecovery: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/address/recovery/${payload.data.ids}`,
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderAddressDelete: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/address/delete/${payload.data.ids}`,
                    method: 'delete',
                    data: payload.data,
                    params: payload.params,
                });
            }
        };