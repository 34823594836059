<template>
    <div class="media-container-root"
        :style="{
            width:`${size*100}%`,
            height:`${size*100}%`,
            left:`${(1-size)*100/2}%`,
            top:`${(1-size)*100/2}%`,

        }">
        <video class="resource"
            v-if="datas.resourceType=='video'"
            :src="datas.resourceUri" controls></video>
        <img class="resource"
            v-else-if="datas.resourceType=='image'"
            :src="datas.resourceUri"
            alt="">
        <span class="resource"
            v-else-if="datas.resourceType=='unknown'">未知资源</span>
    </div>
</template>
<script setup>
import { onMounted, reactive, watch } from 'vue';

const props = defineProps({
    resource: {},
    size: { type: Number, default: 1 }
})
const datas = reactive({
    resourceUri: '',
    resourceType: ''
})
const initVlaue = () => {
    datas.resourceUri = setResourceUri();
    datas.resourceType = setResourceType();
}
const setResourceUri = () => {
    if (!props || !props.resource) {
        return 'none';
    }
    if (typeof props.resource == 'object') {
        return props.resource.url;
    }
    return props.resource;
}
const setResourceType = () => {
    let ext = datas.resourceUri.split('.').pop();
    if (ext == "mp4" || ext == "rmvb" || ext == "avi" || ext == "ts") {
        return 'video';
    } else if (!ext||ext==''||ext == "bmp" || ext == "png" || ext == "gif" || ext == "jpg" || ext == "jpeg") {
        return 'image';
    } else {
        return 'image';
    }
}

watch(() => props.resource, () => {
    initVlaue();
})
onMounted(async () => {
    initVlaue();
});
</script>
<style lang="less" scoped>
.media-container-root {
    // width: 100%;
    // height: 100%;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .resource {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>