<template>
    <div class="settle-report-root">
        <div class="summary">
            <div class="year">
                选择统计类型
                <select style="width: 100px; height: 30px;"
                    name="summary-select"
                    v-model="data.search.display"
                    @change="updateSearch()">
                    <option v-for="(item, index) in data.summaryOptions"
                        :key="index"
                        :value="item.display">{{ item.display }}</option>
                </select>
                <table>
                    <tr>
                        <td>#</td>
                        <th>{{ data.search.head }}</th>
                        <th>平台总流水</th>
                        <th>平台收入</th>
                    </tr>
                    <tr v-for="(item, index) in data.summary"
                        :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.key }}</td>
                        <td>{{ item.total }}</td>
                        <td class="danger">{{ item.income }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="detail">
            <h3 style="padding: 10px 0;text-align:center">详情</h3>
            <table>
                <tr>
                    <td>#</td>
                    <th>结算总金额</th>
                    <th>平台收入</th>
                    <th>店铺编号</th>
                    <th>店铺名称</th>
                    <th>结算订单</th>
                    <th>结算详情</th>
                    <th>结算时间</th>
                </tr>
                <tr v-for="(item, index) in data.list"
                    :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.amount_total }}</td>
                    <td>{{ item.amount_platform }}</td>
                    <td>{{ item.shop_id }}</td>
                    <td>
                        <div class="shop-logo text-etc">
                            <img :src="item.shop_logo"
                                alt=""> <span>{{ item.shop_name }}</span>
                        </div>
                    </td>
                    <td>{{ item.order_count }}</td>
                    <td>{{ item.remarks }}</td>
                    <td>{{ item.created_date }}</td>
                </tr>
            </table>
            <div class="footer-area">
                <Paginate :config="data.pageInfo"
                    @on-page-changed="getDataList"></Paginate>
            </div>
        </div>
        <!-- 总收益 -->
        <!-- 年统计 最近三年 自主选择年份 -->
        <!-- 月统计 最近6月 自主选择月份 -->
        <!-- 日统计 最近7天 自主选择天数 -->
        <!-- 结算明细 -->
    </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const data = reactive({
    startDate: "",
    endDate: "",
    key: '%Y-%m-%d',
    pageInfo: {
        page: 1,
        pageSize: 20,
        total: 1,
        count: 1,
    },
    search: {
        display: "按年",
        key: "%Y",
        head: '年份',
        start: 0,
        end: 9999999999,
    },
    list: [],
    summaryOptions: [
        {
            display: "按年",
            key: "%Y",
            head: '年份',
            start: 0,
            end: 9999999999,
        },
        {
            display: "按月",
            key: "%Y-%m",
            head: '月份',
            start: 0,
            end: 9999999999,
        },
        {
            display: "按日",
            key: "%Y-%m-%d",
            head: '日期',
            start: 0,
            end: 9999999999,
        },
    ],
    summary: [],
});

const updateSearch = () => {
    if (data.search.display == '按年') {
        data.search.key = "%Y";
        data.search.head = "近10年";
    } else if (data.search.display == '按月') {
        data.search.key = "%Y-%m";
        data.search.head = "近10月";
    } else if (data.search.display == '按日') {
        data.search.key = "%Y-%m-%d";
        data.search.head = "近10天";
    } else { return }
    getData();
}
const getDataList = async () => {
    data.list = [];
    const res = await store.dispatch({
        type: "request",
        method: 'get',
        url: '/admin/mall/order/settlList',
        params: {
            page: data.pageInfo.page,
            pageSize: data.pageInfo.pageSize
        }
    });
    data.list = res;
}
const getData = async () => {
    data.summary = [];
    const res = await store.dispatch({
        type: "request",
        method: 'get',
        url: '/admin/mall/order/settle',
        params: {
            key: data.search.key,
            start: data.search.start,
            end: data.search.end,
        }
    });
    data.summary = res;
}

onMounted(() => {
    getData();
    getDataList();
});
</script>
<style lang="less" scoped>
.shop-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    &>img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #f2f2f2;
        margin-right: 10px;
    }
}

.footer-area {
    position: relative;
    height: 80px;
    padding: 10px;
}
</style>
