<template>
    <div v-if="readonly" class="cell-date table-cell readonly"
        type="datetime">
        {{ datas.displayDate }}
    </div>
    <div v-else class="cell-date table-cell"
        type="datetime">
        <div class="date-display"
            @click="selectDate">{{ datas.displayDate }}</div>
        <div 
            class="date-selector-contain"
            v-slide-vertical="datas.isOpen"
            @click="selectDate">
            <div class="date-selector-box"
                @click.stop="">
                <div class="date-selector-header">
                    <div class="date-selector-head-item double-width"
                        data-unit="year">
                        年
                    </div>
                    <div class="date-selector-head-item"
                        data-unit="month">月</div>
                    <div class="date-selector-head-item"
                        data-unit="day">日</div>
                    <div class="date-selector-head-item"
                        data-unit="hour">时</div>
                    <div class="date-selector-head-item"
                        data-unit="min">分</div>
                    <div class="date-selector-head-item"
                        data-unit="sec">秒</div>
                </div>
                <div class="date-selector-scroller">
                    <div class="bg-top"></div>
                    <div class="bg-bottom"></div>
                    <ul class="date-item-scroll double-width scroll scroll-all scroll-no-bar"
                        v-scroll-listenner="{ func: scrollListen, data: 'Y' }">
                        <li v-custom-event="{
                            func: elementScrollIntoView,
                            data: datas.year,
                        }"
                            v-for="val in datas.yearList"
                            :class="['date-item', { active: val == datas.year }]"
                            @click="setyear(val)">
                            {{ val }}
                        </li>
                    </ul>
                    <ul class="date-item-scroll scroll scroll-all scroll-no-bar"
                        v-scroll-listenner="{ func: scrollListen, data: 'm' }">
                        <li v-custom-event="{
                            func: elementScrollIntoView,
                            data: datas.month,
                        }"
                            v-for="val in datas.unit12"
                            :class="['date-item', { active: val == datas.month }]"
                            @click="setmonth(val)"
                            :data-val="`_${val}`">
                            {{ val }}
                        </li>
                    </ul>
                    <ul class="date-item-scroll scroll scroll-all scroll-no-bar"
                        v-scroll-listenner="{ func: scrollListen, data: 'd' }">
                        <li v-custom-event="{
                            func: elementScrollIntoView,
                            data: datas.day,
                        }"
                            v-for="val in datas.dayList"
                            :class="['date-item', { active: val == datas.day }]"
                            @click="setday(val)"
                            :data-val="`_${val}`">
                            {{ val }}
                        </li>
                    </ul>
                    <ul class="date-item-scroll scroll scroll-all scroll-no-bar"
                        v-scroll-listenner="{ func: scrollListen, data: 'H' }">
                        <li v-custom-event="{
                            func: elementScrollIntoView,
                            data: datas.hour,
                        }"
                            v-for="val in datas.unit24"
                            :class="['date-item', { active: val == datas.hour }]"
                            @click="sethour(val)"
                            :data-val="`_${val}`">
                            {{ val }}
                        </li>
                    </ul>
                    <ul class="date-item-scroll scroll scroll-all scroll-no-bar"
                        v-scroll-listenner="{ func: scrollListen, data: 'i' }">
                        <li v-custom-event="{
                            func: elementScrollIntoView,
                            data: datas.minute,
                        }"
                            v-for="val in datas.unit60"
                            :class="['date-item', { active: val == datas.minute }]"
                            @click="setminute(val)"
                            :data-val="`_${val}`">
                            {{ val }}
                        </li>
                    </ul>
                    <ul class="date-item-scroll scroll scroll-all scroll-no-bar"
                        v-scroll-listenner="{ func: scrollListen, data: 's' }">
                        <li v-custom-event="{
                            func: elementScrollIntoView,
                            data: datas.second,
                        }"
                            v-for="val in datas.unit60"
                            :class="['date-item', { active: val == datas.second }]"
                            @click="setsecond(val)"
                            :data-val="`_${val}`">
                            {{ val }}
                        </li>
                    </ul>
                </div>
                <div class="date-selector-tools">
                    <div class="button"
                        @click="setNow()">现在</div>
                    <div class="button"
                        @click="selectDate()">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
/*
    时间组件说明
        时间的显示值根据计算得到，因此不需要配置
        当选择数据改变时，重新计算新的时间日期
        初始化的时，设置ymd
        value改变时，设置ymd
        ymd改变时，重新设置value  可能造成死锁，需要增加一个防死锁的标记
 */
import { computed, nextTick, reactive, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
const vScrollListenner = {
    /**
     *
     * @param {Element} el
     * @param {*} binding
     * @param {*} vnode
     */
    beforeMount(el, binding, vnode) {
        // 通过滚动选择的场景
        // 手机滑动
        // 鼠标滚动
        // 必须是手动操作过后才能改变参数
        // 当前dom和用户发生交互行为后，添加标记
        // 数据改变后，取消标记
        let timeout = null,
            touchLocked = false,
            scrolling = false;
        el.addEventListener("scroll", () => {
            // 标记页面正在滚动
            scrolling = true;
            // 针对电脑:鼠标滚动停止0.05秒后开始处理数据
            // 针对触屏设备:触摸结束且滚动结束后后开始处理数据
            clearTimeout(timeout); // 重新开始计时
            timeout = setTimeout(() => {
                scrolling = false;
                if (touchLocked) {
                    // 页面停止滚动，但还在触摸锁定状态
                    return;
                }
                binding.value.func(el, vnode, binding.value.data);
            }, 50);
            // return false;
        });
        el.addEventListener("touchend", () => {
            // console.log("触摸结束")
            // 松手后可能还在继续滚动
            touchLocked = false;
            if (!scrolling) {
                binding.value.func(el, vnode, binding.value.data);
            }
        });
        el.addEventListener(
            "touchstart",
            () => {
                // console.log("触摸开始")
                touchLocked = true;
            },
            { passive: true }
        );
    },
    /**
     * @param {Element} el
     * @param {*} binding
     * @param {*} vnode
     */
    mounted(el, binding, vnode) {
        // console.log("页面渲染完成");
        // if (typeof binding.value.init == 'function') {
        // 	binding.value.init(el, binding.value.data);
        // }
        // setTimeout(() => {
        // }, 10);
    },
};
const vCustomEvent = {
    /**
     * @param {Element} el
     * @param {*} binding
     */
    beforeMount(el, binding) {
        binding.value.func(el, binding.value.data);
    },
    // mounted (el, binding) { binding.value.func(el, binding.value.data) },
    updated(el, binding) {
        binding.value.func(el, binding.value.data);
    },
};
const store = useStore();
const props = defineProps({
    modelValue: [String, Number],
    readonly: Boolean,
    cell: Object,
});
const emit = defineEmits(['update:modelValue']);
const { readonly, cell, modelValue } = toRefs(props);
const datas = reactive({
    originData: modelValue.value,
    isOpen: false,
    year: "0000",
    month: "00",
    day: "00",
    hour: "00",
    minute: "00",
    second: "00",
    unit60: [],
    unit12: [],
    unit24: [],
    dayList: computed(() => {
        var ret = [];
        let maxDay = getLastDay(
            datas.year,
            parseInt(datas.month) - 1
        );
        for (let i = 1; i <= maxDay; i++) {
            ret.push(i < 10 ? `0${i}` : i + "");
        }
        if (datas.day > maxDay) {
            datas.day = maxDay;
        }
        return ret;
    }),
    yearList: [],
    displayDate: computed(() => {
        if (datas.originData == '' || isNaN(datas.originData) || datas.originData <= 0) {
            setNow();
        }
        return computeDisplayDate(datas.originData);
    }),
})
const configFixed = () => {
    // cell.value=cell.value==undefined?{value:"0"}:cell.value;
    // 初始化选择列表
    for (let i = 0; i < 60; i++) {
        var val = i < 10 ? `0${i}` : i + "";
        datas.unit60.push(val);
        i < 13 && i > 0 && datas.unit12.push(val);
        i < 24 && datas.unit24.push(val);
    }
    // setYearList();
}
const computeDisplayDate = (timestrap) => {
    // 重新设置ymd
    let date = new Date(timestrap * 1000);
    datas.year = date.getFullYear();
    datas.month = date.getMonth() + 1;
    datas.month = datas.month < 10 ? `0${datas.month}` : String(datas.month);
    datas.day = date.getDate();
    datas.day = datas.day < 10 ? `0${datas.day}` : String(datas.day);
    datas.hour = date.getHours();
    datas.hour = datas.hour < 10 ? `0${datas.hour}` : String(datas.hour);
    datas.minute = date.getMinutes();
    datas.minute = datas.minute < 10 ? `0${datas.minute}` : String(datas.minute);
    datas.second = date.getSeconds();
    datas.second = datas.second < 10 ? `0${datas.second}` : String(datas.second);
    // 初始化年份选择列表
    setYearList();
    emit('update:modelValue', Math.ceil(datas.originData));
    return buildDatetimeStr();
}
const setYearList = () => {
    // 初始化时重置
    // 时间戳改变时重置
    // 选择到当前首末年时添加
    let currentYear = parseInt(datas.year),
        n = 0,
        yearStart = 0,
        isPush = true,
        yearListLen = datas.yearList.length;
    // 年份默认显示当前年份的上下5年，当年份栏拖到顶部或底部时，自动添加前三年或后三年
    if (yearListLen == 0) {
        // 重置年份列表
        yearStart = currentYear - 5;
        n = currentYear + 5;
    } else if (currentYear <= datas.yearList[0]) {
        yearStart = currentYear - 3;
        n = datas.yearList[0] - 1;
        isPush = false;
    } else if (currentYear >= datas.yearList[yearListLen - 1]) {
        yearStart = datas.yearList[yearListLen - 1] + 1;
        n = currentYear + 3;
    } else {
        return;
    }
    yearStart = yearStart < 1970 ? 1970 : yearStart;
    n = n > 2285 ? 2285 : n;
    isPush
        ? pushYear(yearStart, n)
        : unshiftYear(yearStart, n);
}
const pushYear = (start, end) => {
    for (let i = start; i < end; i++) {
        datas.yearList.push(i);
    }
}
const unshiftYear = (start, end) => {
    for (let i = end; i >= start; i--) {
        datas.yearList.unshift(i);
    }
}
const buildDatetimeStr = () => {
    return `${datas.year}-${datas.month}-${datas.day} ${datas.hour}:${datas.minute}:${datas.second}`;
}
const getLastDay = (year, month) => {
    var monthEndDate = new Date(year, month + 1, 0);
    return monthEndDate.getDate();
}
/**
 * @param {Element} el
 */
const scrollListen = (el, vnode, tag, callback = null) => {
    if (el.offsetParent === null) {
        return;
    }
    // 只有在触摸状态或鼠标滚动状态才会修改参数
    let scrollTop = el.scrollTop;
    let scrollBoxSize = (scrollTop / 30).toFixed(0);
    switch (tag) {
        case "Y":
            if (datas.year == datas.yearList[scrollBoxSize]) {
                scrollAnim(
                    el.getElementsByTagName("li")[scrollBoxSize],
                    30 * scrollBoxSize
                );
            } else {
                if (
                    datas.yearList[scrollBoxSize] == 1970 &&
                    parseInt(datas.month) == 1 &&
                    parseInt(datas.day) == 1 &&
                    parseInt(datas.hour) < 8
                ) {
                    datas.hour = 8;
                }
                datas.year = datas.yearList[scrollBoxSize];
            }
            break;
        case "m":
            if (datas.month == datas.unit12[scrollBoxSize]) {
                scrollAnim(
                    el.getElementsByTagName("li")[scrollBoxSize],
                    30 * scrollBoxSize
                );
            } else {
                if (
                    parseInt(datas.year) == 1970 &&
                    datas.unit12[scrollBoxSize] == 1 &&
                    parseInt(datas.day) == 1 &&
                    parseInt(datas.hour) < 8
                ) {
                    // console.log("到达最小日期");
                    datas.hour = 8;
                }
                datas.month = datas.unit12[scrollBoxSize];
            }
            break;
        case "d":
            if (datas.day == datas.dayList[scrollBoxSize]) {
                scrollAnim(
                    el.getElementsByTagName("li")[scrollBoxSize],
                    30 * scrollBoxSize
                );
            } else {
                if (
                    parseInt(datas.year) == 1970 &&
                    parseInt(datas.month) == 1 &&
                    datas.dayList[scrollBoxSize] == 1 &&
                    parseInt(datas.hour) < 8
                ) {
                    // console.log("到达最小日期");
                    datas.hour = 8;
                }
                datas.day = datas.dayList[scrollBoxSize];
            }
            break;
        case "H":
            if (datas.hour == datas.unit24[scrollBoxSize]) {
                scrollAnim(
                    el.getElementsByTagName("li")[scrollBoxSize],
                    30 * scrollBoxSize
                );
            } else {
                if (
                    parseInt(datas.year) == 1970 &&
                    parseInt(datas.month) == 1 &&
                    parseInt(datas.day) == 1 &&
                    datas.unit24[scrollBoxSize] < 8
                ) {
                    // console.log("到达最小日期");
                    datas.hour = 8;
                } else {
                    datas.hour = datas.unit24[scrollBoxSize];
                }
            }
            break;
        case "i":
            if (datas.minute == datas.unit60[scrollBoxSize]) {
                scrollAnim(
                    el.getElementsByTagName("li")[scrollBoxSize],
                    30 * scrollBoxSize
                );
            } else {
                datas.minute = datas.unit60[scrollBoxSize];
            }
            break;
        case "s":
            if (datas.second == datas.unit60[scrollBoxSize]) {
                scrollAnim(
                    el.getElementsByTagName("li")[scrollBoxSize],
                    30 * scrollBoxSize
                );
            } else {
                datas.second = datas.unit60[scrollBoxSize];
            }
            break;
    }
    // 如果值发生改变，会自动监听变化
    // 如果未发生改变，需要手动修改滚动值
    updateTimestrap();
}
const updateTimestrap = () => {
    if (
        parseInt(datas.year) == 1970 &&
        parseInt(datas.month) == 1 &&
        parseInt(datas.day) == 1 &&
        datas.hour < 8
    ) {
        datas.hour = 8;
    }
    datas.originData = Math.floor(
        new Date(buildDatetimeStr()).getTime() / 1000
    );
}
/**
 * @param {Element} el
 */
const elementScrollIntoView = (el, data) => {
    if (el.classList.value.indexOf("active") > -1) {
        nextTick(() => {
            if (el.offsetTop < 60) {
                return;
            }
            let aim = el.offsetTop - 60,
                distance = aim - el.parentNode.scrollTop;
            scrollAnim(el, aim);
        });
    }
}
watch(() => props.modelValue, () => {
    initValue();
})
const initValue = () => {
    datas.originData = props.modelValue;
}
const scrollAnim = (el, aim) => {
    let currentDistance = el.parentNode.scrollTop - aim;
    if (el.offsetParent === null) {
        return;
    }
    if (currentDistance == 0) {
        // console.log("滚动停止",el);
        return;
    } else if (Math.abs(currentDistance) > 60) {
        el.parentNode.scrollTop = aim;
        return;
    }
    // console.log(`滚动到${el.parentNode.scrollTop}/${aim}`);
    let step = currentDistance > 0 ? -1 : 1;
    el.parentNode.scrollTop = Math.floor(el.parentNode.scrollTop + step);
    setTimeout(() => {
        scrollAnim(el, aim);
    }, 0);
}
const selectDate = () => {
    if (readonly.value) {
        return;
    }
    datas.isOpen = !datas.isOpen;
    if (datas.isOpen) {
        if (typeof top.window.closeOpenningDrop == "function") {
            top.window.closeOpenningDrop();
        }
        top.window.closeOpenningDrop = () => {
            datas.isOpen = false;
        };
    } else {
        delete top.window.closeOpenningDrop;
    }
}
const setyear = (val) => {
    datas.year = val;
    updateTimestrap();
}
const setmonth = (val) => {
    datas.month = val;
    updateTimestrap();
}
const setday = (val) => {
    datas.day = val;
    updateTimestrap();
}
const sethour = (val) => {
    datas.hour = val;
    updateTimestrap();
}
const setminute = (val) => {
    datas.minute = val;
    updateTimestrap();
}
const setsecond = (val) => {
    datas.second = val;
    updateTimestrap();
}
const setNow = () => {
    datas.originData = Math.floor(new Date().getTime() / 1000);
}
configFixed();
</script>
<style lang="less" scoped>
@import url("~@/assets/less/global-config/config.less");
// 时间日期
@colWidth: 15%;
@doubleColWidth: 25%;
@boxHeight: 210px;
@cellDateMinWidth: 360px;

.cell-date {
    min-width: @cellDateMinWidth;
    width: @cellDateMinWidth;
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    border: 1px solid var(--color-theme-light-1);
    &.readonly{
        width: unset;
        min-width: unset;
        border: none;
    }

    &:hover {
        background-color: #e6fbff;
    }

    .date-display {
        height: 100%;
        width: 100%;
        line-height: 30px;
        padding: 0 10px;
    }

    .date-selector-contain {
        z-index: 100;
        transition: height ease 0.3s;
        overflow: hidden;
        position: absolute;
        top: calc(100% + 2px);
        width: 234px;

        .date-selector-box {
            position: relative;
            left: 0;
            bottom: calc(@boxHeight - 100%);
            background-color: rgba(0, 0, 0, 0.5);
            width: 234px;
            height: @boxHeight;
            border: 1px solid #337ad7;
            background-color: white;
            padding-top: 30px;
            padding-bottom: 30px;

            .date-selector-header {
                height: 30px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-bottom: 1px solid #337ad7;
                // display: flex;
                line-height: 29px;
                background-color: #8dbeff;
                color: white;

                .date-selector-head-item {
                    float: left;
                    padding: 0 10px;
                    text-align: center;
                    width: @colWidth;
                    height: 100%;
                    border-right: 1px dashed #337ad7;

                    &.double-width {
                        width: @doubleColWidth;
                    }

                    &:nth-last-child(1) {
                        border-right: 0;
                    }
                }
            }

            .date-selector-scroller {
                width: 100%;
                height: 100%;
                background-color: #fff;
                position: relative;
                display: flex;

                .date-item-scroll {
                    float: left;
                    padding: 60px 0px;
                    text-align: center;
                    width: 15%;
                    height: 100%;
                    border-right: 1px dashed #337ad7;
                    transition: all ease 0.3s;
                    position: relative;
                    z-index: 2;

                    // background-color: gray;
                    .date-item {
                        padding: 0;
                        text-align: center;
                        height: 30px;
                        line-height: 30px;

                        &.active {
                            // background-color: white;
                            font-size: 15px;
                        }
                    }

                    &.double-width {
                        width: @doubleColWidth;
                    }

                    &:nth-last-child(1) {
                        border-right: 0;
                    }
                }
            }

            .date-selector-tools {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                padding-right: 10px;

                .button {
                    padding: 0 6px;
                    margin: 0 4px;
                    height: 18px;
                    line-height: 16px;
                    font-size: 12px;
                }
            }

            .bg-top,
            .bg-bottom {
                background-color: gray;
                width: 100%;
                height: 60px;
                position: absolute;
                left: 0;
                z-index: 1;
            }

            .bg-top {
                top: 0;
                background-image: linear-gradient(#c8c8c8, #fff);
                // background-image: linear-gradient(#424242, #d1d1d1);
                // background-image: linear-gradient(#e66465, #9198e5);
            }

            .bg-bottom {
                bottom: 0;
                background-image: linear-gradient(#fff, #c8c8c8);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .cell-date {
        width: 100%;
        min-width: 100%;

        &:hover {
            background-color: transparent;
        }

        .date-selector-contain {
            z-index: 1000;
            transition: height ease 0.3s;
            overflow: hidden;
            position: fixed;
            width: 100%;
            height: 100% !important;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.508);

            .date-selector-box {
                width: 100%;
                border-width: 0;
                border-top-width: 1px;
            }
        }
    }
}
</style>
