<template>
	<div class="checkbox-root"
		:border="border">
		<LabelGroup v-if="readonly"
			class="checked-display"
			:list="datas.display">
			<span v-show="datas.display.length==0">none</span>
		</LabelGroup>

		<div v-else
			class="select-list">
			<div v-if="datas.dictionary.mapper"
				v-for="(option,index) in datas.dictionary.mapper"
				:key="index"
				:class="['check-option',{active:elementSelected(index)>-1}]"
				@click="optionClick(option,index)">
				<div class="check-status">
					<span :class="['iconfont',
					{'icon-ok-icon':elementSelected(index)>-1}]"></span>
				</div>
				<div class="label text-etc">{{ option }}</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
// vue内容定义
const props = defineProps({
	border: Boolean,
	config: Object,
	placeholder: String,
	readonly: Boolean,
	multi: Boolean, // 是否允许多选
	modelValue: { type: [Number, String, Array, Object] }
});
const emit = defineEmits(['update:modelValue', 'change']);

// 参数定义
const datas = reactive({
	dictionary: { mapper: [] },
	display: [],
})
const valueScheme = ref([]);

// vue方法
watch(() => props.modelValue, () => {
	initVlaue();
})
onMounted(async () => {
	await top.window.handle.getDictionary(props.config.dictionary).then(
		res => {
			datas.dictionary = res ? res : {};
		}
	)
	initVlaue();
});

// 组件内部方法
const initVlaue = async () => { // 初始化参数
	if (typeof props.modelValue == 'object') {
		valueScheme.value = props.modelValue;
	} else {
		valueScheme.value = props.modelValue == '' ? [] : props.modelValue.split(',');
	}

	let result = [];
	for (const key in valueScheme.value) {
		let el = valueScheme.value[key];
		valueScheme.value[key] = Number(el);
		result.push(datas.dictionary.mapper[el]);
	}
	datas.display = result;
}
const elementSelected = (index) => {
	return valueScheme.value.indexOf(Number(index));
}
const optionClick = (option, index) => {
	if (props.readonly) {
		return;
	}
	let spliceIndex = elementSelected(index);
	if (spliceIndex > -1) {
		valueScheme.value.splice(spliceIndex, 1);
	} else {
		// if (!props.config.multi) { // 如果不允许多选 需要把值置空
		// 	valueScheme.value = [];
		// }
		valueScheme.value.push(Number(index));
	}
	dataChange();
}
const dataChange = () => { // 更新事件触发
	if (props.readonly) {
		return;
	}
	emit('update:modelValue', valueScheme.value.join(','));
	emit('change', valueScheme.value.join(','));
}
</script>
<style lang="less" scoped>
@import url("~@/assets/less/global-config/config.less");

.checkbox-root {
	width: 100%;
	position: relative;

	.checked-display {
		width: 100%;
		height: 100%;
		line-height: 28px;
		text-align: left;
		padding: 0;
	}


	.select-list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		padding: 0 10px;
		// position: absolute;
		// top: calc(100% + 4px);
		// left: 0;
		// border: 1px solid var(--color-theme-light-1);

		.check-option {
			// width: 100%;
			font-size: 12px;
			color: var(--color-light-4);
			cursor: pointer;
			padding: 0 10px 0 20px;
			margin: 10px;
			position: relative;
			display: flex;
			align-items: center;
			border-radius: 4px;
			border: 1px solid transparent;

			&:hover {
				border-color: inherit;
			}

			.check-status {
				width: 12px;
				height: 12px;
				position: absolute;
				left: 5px;
				border-radius: 2px;
				border: 1px solid;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
			}


			.label {
				cursor: inherit;
				width: 100%;
				text-align: left;
			}

			&.active {
				color: var(--color-light-4);

				&:hover {
					border-color: var(--color-theme-light-1);
				}


				.check-status,
				.label {
					color: var(--color-theme-light-1);
				}
			}
		}
	}
}
</style>