<template>
    <div class="role-management-root">
        <Waitting v-if="data.loading"
            :text="data.msg"></Waitting>
        <template v-else>
            <!-- 角色选择 -->
            <div class="left">
                <div v-for="(item,index) in data.roleList"
                    :class="['role-item',{active:data.activeRoleID==index}]"
                    @click="setRoleActive(index)">{{item.name}}</div>
            </div>

            <!-- 权限选择 -->
            <div class="right"
                v-if="data.activeRoleID!=''">
                <!-- 导航 -->
                <div class="nav">
                    <div :class="['item',{active:data.activeNav==1}]"
                        @click="setNavActive(1)">菜单权限</div>
                    <div :class="['item',{active:data.activeNav==0}]"
                        @click="setNavActive(0)">数据权限</div>
                </div>
                <div class="content scroll scroll-all">
                    <div class="button button-save"
                        @click="updateRight(data.roleList[data.activeRoleID].right)">保存</div>
                    <MenuTree v-show="data.activeNav==0"
                        :list="data.apiList"
                        :type="data.activeNav==0?[0,1]:[2,3]"
                        :role="data.roleList[data.activeRoleID]"></MenuTree>
                    <MenuTree v-show="data.activeNav==1"
                        :list="data.menuList"
                        :type="data.activeNav==0?[0,1]:[2,3]"
                        :role="data.roleList[data.activeRoleID]"></MenuTree>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
// 加载角色列表

import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../../assets/js/apiMap';

// 修改角色权限(新增/删除) 0 1 接口   2 3 菜单
const store = useStore();
// 页面数据
const data = reactive({
    roleList: {},
    activeRoleID: '',
    activeNav: '1',
    menuList: {},
    apiList: {},
    rightList: [],
    loading: false,
    error: false,
    msg: '',
});

// 加载角色列表
const loadRoleList = async () => {
    data.roleList = {};
    data.msg = '正在加载角色信息...';
    await store.dispatch({
        type: apiMap.UserRolePageList,
        params: {
            page: 1, pageSize: 100
        }
    }).then(res => {
        if (res && res.list) {
            for (const key in res.list) {
                if (Object.hasOwnProperty.call(res.list, key)) {
                    const element = res.list[key];
                    data.roleList[element.id] = { ...element, right: {} };
                    if (!data.activeRoleID) {
                        data.activeRoleID = element.id
                    }
                    loadRight(data.roleList[element.id]);
                }
            }
        }
    })
}
const loadRight = role => {
    data.msg = '正在加载权限信息...';
    store.dispatch({
        type: apiMap.SysMenuRightPageList,
        params: {
            page: 1, pageSize: 1000, condition: { role_id: role.id }
        }
    }).then(right => {
        if (right && right.list) {
            for (const key in right.list) {
                if (Object.hasOwnProperty.call(right.list, key)) {
                    const element = right.list[key];
                    role.right[element.menu_id] = element
                }
            }
        }
    })
}
// 加载菜单列表
const initMenuList = async (type) => {
    data.msg = '正在加载菜单信息...';
    await store.dispatch({
        type: apiMap.SysMenuPageList,
        params: {
            page: 1, pageSize: 1000, condition: { 'type.in': type, loadAll: true }
        }
    }).then(async res => {
        if (res) {
            data.menuList = res;
        }
    });
}
const initApiList = async (type) => {
    data.msg = '正在加载接口信息...';
    await store.dispatch({
        type: apiMap.SysMenuPageList,
        params: {
            page: 1, pageSize: 1000, condition: { 'type.in': type, loadAll: true }
        }
    }).then(async res => {
        if (res) {
            data.apiList = res;
        }
    });
}
const setRoleActive = async id => {
    data.activeRoleID = id;
    setNavActive(1)
}
const setNavActive = id => {
    data.activeNav = id;
    // if (id == 0) { // 接口
    //     data.list = data.apiList;
    // } else { // 菜单
    //     data.list = data.menuList;
    // }
}

// 更新权限
const updateRight = (right) => {
    data.loading = true;
    data.msg = '正在保存设置';
    store.dispatch({
        type: apiMap.SysMenuRightUpdateMulti,
        data: { list: right, condition: [], allowField: [] },
    }).then(res => {
        if (res) {
            top.window.sendMessage({
                type: 'success',
                content: '保存成功'
            })
            // right = res;
            data.roleList[data.activeRoleID].right = res;
            // location.reload();
            // return;
        }
        data.loading = false;
    });
}

onMounted(async () => {
    data.loading = true;
    await loadRoleList();
    await initApiList([0, 1])
    await initMenuList([2, 3])
    data.loading = false;
})
</script>


<style lang="less" scoped>
.role-management-root {
    width: 100%;
    height: 100%;
    padding: 0 0 0 300px;
    position: relative;

    .left {
        width: 300px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 15px;
        background-color: #f2f2f2;
        border-right: 1px solid var(--color-theme-light-1);

        .role-item {
            width: 100%;
            height: 32px;
            line-height: 30px;
            cursor: pointer;
            text-align: center;
            background-color: var(--color-theme-light-1);
            border-bottom: 1px solid;

            &.active {
                background-color: white;
            }
        }
    }

    .right {
        width: 100%;
        height: 100%;
        padding-top: 32px;
        position: relative;

        .nav {
            width: 100%;
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 15px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--color-theme-light-1);
            background-color: #f2f2f2;

            .item {
                height: 30px;
                line-height: 30px;
                text-align: center;
                padding: 0 10px;
                background-color: var(--color-theme-light-1);
                cursor: pointer;

                &.active {
                    background-color: white;
                }
            }
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 0 15px;

            .button-save {
                position: fixed;
                z-index: var(--index-page);
                bottom: 10px;
                margin: 0 auto;
            }
        }
    }
}
</style>