<template>
	<InputBox :conf="telConf"></InputBox>
	<InputBox :conf="pwdConf"></InputBox>
	<InputBox :conf="vfcConf"></InputBox>
	<div class="bottom-buttons">
		<div class="button logout normal-fill" @click="jumpToLog">返回</div>
		<div class="button replace-pwd normal-fill" @click="submit">提交</div>
	</div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from "vuex"
// import InputBox from '../../universal/InputBox.vue';
export default {
	name: "FindPwd",
	// components: { InputBox },
	setup () {
		var store = useStore();
		let telConf = ref({
			value: store.getters.userinfo.info.tel,
			length: "11",
			rule: /^[0-9]{11}$/,
			required: true,
			placeholder: "请输入手机号",
			errorMsg: "手机号必须是11位国内有效手机号",
		})
		let pwdConf = ref({
			value: "",
			length: "20",
			rule: /^.{6,20}$/,
			placeholder: "请输入登录密码",
			required: true,
			type: "test",
			errorMsg: "密码必须是6-20位任意字符",
		})
		let vfcConf = ref({
			length: "4",
			rule: /^[0-9]{4}$/,
			required: true,
			placeholder: "请输入验证码",
			errorMsg: "验证码必须是4为有效数字",
			buttons: [
				{ display: "获取验证码" },
			]
		})
		function jumpToLog () {
			store.dispatch("changeIdentifyState", "Log");
		}
		function submit () {
			if (store.state.identify.logState) {
				alert("请耐心等待服务器响应");
				return false;
			}
			if (!telConf.value.rule.test(telConf.value.value) ||
				!pwdConf.value.rule.test(pwdConf.value.value) ||
				!vfcConf.value.rule.test(vfcConf.value.value)) {
					alert("请输入合适的内容再提交");
					return ;
			}
			store.commit("updateLogState", true);
			store.dispatch("replacePwd", {
				tel: telConf.value.value,
				pwd: pwdConf.value.value,
				vfc: vfcConf.value.value,
			})
		}
		return {
			// vars
			telConf,
			pwdConf,
			vfcConf,
			// func
			jumpToLog,
			submit
		}
	}
}
</script>
<style lang="less">
</style>