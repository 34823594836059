<template>
	<div :class="['radio-box-root',{readonly:readonly}]">
		<Label v-if="readonly"
			:text="datas.display"></Label>
		<div v-else
			v-for="(option,index) in datas.dictionary.mapper"
			:class="['radio-option', { active: valueScheme == index }]"
			:key="index"
			@click="dataChange(index)">
			<!-- <input type="radio"
				:readonly="readonly"
				:id="`${datas.dictionary.code}_${index}`"
				:value="index"
				v-model="valueScheme"
				@change="dataChange" /> -->

			<div class="check-status">
				<div class="checked"></div>
			</div>
			<div class="label"
				:for="`${datas.dictionary.code}_${index}`">{{ option }}</div>
		</div>
	</div>
</template>
<script setup>
import { onMounted, reactive, ref, watch } from 'vue';

const props = defineProps({
	config: Object,
	readonly: Boolean,
	modelValue: { type: [Number, String] }
});
const emit = defineEmits([
	'update:modelValue'
]);
const datas = reactive({
	dictionary: {},
	display: '',
})
const valueScheme = ref('');

onMounted(async () => {
	await top.window.handle.getDictionary(props.config.dictionary).then(
		res => {
			datas.dictionary = res
		}
	)
	initVlaue();
});
const initVlaue = () => {
	valueScheme.value = props.modelValue;
	datas.display = datas.dictionary.mapper[props.modelValue]
}
watch(() => props.modelValue, () => {
	initVlaue();
})
const dataChange = (data) => {
	if (props.readonly) {
		return;
	}
	// valueScheme.value = !valueScheme.value
	emit('update:modelValue', data);
}
</script>
<style lang="less">
@import url("~@/assets/less/global-config/config.less");

.radio-box-root {
	padding: 10px 0;
	display: flex;
	align-items: center;
	position: relative;

	.radio-option {
		font-size: 12px;
		color: var(--color-light-4);
		cursor: pointer;
		line-height: 24px;
		padding: 0 10px 0 20px;
		margin: 10px;
		position: relative;
		display: flex;
		align-items: center;
		border-radius: 4px;
		border: 1px solid transparent;

		&:hover {
			border-color: var(--color-theme-light-1);
		}

		.check-status {
			width: 12px;
			height: 12px;
			position: absolute;
			left: 5px;
			border-radius: 50%;
			border: 1px solid;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.label {
			cursor: inherit;
			width: 100%;
			text-align: left;
		}

		&.active {
			color: var(--color-light-4);

			&:hover {
				border-color: var(--color-theme-light-1);
			}


			.check-status,
			.label {
				color: var(--color-theme-light-1);
			}

			.check-status>.checked {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: var(--color-theme-light-1);
			}
		}
	}
}
</style>