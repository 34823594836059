export default {
            MallOrderInsert: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/insert',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderInsertMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/insertMulti',
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderUpdate: ({ dispatch, commit, state }, payload) => {
                let id=payload.data.id;delete payload.data.id;
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/update/${id}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderUpdateMulti: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/updateMulti',
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderSelect: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/select/${payload.data.id}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderSelectBy: ({ dispatch, commit, state }, payload) => {
                
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/selectBy/${payload.data.key}/${payload.data.value}`,
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderPageList: ({ dispatch, commit, state }, payload) => {
                return dispatch({
                    type: 'request',
                    url: '/admin/mall/order/pageList',
                    method: 'get',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderTrash: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/trash/${payload.data.ids}`,
                    method: 'put',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderRecovery: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/recovery/${payload.data.ids}`,
                    method: 'post',
                    data: payload.data,
                    params: payload.params,
                });
            },
            MallOrderDelete: ({ dispatch, commit, state }, payload) => {
                
                return dispatch({
                    type: 'request',
                    url: `/admin/mall/order/delete/${payload.data.ids}`,
                    method: 'delete',
                    data: payload.data,
                    params: payload.params,
                });
            }
        };