/** @format 修改state的唯一方法*/
// 更改 Vuex 的 store 中的状态的唯一方法是提交 mutation。Vuex 中的 mutation 非常类似于事件：每个 mutation 都有一个字符串的事件类型 (type)和一个回调函数 (handler)
export default {
	increment(state, n) {
		// 变更状态
		state.count += n;
	},
	payload(state, payload) {
		state.count += payload.amount;
	},
	setUserinfo(state, payload) {
		state.userInfo.profile = payload.data;
	},
	getUserinfo(state, payload) {
		return state.userInfo.profile;
	},
	setToken(state, payload) {
		state.userInfo.token = payload.token;
	},
	getToken(state, payload) {
		return state.userInfo.token;
	},
	setDictionary(state, payload) {
		let data = payload.data;
		state.dictionary[data.code] = data;
	},
};
