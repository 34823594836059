<template>
    <div class="table-list-root">
        <div class="buttons-contain">
            <!-- <div class="button"
                @click="insert()">新增</div> -->
            <div class="button"
                @click="getData()">刷新</div>
        </div>
        <div class="handle-area">
            <div class="search-condition">
                <div class="key">关键词</div>
                <InputBox v-model="conf.queryCondtion.keywordsLike"
                    :border="true"
                    :radius="true"
                    :placeholder="'请输入关键词'"></InputBox>
            </div>
            <template v-for="(field,index) in conf.fieldSchema"
                :key="index">
                <div v-if="field.config.dictionary"
                    class="search-condition">
                    <div class="key">{{field.display}}</div>
                    <DropSelect v-model="conf.queryCondtion[`${field.value}.in`]"
                        :config="{dictionary:field.config.dictionary,multi:true}"
                        :placeholder="'请选择'+field.display"
                        :border="true"></DropSelect>
                </div>
            </template>
            <div class="button srearch-button"
                @click="getData">查询</div>
        </div>
        <div class="data-area scroll scroll-all">
            <table class="getch-table">
                <thead>
                    <tr>
                        <th class="col-sharp">#</th>
                        <th v-for="(field,index) in conf.fieldSchema"
                            :key="index">
                            {{field.display}}
                        </th>
                        <th class="recode-tools">操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(recode,index) in conf.dataList"
                        :key="index">
                        <td class="col-sharp">{{index+1}}</td>
                        <td v-for="(field,index) in conf.fieldSchema"
                            :key="index">
                            <div class="table-cell-container text-etc"
                                v-if="field.value!='deleted_at'">
                                <component :is="field.component"
                                    v-model="recode[field.value]"
                                    :readonly="true"></component>
                                <!-- {{recode[field.value]}} -->
                            </div>
                        </td>
                        <td class="recode-tools">
                            <div class="recode-tools-contain">
                                <div class="button"
                                    @click="select(recode)">选择</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-show="!conf.loading&&conf.dataList.length==0"
                style="width: 100%;text-align:center;line-height:30px;">暂无记录</div>
            <Waitting v-show="conf.loading"
                :text="'加载中'"></Waitting>
        </div>
        <div class="footer-area">
            <Paginate :config="conf.pageInfo"
                @on-page-changed="getData"></Paginate>
        </div>
        <Window :config="conf.winConf"
            @closeWin="conf.winConf.display=false"></Window>
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiMap } from "../../assets/js/apiMap";
const store = useStore()
const route = useRoute();
const tableName = route.params.table;
const conf = reactive({
    loading: false,
    tableSchema: {},
    fieldSchema: [],
    pageInfo: {
        page: 1,
        pageSize: 20,
        total: 1,
        count: 1,
    },
    winConf: {
        title: "弹窗",
        url: '',
        type: 0,
        width: 800,
        height: 1000,
        display: false,
        isFull: true,
    },
    queryCondtion: {
        keywordsLike: '',
    },
    orderMethod: [],
    dataList: [],
});
const insert = async () => {
    conf.winConf.display = true;
    conf.winConf.url = `/table/edit/${tableName}/0`;

}
const getData = async () => {
    conf.loading = true;
    conf.dataList = [];
    store.dispatch({
        type: 'request',
        url: `/admin/${tableName.split('_').join('/')}/pageList`,
        method: 'get',
        params: {
            page: conf.pageInfo.page, pageSize: conf.pageInfo.pageSize, condition: conf.queryCondtion, order: conf.orderMethod,
            queryField: [route.query.foreignField, route.query.foreignDisplayField]
        }
    }
    ).then(res => {
        conf.loading = false;
        if (!res) {
            return;
        }
        conf.dataList = res.list;
        delete res.list;
        conf.pageInfo = res;
    });
}
onMounted(async () => {
    await store.dispatch({
        type: apiMap.SysTableSelectBy,
        data: {
            key: 'value', value: tableName
        }
    }).then(res => {
        conf.tableSchema = res;
    });
    await store.dispatch({
        type: apiMap.SysTableFieldPageList,
        params: {
            page: 1, pageSize: 100, condition: { table_value: tableName, 'value.in': [route.query.foreignField, route.query.foreignDisplayField] }, order: { position: 'asc' }
        }
    }).then(res => {
        conf.fieldSchema = res.list
    });
    await getData();
})
const select = recode => {
    top.window[tableName].selected(recode);
}
</script>
<style lang="less" scoped>
.table-list-root {
    width: 100%;
    height: 100%;
    padding: 8px;

    .handle-area {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px;
        padding-right: 76px;
        position: relative;

        .srearch-button {
            position: absolute;
            right: 8px;
            width: 60px;
            text-align: center;
        }

        .search-condition {
            width: 300px;
            margin: 10px;
            display: flex;
            align-items: center;
            padding-left: 100px;
            position: relative;

            &>.key {
                width: 90px;
                font-weight: bold;
                position: absolute;
                left: 0;
                text-align: right;
            }
        }

    }


    .data-area {
        width: 100%;
        padding: 0px 0 8px;
        border-bottom: 1px solid;

        td,
        th {
            padding: 0 10px;

            .table-cell-container {
                max-width: 400px;
            }
        }
    }

    .footer-area {
        position: relative;
        height: 80px;
        padding: 10px;
    }
}
</style>