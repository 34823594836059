<template>
	<div class="waiting">
		<!-- <img src="@/assets/images/website/loading-ani.gif" alt style="width: 50px;height:50px" /> -->
		<div class="waiting-animate">
			<!-- <span v-for="(el,index) in animateEl"
				:class="['iconfont', 'icon-pulse-icon', `animate-node-${el}`]"></span> -->
			<span :class="['iconfont', 'icon-pulse-icon', `animate-node-${animateElLeft[0]}`]"
				:style="{left:animateElLeft[0]+'px'}"></span>
			<span :class="['iconfont', 'icon-pulse-icon', `animate-node-${animateElLeft[1]}`]"
				:style="{left:animateElLeft[1]+'px'}"></span>
			<span :class="['iconfont', 'icon-pulse-icon', `animate-node-${animateElLeft[2]}`]"
				:style="{left:animateElLeft[2]+'px'}"></span>
			<span :class="['iconfont', 'icon-pulse-icon', `animate-node-${animateElLeft[3]}`]"
				:style="{left:animateElLeft[3]+'px'}"></span>
			<span :class="['iconfont', 'icon-pulse-icon', `animate-node-${animateElLeft[4]}`]"
				:style="{left:animateElLeft[4]+'px'}"></span>
		</div>
		<div class="waiting-text"
			v-show="text">{{text}}</div>
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps([
	'text'
])
const animateElLeft = ref([
	-14,
	0,
	14,
	28,
	42,
]);
const move = () => {
	let node = animateElLeft.value.shift();

	animateElLeft.value.push(node);
	setTimeout(() => {
		move();
	}, 500);
}
onMounted(() => {
	move();
})
</script>
<style lang="less">
.waiting {
	// width: 30px;
	height: 30px;
	// position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	// background-color: var(--color-light-11);
	z-index: 999999999;
	// flex-direction: column;

	.waiting-animate {
		display: flex;
		width: 30px;
		height: 30px;
		overflow: hidden;
		align-items: center;
		position: relative;

		.icon-pulse-icon {
			display: flex;
			// width: 30px;
			height: 30px;
			align-items: center;
			transition: all .5s;
			position: absolute;
			font-size: 14px;
			z-index: 2;

			&.animate-node--14,
			&.animate-node-42 {
				opacity: 0;
			}

			// &:nth-child(1) {
			// 	opacity: 0;
			// }

			// &:nth-last-child(1) {
			// 	opacity: 0;
			// }

			// &.animate-node-1 {
			// 	left: -14px;
			// }

			// &.animate-node-2 {
			// 	left: 0;
			// }

			// &.animate-node-3 {
			// 	left: 14px;
			// }

			// &.animate-node-4 {
			// 	left: 28px;
			// }

			// &.animate-node-5 {
			// 	left: 42px;
			// }
		}
	}

	.waiting-text {
		display: flex;
		white-space: nowrap;
		padding: 0 10px;
	}
}
</style>

饮品类
糕点类
设备类
原料类
食品类
干点类
包装类
工具类