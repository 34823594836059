<template>
	<div class="home-root">
		<!-- 统计销量 统计今日销量 统计 -->
		<div class="all-count">
			<DataSumarry class="success"
				:title="'总销售额'"
				:value="data.count.paidAmount"></DataSumarry>
			<DataSumarry class="danger"
				:title="'总退款量'"
				:value="data.count.refundedAmount"></DataSumarry>
			<DataSumarry class="warning"
				:title="'退款中金额'"
				:value="data.count.refundingAmount"></DataSumarry>
			<DataSumarry class="success"
				:title="'总销量'"
				:value="data.count.paidTotal"></DataSumarry>
			<DataSumarry class="danger"
				:title="'总退款订单'"
				:value="data.count.refundedTotal"></DataSumarry>
			<DataSumarry class="warning"
				:title="'退款中订单'"
				:value="data.count.refundingTotal"></DataSumarry>
		</div>
		<div class="all-count today">
			<DataSumarry class="success"
				:title="'今日总销售额'"
				:value="data.count.paidAmountToday"></DataSumarry>
			<DataSumarry class="danger"
				:title="'今日总退款量'"
				:value="data.count.refundedAmountToday"></DataSumarry>
			<DataSumarry class="warning"
				:title="'今日退款中金额'"
				:value="data.count.refundingAmountToday"></DataSumarry>
			<DataSumarry class="success"
				:title="'今日总销量'"
				:value="data.count.paidTotalToday"></DataSumarry>
			<DataSumarry class="danger"
				:title="'今日总退款订单'"
				:value="data.count.refundedTotalToday"></DataSumarry>
			<DataSumarry class="warning"
				:title="'今日退款中订单'"
				:value="data.count.refundingTotalToday"></DataSumarry>
		</div>
		<!-- 邀请动态 -->
		<!-- 消费动态 -->
		<!-- 个人资产 -->
		<!--  -->
	</div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { apiMap } from '../assets/js/apiMap';

const data = reactive({
	count: {
		paidAmount: 0,
		refundedAmount: 0,
		refundingAmount: 0,
		paidTotal: 0,
		refundedTotal: 0,
		refundingTotal: 0,
		paidAmountToday: 0,
		refundedAmountToday: 0,
		refundingAmountToday: 0,
		paidTotalToday: 0,
		refundedTotalToday: 0,
		refundingTotalToday: 0,
	}
})
const store = useStore();
onMounted(async () => {
	await store.dispatch({
		type: apiMap.MallShopReport,
	}).then(res => {
		if (res)
			data.count = res;
	})
})
</script>
<style lang="less" scoped>
.home-root {
	width: 100%;
	// display: flex;
	// flex-direction: column;

	.all-count {
		width: 100%;
		display: flex;
		align-items: center;
		// border-bottom: 1px solid var(--color-light-7);
		margin: 10px 0;

		::v-deep .data-summary-root {
			border-right: 1px solid var(--color-light-7);
			text-align: center;

			&:nth-last-child(1) {
				border-right: 0;
			}
		}
	}
}
</style>
