<template>
    <div class="drop-select-root"
        :border="border">
        <div class="selected-display text-etc"
            @click="openDrop">
            {{datas.display.join(',')}}
            <span v-show="datas.display.length==0">{{placeholder}}</span>
            <span :class="['iconfont',{'icon-down':!datas.dropSwitch,'icon-up':datas.dropSwitch}]"></span>
        </div>

        <div v-if="!readonly"
            v-show="datas.dropSwitch"
            class="select-list scroll scroll-y scroll-no-bar"
            @mouseleave="openDrop">
            <div v-if="datas.dictionary.mapper"
                v-for="(option,index) in datas.dictionary.mapper"
                :class="['select-option',{active:elementSelected(index)>-1}]"
                :key="index"
                @click.prevent="optionClick(option,index)">
                {{ option }}
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
// vue内容定义
const props = defineProps({
    border: Boolean,
    config: Object,
    placeholder: String,
    readonly: Boolean,
    multi: Boolean, // 是否允许多选
    modelValue: { type: [Number, String, Array, Object] }
});
const emit = defineEmits(['update:modelValue', 'change']);

// 参数定义
const datas = reactive({
    dictionary: { mapper: [] },
    display: [],
    dropSwitch: false,
})
const valueScheme = ref([]);

// vue方法
watch(() => props.modelValue, () => {
    initVlaue();
})
onMounted(async () => {
    await top.window.handle.getDictionary(props.config.dictionary).then(
        res => {
            datas.dictionary = res ? res : {};
        }
    )
    initVlaue();
});

// 组件内部方法
const initVlaue = () => { // 初始化参数
    valueScheme.value = valueScheme.value == undefined ? '' : valueScheme.value;
    try {
        if (typeof props.modelValue == 'object') {
            valueScheme.value = props.modelValue;
        } else {
            valueScheme.value = props.modelValue == '' ? [] : props.modelValue.split(',');
        }
    } catch {
    
    }

    let result = [];
    for (const key in valueScheme.value) {
        let el = valueScheme.value[key];
        valueScheme.value[key] = Number(el);
        result.push(datas.dictionary.mapper[el]);
    }
    datas.display = result;
}
const elementSelected = (index) => {
    return valueScheme.value.indexOf(Number(index));
}
const openDrop = () => {
    if (props.readonly) {
        return;
    }
    datas.dropSwitch = !datas.dropSwitch;
}
const optionClick = (option, index) => {
    if (props.readonly) {
        return;
    }
    let spliceIndex = elementSelected(index);
    if (spliceIndex > -1) {
        valueScheme.value.splice(spliceIndex, 1);
    } else {
        if (!props.config.multi) { // 如果不允许多选 需要把值置空
            valueScheme.value = [];
        }
        valueScheme.value.push(Number(index));
    }
    dataChange();
}
const dataChange = () => { // 更新事件触发
    if (props.readonly) {
        return;
    }
    // valueScheme.value = !valueScheme.value
    emit('update:modelValue', valueScheme.value.join(','));
    emit('change', valueScheme.value.join(','));
}
</script>
<style lang="less" scoped>
@import url("~@/assets/less/global-config/config.less");

.drop-select-root {
    width: 100%;
    height: 30px;
    position: relative;

    &[border=true] {
        border: 1px solid var(--color-theme-light-1);
    }

    .selected-display {
        width: 100%;
        height: 100%;
        line-height: 28px;
        padding: 0 28px 0 10px;
        position: relative;
        text-align: left;

        .iconfont {
            position: absolute;
            right: 0;
            width: 28px;
            height: 28px;
            text-align: center;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .select-list {
        width: 100%;
        max-height: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        border: 1px solid var(--color-theme-light-1);
        z-index: var(--index-component);

        .select-option {
            width: 100%;
            font-size: 12px;
            color: var(--color-light-4);
            cursor: pointer;
            position: relative;
            text-align: left;
            padding: 6px 15px;
            border-bottom: 1px solid var(--color-theme-light-1);

            &:hover {
                color: var(--color-theme-light-1);
            }

            &.active {
                border-color: white;
                background-color: var(--color-theme-light-1);
                color: white;
            }

            &:nth-last-child(1) {
                border-color: transparent;
            }
        }
    }
}
</style>