<template>
    <div class="cell-richbox table-cell">
        <div class="wang-editor-contain"
            v-wet="init"></div>
    </div>
</template>
<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import WE from "wangeditor";
const vWet = {
    beforeMount(el, binding) {
        if (typeof binding.value == "function") {
            setTimeout(() => {
                binding.value(el);
            }, 0);
        }
    },
};
const value = ref('');
const props = defineProps({
    modelValue: String,
    name: String,
});
const emit = defineEmits(['update:modelValue']);
const datas = reactive({
    e: WE,
});
const init = el => {
    datas.e = new WE(el);
    datas.e.config.onchange = (html) => {
        value.value = html;
        emit('update:modelValue', html);
    };
    datas.e.create();
    datas.e.txt.html(props.modelValue);
}
watch(() => props.modelValue, () => {
    setValue();
})
const setValue = () => {
    value.value = props.modelValue;
    if (datas.e && datas.e.txt)
        datas.e.txt.html(props.modelValue);
}
onMounted(() => {
    setValue();
})
</script>
<style lang="less">
@import url("~@/assets/less/global-config/config.less");
// wangeditor样式修正
@wets: 30px;

.cell-richbox {
    min-width: 220px;
    width: 100%;
    display: flex;
    height: 100%;
    // border: 1px solid var(--color-theme-light-1);
    position: relative;
}

.wang-editor-contain {
    width: 100%;
    height: 100%;
    padding-top: calc(@wets + 30px);
    position: relative;
    background-color: white;
    border: 1px solid #c9d8db;

    &.w-e-full-screen-editor {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
    }

    .w-e-toolbar {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        flex-wrap: nowrap;
        height: @wets;
        padding-left: 90px;
        overflow: auto;

        .w-e-menu {
            width: @wets;
            height: @wets;
            min-width: @wets;
            white-space: nowrap;

            &:nth-last-child(1) {
                position: absolute;
                left: 0;
                // background-color: inherit;
                border-right: 1px solid #c9d8db;
            }

            &:nth-last-child(4) {
                position: absolute;
                left: 30px;
            }

            &:nth-last-child(3) {
                position: absolute;
                left: 60px;
            }
        }
    }

    .w-e-text-container {
        width: 100%;
        height: 100% !important;
    }
}
</style>
