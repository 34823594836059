<template>
	<div class="table-list-root">
		<div class="buttons-contain">
			<!-- <div class="button"
                @click="insert()">新增</div> -->
			<div class="button" @click="getData()">刷新</div>
		</div>
		<div class="handle-area">
			<div class="search-condition">
				<div class="key">关键词</div>
				<InputBox v-model="conf.queryCondtion.keywordsLike" :border="true" :radius="true"
					:placeholder="'请输入关键词'"></InputBox>
			</div>
			<template v-for="(field, index) in conf.fieldSchema" :key="index">
				<div v-if="field.config.dictionary" class="search-condition">
					<div class="key">{{ field.display }}</div>
					<DropSelect v-model="conf.queryCondtion[`${field.value}.in`]"
						:config="{ dictionary: field.config.dictionary, multi: true }"
						:placeholder="'请选择' + field.display" :border="true"></DropSelect>
				</div>
			</template>
			<div class="button srearch-button" @click="getData">查询</div>
		</div>
		<div class="data-area scroll scroll-all">
			<table class="getch-table">
				<thead>
					<tr>
						<th class="col-sharp">#</th>
						<th v-for="(field, index) in conf.fieldSchema" :key="index">
							{{ field.display }}
						</th>
						<th class="recode-tools">操作</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(recode, index) in conf.dataList" :key="index">
						<td class="col-sharp">{{ index + 1 }}</td>
						<td v-for="(field, index) in conf.fieldSchema" :key="index">
							<div class="table-cell-container text-etc" v-if="field.value != 'deleted_at'">
								<component v-model="recode[field.value]" :is="field.component"
									:foreignValue="recode[`${field.value}Foreign`]" :config="field.config"
									:readonly="!field.config.editInList"></component>
								<!-- {{recode[field.value]}} -->
							</div>
						</td>
						<td class="recode-tools">
							<div class="recode-tools-contain">
								<div class="button" v-for="action in conf.tableSchema.row_actions"
									v-show="action.condition ? action.condition(recode) : true"
									@click="action.action(recode, index)">
									{{ action.display }}
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div v-show="!conf.loading && conf.dataList.length == 0"
				style="width: 100%;text-align:center;line-height:30px;">暂无记录</div>
			<Waitting v-show="conf.loading" :text="'加载中'"></Waitting>
		</div>
		<div class="footer-area">
			<Paginate :config="conf.pageInfo" @on-page-changed="getData"></Paginate>
		</div>
		<Window v-if="conf.winConf.display" :config="conf.winConf" @closeWin="conf.winConf.display = false"></Window>
		<Window :config="conf.auditWin" @closeWin="conf.auditWin.display = false">
			<div class="audit">
				<InputBox v-model="conf.auditWin.recode.remarks" :border="true" :placeholder="'回复内容'"></InputBox>
				<div class="buttons-contain">
					<div class="button success-fill" @click="audit(3)">通过</div>
					<div class="button danger-fill" @click="audit(2)">拒绝</div>
				</div>
			</div>
		</Window>
	</div>
</template>
<script setup>
	import {
		computed,
		onMounted,
		reactive,
		ref,
		toRefs
	} from "vue";
	import {
		useRoute
	} from "vue-router";
	import {
		useStore
	} from "vuex";
	import {
		apiMap
	} from "../../assets/js/apiMap";
	import {
		actionsMap
	} from "../../assets/js/actionsMap";
	import {
		tools
	} from "../../assets/js/common";
	const store = useStore()
	const route = useRoute();
	const tableName = 'mall_shop';
	const conf = reactive({
		loading: false,
		tableSchema: {},
		fieldSchema: [],
		auditWin: {
			title: "审核",
			// url: '',
			type: 2,
			width: 600,
			height: 120,
			display: false,
			isFull: false,
			recode: {},
		},
		pageInfo: {
			page: 1,
			pageSize: 20,
			total: 1,
			count: 1,
		},
		winConf: {
			title: "弹窗",
			url: '',
			type: 0,
			width: 800,
			height: 1000,
			display: false,
			isFull: true,
		},
		queryCondtion: {
			keywordsLike: '',
		},
		keywordsButtons: [{
			display: '查询',
			action: e => {
				getData();
			}
		}],
		orderMethod: [],
		dataList: [],
	});
	const insert = async () => {
		conf.winConf.display = true;
		conf.winConf.url = `/table/edit/${tableName}/0`;

	}
	const updateRecode = async recode => {
		store.dispatch({
			type: apiMap[tools.ucWords(tableName).replaceAll(' ', '') + 'Update'],
			data: {
				id: recode.id,
				data: recode,
			}
		}).then(res => {
			recode = res;
		})
	}
	const getData = async () => {
		conf.loading = true;
		conf.dataList = [];
		store.dispatch({
			type: 'request',
			url: `/admin/${tableName.split('_').join('/')}/pageList`,
			method: 'get',
			params: {
				page: conf.pageInfo.page,
				pageSize: conf.pageInfo.pageSize,
				condition: conf.queryCondtion,
				order: conf.orderMethod
			}
		}).then(res => {
			conf.loading = false;
			if (!res) {
				return;
			}
			conf.dataList = res.list;
			delete res.list;
			conf.pageInfo = res;
		});
	}
	const audit = async status => {
		conf.auditWin.recode.status = status;
		await updateRecode(conf.auditWin.recode);
		conf.auditWin.display = false;
		conf.auditWin.recode = {};
	}
	onMounted(async () => {
		let item = apiMap[tools.ucWords(tableName).replaceAll(' ', '') + 'Update']
		console.log(1111, item);
		await store.dispatch({
			type: apiMap.SysTableSelectBy,
			data: {
				key: 'value',
				value: tableName
			}
		}).then(res => {
			conf.tableSchema = res;
		});
		await store.dispatch({
			type: apiMap.SysTableFieldPageList,
			params: {
				page: 1,
				pageSize: 100,
				condition: {
					table_value: tableName,
					show_in_list: 1
				},
				order: {
					position: 'asc'
				}
			}
		}).then(res => {
			conf.fieldSchema = res.list
		});
		await getData();
		conf.tableSchema.row_actions = { // 通过 拒绝
			// top_shop
			setTop: {
				condition: (recode) => recode.set_top == 0 && recode.status == 3,
				action: (recode) => {
					recode.set_top = 1;
					updateRecode(recode);
				},
				display: '设为优质商家',
			},
			cancelTop: {
				condition: (recode) => recode.set_top == 1 && recode.status == 3,
				action: (recode) => {
					recode.set_top = 0;
					updateRecode(recode);
				},
				display: '取消优质商家',
			},
			audit: {
				condition: (recode) => recode.status == 1,
				action: (recode) => {
					conf.auditWin.recode = recode;
					conf.auditWin.display = true;
					// recode.status = 3;
					// updateRecode(recode);
				},
				display: '审核',
			},
			// refuse: {
			//     condition: (recode) => recode.status == 1,
			//     action: (recode) => {
			//         // TODO: 
			//         conf.auditWin.recode = recode;

			//     },
			//     display: '拒绝',
			// },
			stop: {
				condition: (recode) => recode.status == 3,
				action: (recode) => {
					recode.status = 4;
					updateRecode(recode);
				},
				display: '停运',
			},
			start: {
				condition: (recode) => recode.status == 4,
				action: (recode) => {
					recode.status = 3;
					updateRecode(recode);
				},
				display: '开启',
			},
			// delete: {
			//     action: (recode, index) => {
			//         if (!window.confirm('是否删除选中数据')) {
			//             return;
			//         }
			//         store
			//             .dispatch({
			//                 type: apiMap[
			//                     tools
			//                         .ucWords(tableName)
			//                         .replaceAll(' ', '') + 'Trash'
			//                 ],
			//                 data: {
			//                     ids: recode.id,
			//                 },
			//             })
			//             .then((res) => {
			//                 if (res) {
			//                     top.window.sendMessage('删除成功');
			//                     conf.dataList.splice(index, 1);
			//                     return;
			//                 }
			//                 top.window.sendMessage('删除失败');
			//             });
			//     },
			//     display: '删除',
			// },
		};
	})
</script>
<style lang="less" scoped>
	.table-list-root {
		width: 100%;
		height: 100%;
		padding: 8px;

		.handle-area {
			width: 100%;
			display: flex;
			align-items: center;
			padding: 8px;
			padding-right: 76px;
			position: relative;

			.srearch-button {
				position: absolute;
				right: 8px;
				width: 60px;
				text-align: center;
			}

			.search-condition {
				width: 300px;
				margin: 10px;
				display: flex;
				align-items: center;
				padding-left: 100px;
				position: relative;

				&>.key {
					width: 90px;
					font-weight: bold;
					position: absolute;
					left: 0;
					text-align: right;
				}
			}

		}

		.data-area {
			width: 100%;
			padding: 0px 0 8px;
			border-bottom: 1px solid;

			td,
			th {
				padding: 0 10px;
				text-align: center;

				.table-cell-container {
					max-width: 400px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.footer-area {
			position: relative;
			height: 80px;
			padding: 10px;
		}

		.audit {
			width: 100%;
			padding: 10px 15px;

			.buttons-contain {
				display: flex;
				justify-content: space-between;
			}
		}
	}
</style>
