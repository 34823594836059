/** @format */

import IdentifyPanel from '@/views/template/IdentifyPanel.vue';
import Index from '@/views/Index.vue';
import MyView from '@/views/MyView.vue';
import Home from '@/views/Home.vue';
import DemoList from '@/views/demo/data/DemoList.vue';
import PageLogin from '@/views/identify/PageLogin.vue'; // 登录
import TableList from '@/views/table/TableList.vue';
import TableSelect from '@/views/table/TableSelect.vue';
import TableEdit from '@/views/table/TableEdit.vue';
import PlatformConfig from '@/views/sys/PlatformConfig.vue';
import GoodsEdit from '@/views/goods/GoodsEdit.vue';
import GoodsList from '@/views/goods/GoodsList.vue';
import ShopList from '@/views/shop/ShopList.vue'; // 商品列表
import ShopMine from '@/views/shop/ShopMine.vue';
import OrderList from '@/views/order/OrderList.vue'; // 订单列表
import OrderEdit from '@/views/order/OrderEdit.vue';
import OrderSend from '@/views/order/OrderSend.vue';
import MineAccount from '@/views/account/MineAccount.vue'; // 我的帐户
import TradeList from '@/views/account/TradeList.vue';
import WithdrawList from '@/views/account/WithdrawList.vue'; // 提现列表
import AgentList from '@/views/user/AgentList.vue'; // 代理人列表
import RoleManagement from '@/views/sys/RoleManagement.vue';
import SettleReport from '@/views/sys/SettleReport.vue'; // 数据统计
import ResourceList from '@/views/resource/ResourceList.vue'; // 资源圈列表
import ResourceInfo from '@/views/resource/ResourceInfo.vue'; // 资源圈详情

const routes = [
	{
		path: '/sys',
		name: 'sys',
		component: MyView,
		children: [
			{
				path: 'platformConfig',
				name: 'sys.platformConfig',
				component: PlatformConfig,
			},
			{
				path: 'roleManagement',
				name: 'sys.roleManagement',
				component: RoleManagement,
			},
			{
			    path: 'SettleReport',
			    name: 'sys.SettleReport',
			    component: SettleReport,
			},
		],
	},
	{
		path: '/user',
		name: 'user',
		component: MyView,
		children: [
			{
				path: 'agent',
				name: 'user.agent',
				component: AgentList,
			},
		],
	},
	{
		path: '/resource',
		name: 'resource',
		component: MyView,
		children: [
			{
				path: 'list',
				name: 'resource.list',
				component: ResourceList,
			},
			{
				path: 'info/:id',
				name: 'resource.info',
				component: ResourceInfo,
			},
		],
	},
	{
		path: '/finace',
		name: 'finace',
		component: MyView,
		children: [
			{
				path: 'account',
				name: 'finace.account',
				component: MineAccount,
			},
			{
				path: 'trade',
				name: 'finace.trade',
				component: TradeList,
			},
			{
				path: 'withdraw',
				name: 'finace.withdraw',
				component: WithdrawList,
			},
		],
	},
	{
		path: '/order',
		name: 'order',
		component: MyView,
		children: [
			{
				path: 'edit/:id',
				name: 'order.edit',
				component: OrderEdit,
			},
			{
				path: 'list',
				name: 'order.list',
				component: OrderList,
			},
			{
				path: 'send/:order_id',
				name: 'order.send',
				component: OrderSend,
			},
		],
	},
	{
		path: '/shop',
		name: 'shop',
		component: MyView,
		children: [
			// {
			// 	path: 'edit/:id',
			// 	name: 'shop.edit',
			// 	component: GoodsEdit,
			// },
			{
				path: 'list',
				name: 'shop.list',
				component: ShopList,
			},
			{
				path: 'mine',
				name: 'shop.mine',
				component: ShopMine,
			},
		],
	},
	{
		path: '/goods',
		name: 'goods',
		component: MyView,
		children: [
			{
				path: 'edit/:id',
				name: 'goods.edit',
				component: GoodsEdit,
			},
			{
				path: 'list',
				name: 'goods.list',
				component: GoodsList,
			},
		],
	},
	{
		path: '/',
		name: 'index',
		component: Index,
	},
	{
		path: '/home',
		name: 'home',
		component: Home,
	},
	{
		path: '/table',
		name: 'table',
		component: MyView,
		children: [
			{
				path: 'list/:table',
				name: 'table.list',
				component: TableList,
			},
			{
				path: 'edit/:table/:id',
				name: 'table.edit',
				component: TableEdit,
			},
			{
				path: 'select/:table',
				name: 'table.select',
				component: TableSelect,
			},
		],
	},
	{
		path: '/identify',
		name: 'identify',
		component: MyView,
		children: [
			{
				path: 'login',
				name: 'identify.login',
				component: PageLogin,
			},
		],
	},
	{
		path: '/idp',
		name: 'identifyPanel',
		component: IdentifyPanel,
	},
	{
		path: '/demo',
		name: 'demo',
		component: MyView,
		children: [
			{
				path: 'data',
				name: 'demo.data',
				component: MyView,
				children: [
					{
						path: 'list',
						name: 'demo.data.list',
						component: DemoList,
					},
				],
			},
		],
	},
	// {
	//     path: "/",
	//     name: "pageIndex",
	//     component: Page
	// }, {
	//     path: '/page',
	//     name: 'page',
	//     component: Page,
	//     children: [{
	//             path: "home",
	//             name: "PageHome",
	//             component: Home,
	//         },
	//         {
	//             path: "table/list/:table",
	//             name: "TableList",
	//             component: TableList,
	//         },
	//         {
	//             path: "table/detail/:table/:id",
	//             name: "RecodeDetail",
	//             component: RecodeDetail
	//         },
	//     ]
	// }, {
	//     path: '/panel',
	//     name: 'panel',
	//     component: Panel,
	//     children: [{
	//             path: "table/select/:table/:save/:display", //`/panel/table/select/${config.value.selectTable}/${config.value.saveField}/${config.value.displayField}`
	//             name: "RecodeSelectPanel",
	//             component: TableList
	//         },
	//         {
	//             path: "table/children/:table/:constraintField/:constraintValue",
	//             name: "TableChildrenPanel",
	//             component: TableList,
	//         },
	//         {
	//             path: "table/list/:table",
	//             name: "TableListPanel",
	//             component: TableList,
	//         },
	//         {
	//             path: "table/detail/:table/:id",
	//             name: "RecodeDetailPanel",
	//             component: RecodeDetail
	//         },
	//     ]
	// }, {
	//     path: '/home',
	//     name: 'Home',
	//     component: Home,
	//     children: [{
	//         path: "",
	//         component: "",
	//     }]
	// },
	// // 表单管理
	// // 报表管理
	// {
	//     path: "/table/report/:tpl",
	//     name: "ReportTempLateVue",
	//     component: ReportTempLateVue
	// },
	// // 表单格子内容演示
	// {
	//     path: "/table/cell/demo",
	//     name: "TableCellDemo",
	//     component: TableCellDemo
	// },
];
export { routes };
