/** @format */

export default {
	getTodoById: (state) => (id) => {
		return state.todos.find((todo) => todo.id === id);
	},
	doneTodosCount(state, getters) {
		return getters.doneTodos.length;
	},
	doneTodos(state) {
		return state.todos.filter((todo) => todo.done);
	},
	pageTitle(state) {
		return state.publicInfo.pageTitle;
	},
	displayFooter(state) {
		return state.system.config.displayFooter;
	},
};
